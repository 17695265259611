import { useState } from 'react';

import { Typography } from '@mui/material';

import DwdmCircuitType from '../../forms/p2p/Dwdm/DwdmCircuitType';
import DwdmSourceForm from '../../forms/p2p/Dwdm/DwdmSourceForm';
import DwdmDestinationForm from '../../forms/p2p/Dwdm/DwdmDestinationForm';
import DwdmConstraintsForm from '../../forms/p2p/Dwdm/DwdmConstraintsForm';
import MainLayout from './ MainLayout';

const WsonCircuitCreation = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState<string>('');

  const handleClick = (event: any, content: string) => {
    setAnchorEl(event.currentTarget);
    setContent(content);
    setOpen(true);
  };

  const renderContent = () => {
    switch (content) {
      case 'circuit-type':
        return <DwdmCircuitType setOpen={setOpen} />;
      case 'source-info':
        return <DwdmSourceForm setOpen={setOpen} />;
      case 'node-info':
        return <DwdmDestinationForm setOpen={setOpen} />;
      case 'route-info':
        return <DwdmConstraintsForm setOpen={setOpen} />;
      default:
        return <Typography sx={{ p: 2 }}>Default Content</Typography>;
    }
  };

  return (
    <MainLayout
      anchorEl={anchorEl}
      open={open}
      content={content}
      handleClick={handleClick}
      renderContent={renderContent}
    />
  );
};



export default WsonCircuitCreation