import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';

import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';

import {
  checkboxStyle,
  Heading,
  poppinsFormText,
  Text,
  Title,
} from '../../../themes/summaryStyle';

import CustomSelect from '../../../components/CustomSelect/CustomSelect';
import CustomButton from '../../../components/button/CustomButton';
import { useStepContext } from '../../../contexts/StepProviderContext';
import { Toast } from '../../../components/toaster/CustomToast';
import {
  mepOtnSummarySchema,
  validationMepOtnSummarySchema,
} from '../../../schema/schema';

const MepOtnSummary: React.FC = () => {
  const navigate = useNavigate();
  const { setActiveStep } = useStepContext();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<validationMepOtnSummarySchema>({
    resolver: zodResolver(mepOtnSummarySchema),
  });

  const handleBack = () => {
    setActiveStep(1);
    navigate('/mep/otn');
  };

  useEffect(() => {
    setActiveStep(5);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (data: validationMepOtnSummarySchema) => {
    Toast.success('Circuit created successfully!');
    setTimeout(() => {
      navigate('/mep/otn/circuit-creation');
    }, 500);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ width: { md: 'auto', lg: '979px', xl: 'auto' } }}>
        <Box
          sx={{
            background: '#FFF',
            borderRadius: '1px',
            padding: { xs: '20px', lg: '25px 48px' },
          }}
        >
          <Heading>Circuit Primitive Information</Heading>
          <Grid
            mt={'22px'}
            container
            display='flex'
            alignItems='start'
            justifyContent='center'
          >
            <Grid item xs={12} md={6}>
              <Grid
                container
                display='flex'
                alignItems='center'
                justifyContent='center'
                spacing={'26px'}
              >
                <Grid item xs={6} sm={6} md={5.1}>
                  <Title>Circuit Name:</Title>
                </Grid>
                <Grid item xs={6} sm={6} md={6.9}>
                  <Text>NMS Circuit 22</Text>
                </Grid>
                <Grid item xs={6} sm={6} md={5.1}>
                  <Title>Customer Name:</Title>
                </Grid>
                <Grid item xs={6} sm={6} md={6.9}>
                  <Text>Bharathi Airtel</Text>
                </Grid>
                <Grid item xs={6} sm={6} md={5.1}>
                  <Title>Capacity </Title>
                </Grid>
                <Grid item xs={6} sm={6} md={6.9}>
                  <Text>VC12</Text>
                </Grid>
                <Grid item xs={6} sm={6} md={5.1}>
                  <Title>Copies </Title>
                </Grid>
                <Grid item xs={6} sm={6} md={6.9}>
                  <Text>03</Text>
                </Grid>
                <Grid item xs={6} sm={6} md={5.1}>
                  <Title>Class of Service </Title>
                </Grid>
                <Grid item xs={6} sm={6} md={6.9}>
                  <Text>Protected</Text>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid
                container
                display='flex'
                alignItems='center'
                justifyContent='center'
                spacing={'26px'}
              >
                <Grid item xs={6} sm={6} md={5.1}>
                  <Title>Circuit Mode</Title>
                </Grid>
                <Grid item xs={6} sm={6} md={6.9}>
                  <Text>Single fiber mode</Text>
                </Grid>
                <Grid item xs={6} sm={6} md={5.1}>
                  <Title>Direction</Title>
                </Grid>
                <Grid item xs={6} sm={6} md={6.9}>
                  <Text>Uni</Text>
                </Grid>
                <Grid item xs={6} sm={6} md={5.1}>
                  <Title>Higher Oder</Title>
                </Grid>
                <Grid item xs={6} sm={6} md={6.9}>
                  <Text>GST</Text>
                </Grid>
                <Grid item xs={6} sm={6} md={5.1}>
                  <Title>Copies </Title>
                </Grid>
                <Grid item xs={6} sm={6} md={6.9}>
                  <Text>03</Text>
                </Grid>
                <Grid item xs={6} sm={6} md={5.1}>
                  <Title>Time Slots</Title>
                </Grid>
                <Grid item xs={6} sm={6} md={6.9}>
                  <Text>05</Text>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box
          mt={'17px'}
          sx={{
            background: '#FFF',
            borderRadius: '1px',
            padding: { xs: '20px', lg: '31px 48px' },
          }}
        >
          <Heading>Source Information</Heading>
          <Grid
            mt={'16px'}
            container
            display='flex'
            alignItems='start'
            justifyContent='start'
          >
            <Grid item xs={12} md={6}>
              <Grid
                container
                display='flex'
                alignItems='center'
                justifyContent='center'
                spacing={'26px'}
              >
                <Grid item xs={6} sm={6} md={5.1}>
                  <Title>Source Traffic Type</Title>
                </Grid>
                <Grid item xs={6} sm={6} md={6.9}>
                  <Text>VCG</Text>
                </Grid>
                <Grid item xs={6} sm={6} md={5.1}>
                  <Title>Node/IP</Title>
                </Grid>
                <Grid item xs={6} sm={6} md={6.9}>
                  <Text>10.51.110.130</Text>
                </Grid>
                <Grid item xs={6} sm={6} md={5.1}>
                  <Title>Select Port </Title>
                </Grid>
                <Grid item xs={6} sm={6} md={6.9}>
                  <Text>Port_v2_34</Text>
                </Grid>
                <Grid item xs={6} sm={6} md={5.1}>
                  <Title>Copies </Title>
                </Grid>
                <Grid item xs={6} sm={6} md={6.9}>
                  <Text>03</Text>
                </Grid>
                <Grid item xs={6} sm={6} md={5.1}>
                  <Title>Time Slot </Title>
                </Grid>
                <Grid item xs={6} sm={6} md={6.9}>
                  <Text>15 min</Text>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box
          mt={'17px'}
          sx={{
            background: '#FFF',
            borderRadius: '1px',
            padding: { xs: '20px', lg: '31px 48px' },
          }}
        >
          <Heading>Destination Information</Heading>
          <Grid
            mt={'16px'}
            container
            display='flex'
            alignItems='start'
            justifyContent='start'
          >
            <Grid item xs={12} md={6}>
              <Grid
                container
                display='flex'
                alignItems='center'
                justifyContent='center'
                spacing={'26px'}
              >
                <Grid item xs={6} sm={6} md={5.5}>
                  <Title>Destination Traffic Type</Title>
                </Grid>
                <Grid item xs={6} sm={6} md={6.5}>
                  <Text>VCG</Text>
                </Grid>
                <Grid item xs={6} sm={6} md={5.5}>
                  <Title>Node/IP</Title>
                </Grid>
                <Grid item xs={6} sm={6} md={6.5}>
                  <Text>10.51.110.130</Text>
                </Grid>
                <Grid item xs={6} sm={6} md={5.5}>
                  <Title>Select Port </Title>
                </Grid>
                <Grid item xs={6} sm={6} md={6.5}>
                  <Text>Port_v2_34</Text>
                </Grid>
                <Grid item xs={6} sm={6} md={5.5}>
                  <Title>Copies </Title>
                </Grid>
                <Grid item xs={6} sm={6} md={6.5}>
                  <Text>03</Text>
                </Grid>
                <Grid item xs={6} sm={6} md={5.5}>
                  <Title>Time Slot </Title>
                </Grid>
                <Grid item xs={6} sm={6} md={6.5}>
                  <Text>15 min</Text>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box
          mt={'15px'}
          sx={{
            background: '#FFF',
            borderRadius: '1px',
            padding: { xs: '20px', lg: '31px 48px' },
          }}
        >
          <Heading>Constraints</Heading>
          <Grid
            mt={'16px'}
            container
            display='flex'
            alignItems='start'
            justifyContent='start'
          >
            <Grid item xs={12} md={6}>
              <Grid
                container
                display='flex'
                alignItems='center'
                justifyContent='center'
                spacing={3}
              >
                <Grid item xs={6} sm={6} md={5.5}>
                  <Title>Work Path</Title>
                </Grid>
                <Grid item xs={6} sm={6} md={6.5}>
                  <Text>Include Nodes /Links</Text>
                </Grid>
                <Grid item xs={6} sm={6} md={5.5}>
                  <Title>Protected Path</Title>
                </Grid>
                <Grid item xs={6} sm={6} md={6.5}>
                  <Text>Exclude Nodes /Links</Text>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box
          mt={'29px'}
          sx={{
            background: '#FFF',
            borderRadius: '1px',
            padding: { xs: '20px', lg: '24px 48px' },
          }}
        >
          <Heading>Please Select type of Route Creation</Heading>
          <Grid
            container
            display='flex'
            alignItems='start'
            justifyContent='start'
            spacing={1}
            sx={{ paddingTop: '35px' }}
          >
            <Grid item xs={12}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox sx={checkboxStyle} {...register('tlBandwidth')} />
                  }
                  label='TL Bandwidth Calculation'
                  sx={{
                    '& .MuiFormControlLabel-label': {
                      ...poppinsFormText,
                      fontSize: '16px',
                    },
                  }}
                />
              </FormGroup>
              {errors.tlBandwidth && (
                <Typography
                  className='poppins-error-font'
                  mt='4px'
                  sx={{ color: '#F90000' }}
                >
                  {errors.tlBandwidth.message}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <FormControl>
                <RadioGroup
                  aria-labelledby='direction-group'
                  name='direction'
                  defaultValue=''
                  row
                >
                  <FormControlLabel
                    value='Topology'
                    control={
                      <Radio
                        sx={{
                          '& .MuiSvgIcon-root': {
                            fontSize: 20,
                          },
                          '&.Mui-checked': {
                            color: '#0000FF',
                          },
                        }}
                      />
                    }
                    label='Topology'
                    {...register('direction')}
                  />

                  <FormControlLabel
                    value='Outgoing Links'
                    control={
                      <Radio
                        sx={{
                          '& .MuiSvgIcon-root': {
                            fontSize: 20,
                          },
                          '&.Mui-checked': {
                            color: '#0000FF',
                          },
                        }}
                      />
                    }
                    label='Outgoing Links'
                    {...register('direction')}
                  />
                </RadioGroup>
              </FormControl>
              {errors.direction && (
                <Typography
                  className='poppins-error-font'
                  mt='4px'
                  sx={{ color: '#F90000' }}
                >
                  {errors.direction.message}
                </Typography>
              )}
            </Grid>{' '}
            <Grid item xs={12}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox {...register('autoPlanned')} sx={checkboxStyle} />
                  }
                  label='Auto Path'
                  sx={{
                    '& .MuiFormControlLabel-label': {
                      ...poppinsFormText,
                      fontSize: '16px',
                    },
                  }}
                />
              </FormGroup>
              {errors.autoPlanned && (
                <Typography
                  className='poppins-error-font'
                  mt='4px'
                  sx={{ color: '#F90000' }}
                >
                  {errors.autoPlanned.message}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} mt={'20px'}>
              <CustomSelect
                label={
                  <>
                    Disjointness <span style={{ color: 'red' }}>*</span>
                  </>
                }
                options={[
                  { value: 'option1', label: 'Option 1' },
                  { value: 'option2', label: 'Option 2' },
                ]}
                {...register('disjointness')}
              />
              {errors.disjointness && (
                <Typography
                  className='poppins-error-font'
                  mt='4px'
                  sx={{ color: '#F90000' }}
                >
                  {errors.disjointness.message}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} mt={'23px'}>
              <Box sx={{ display: 'flex', gap: '20px' }}>
                <CustomSelect
                  label={
                    <>
                      No Of path <span style={{ color: 'red' }}>*</span>
                    </>
                  }
                  options={[
                    { value: 'option1', label: 'Option 1' },
                    { value: 'option2', label: 'Option 2' },
                  ]}
                  width={{ lg: '496px', md: '350px', sm: '200px', xs: '150px' }}
                  {...register('noOfPath')}
                />
                <Button
                  className='poppins-font'
                  sx={{
                    backgroundColor: '#ECF7FF',
                    color: '#06152B;',
                    textTransform: 'none',
                    borderRadius: '10px',
                    maxHeight: '49px',
                    width: { sm: '125px', lg: '171px' },
                    border: '2px solid #D9E1E7',
                    padding: '17px 0px 23px 0px',
                  }}
                  variant='outlined'
                >
                  Find Path
                </Button>
              </Box>
              {errors.noOfPath && (
                <Typography
                  className='poppins-error-font'
                  mt='4px'
                  sx={{ color: '#F90000' }}
                >
                  {errors.noOfPath.message}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{ padding: { xs: '20px', md: '45px' } }}
          display='flex'
          gap='20px'
          alignItems='center'
        >
          <CustomButton
            variantType='secondary'
            size='large'
            padding='1rem 5rem'
            onClick={handleBack}
          >
            Back
          </CustomButton>
          <CustomButton
            size='large'
            padding='1rem 5rem'
            bg='#0000FF'
            type='submit'
          >
            Submit
          </CustomButton>
        </Box>
      </Box>
    </form>
  );
};

export default MepOtnSummary;
