import React from 'react';
import OtnGmplsBasicCircuit from '../../forms/p2p/otnGmpls/OtnGmplsBasicCircuit';
import CommonAccordion from '../../components/accordion/CommonAccordion';
import OtnGmplsSouceInfo from '../../forms/p2p/otnGmpls/OtnGmplsSouceInfo';
import OtnGmplsDestinationInfo from '../../forms/p2p/otnGmpls/OtnGmplsDestInfo';
import OtnGmplsConstraints from '../../forms/p2p/otnGmpls/OtnGmplsConstraints';

const OtnGmpls: React.FC = () => {
  const accordions = [
    {
      title: 'Circuit Primitive Information',
      description:
        'Please enter a few details below Listed Mandatory Fields, Need More Please filter From advance',
      content: <OtnGmplsBasicCircuit onNext={() => {}} />, // Pass a dummy onNext function for now
    },
    {
      title: 'Source Information',
      description:
        'Please enter a few details below Listed Mandatory Fields.',
      content: <OtnGmplsSouceInfo onNext={() => {}} />, // Pass a dummy onNext function for now
    },
    {
      title: 'Destination Information',
      description:
        'Please enter a few details below Listed Mandatory Fields.',
      content: <OtnGmplsDestinationInfo onNext={() => {}} />, // Pass a dummy onNext function for now
    },
    {
      title: 'GMPLS Constraints',
      description:
        'Please enter a few details below Listed Mandatory Fields.',
      content: <OtnGmplsConstraints onNext={() => {}} />, // Pass a dummy onNext function for now
    },
  ];
  return (
    <div>
      {/* {activeStep === 5 ? (
        <Box sx={{ width: {md:"calc(100vw - 390px)",lg:"calc(100vw - 450px)"} }}>
          <OtnGmplsSummary />
        </Box>
      ) : (
        <CommonAccordion accordions={accordions} />
      )} */}
      <CommonAccordion accordions={accordions} />
    </div>
  );
};

export default OtnGmpls;
