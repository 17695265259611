import React, { useEffect, useState } from 'react';

import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Typography,
  Collapse,
  IconButton,
  SelectChangeEvent,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import {
  CircuitButtonStyle,
  customPadding,
  textStyle,
} from '../../themes/summaryStyle';
import { useCircuitContext } from '../../contexts/CircuitContext';
import { useLocation } from 'react-router-dom';

interface CircuitSidebarProps {
  setShowTable: React.Dispatch<React.SetStateAction<boolean>>;
  setTableVisibility: (isVisible: boolean) => void;
}

const options = [
  { value: 'rearrange-graph', label: 'Re-Arrange Graph' },
  { value: 'show-alarms', label: 'Show Alarms' },
  { value: 'show-link-status', label: 'Show Link Status' },
];

const CircuitSidebar: React.FC<CircuitSidebarProps> = ({
  setTableVisibility,
}) => {
  const [expandedPanels, setExpandedPanels] = useState<Set<string>>(
    new Set(['panel4'])
  );
  const { selectedValue, subTypeSelect, setSelectedValue, setSubTypeSelect } =
    useCircuitContext();
  const [layout, setLayout] = useState<string>();
  const [selectedOption, setSelectedOption] = useState<string>('');
  const location = useLocation();
  const path = location.pathname.split('/');

  const handleExpandClick = (panel: string) => {
    setExpandedPanels((prev) => {
      const newExpandedPanels = new Set(prev);
      if (newExpandedPanels.has(panel)) {
        newExpandedPanels.delete(panel);
      } else {
        newExpandedPanels.add(panel);
      }
      return newExpandedPanels;
    });
  };

  const handleChange = (event: SelectChangeEvent<string>) => {
    setSelectedOption(event.target.value);
  };

  useEffect(() => {
    if (path.length >= 4) {
      setSelectedValue(path[1]);
      setSubTypeSelect(path[2]);
    }
    const updateLayout = () => {
      if (selectedValue === 'p2p') {
        switch (subTypeSelect) {
          case 'dwdm':
            setLayout('dwdm');
            break;
          case 'wson':
            setLayout('wson');
            break;
          case 'otn':
            setLayout('otn');
            break;
          case 'otn-gmpls':
            setLayout('otn-gmpls');
            break;
          case 'sdh':
            setLayout('sdh');
            break;
          default:
            setLayout('otn-gmpls');
            break;
        }
      } else if (selectedValue === 'mep') {
        if (subTypeSelect === 'otn') {
          setLayout('otn');
        } else {
          setLayout('otn');
        }
      } else {
        setLayout('otn');
      }
    };

    updateLayout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue, subTypeSelect]);

  return (
    <Box
      sx={{
        width: '226px',
        mt: '43px',
        pt: '15px',
        borderLeft: '1px solid #E7E7F1',
        background: '#FFF',
        '&::-webkit-scrollbar': {
          width: '0px',
        },
      }}
    >
      <Box sx={customPadding}>
        {/* Heading */}
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Typography sx={textStyle}>Work Mode</Typography>
          <IconButton
            onClick={() => handleExpandClick('panel4')}
            sx={{ paddingRight: '5px' }}
          >
            {expandedPanels.has('panel4') ? <RemoveIcon /> : <AddIcon />}
          </IconButton>
        </Box>
        {/* Options */}
        <Collapse
          in={expandedPanels.has('panel4')}
          timeout='auto'
          unmountOnExit
        >
          <Box
            mt={'7px'}
            display='flex'
            alignItems='center'
            flexWrap='wrap'
            gap='10px'
          >
            <Button
              variant='outlined'
              sx={{
                ...textStyle,
                ...CircuitButtonStyle,
              }}
            >
              Work
            </Button>
            <Button
              variant='outlined'
              sx={{
                ...textStyle,
                ...CircuitButtonStyle,
              }}
            >
              Protect Path
            </Button>
            {selectedValue === 'p2p' && layout === 'otn-gmpls' && (
              <Button
                variant='outlined'
                sx={{
                  ...textStyle,
                  ...CircuitButtonStyle,
                  padding: '.4rem 1.5rem',
                }}
              >
                Re-Route
              </Button>
            )}
            {selectedValue === 'p2p' && layout === 'otn' && (
              <>
                <Button
                  variant='outlined'
                  sx={{
                    ...textStyle,
                    ...CircuitButtonStyle,
                  }}
                >
                  Tx Circuit
                </Button>{' '}
                <Button
                  variant='outlined'
                  sx={{
                    ...textStyle,
                    ...CircuitButtonStyle,
                  }}
                >
                  Rx Circuit
                </Button>
              </>
            )}
            {selectedValue === 'p2p' && layout === 'sdh' && (
              <>
                <Button
                  variant='outlined'
                  sx={{
                    ...textStyle,
                    ...CircuitButtonStyle,
                  }}
                >
                  Backup
                </Button>{' '}
                <Button
                  variant='outlined'
                  sx={{
                    ...textStyle,
                    ...CircuitButtonStyle,
                    padding: '.4rem .8rem',
                  }}
                >
                  Re-Route
                </Button>
                <Button
                  variant='outlined'
                  sx={{
                    ...textStyle,
                    ...CircuitButtonStyle,
                    padding: '.4rem 2rem',
                  }}
                >
                  Protect Continue{' '}
                </Button>
              </>
            )}
            {selectedValue === 'mep' && layout === 'otn' && (
              <Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked
                      sx={{
                        '& .MuiSvgIcon-root': { fontSize: 15 },
                        '&.Mui-checked': {
                          color: '#2F4CDD',
                        },
                      }}
                      inputProps={{ 'aria-label': 'Checkbox demo' }}
                    />
                  }
                  label={
                    <Typography sx={{ ...textStyle, fontSize: '10px' }}>
                      Enable inter coneect
                    </Typography>
                  }
                />
                <Button
                  variant='outlined'
                  sx={{
                    ...textStyle,
                    ...CircuitButtonStyle,
                    marginLeft: '16px',
                    padding: '.4rem .8rem',
                  }}
                >
                  Inter connect
                </Button>
              </Box>
            )}
          </Box>
        </Collapse>
      </Box>
      <Divider />
      <Box textAlign='center' sx={customPadding}>
        <Button
          variant='outlined'
          sx={{
            ...textStyle,
            '&:active': {
              border: '2px solid #00F',
              backgroundColor: '#ECF3F9',
            },
            '&:hover': {
              border: '2px solid #00F',
              backgroundColor: '#ECF3F9',
            },
            maxWidth: '199px',
            width: '100%',
            height: '36px',
            alignContent: 'center',
            padding: '1.3rem .5rem 1.3rem .5rem',
            borderRadius: '5px',
            border: '2px solid #D0D5DE',
            background: '#ECF3F9',
          }}
          onClick={() => setTableVisibility(true)}
        >
          TL Outgoing{' '}
        </Button>
      </Box>
      <Divider />
      {/* Hide/Show Labels */}
      <Box
        display='flex'
        alignItems='center'
        justifyContent='space-between'
        sx={{
          ...customPadding,
        }}
      >
        <Typography sx={textStyle}>Hide/Show Labels</Typography>
        <IconButton
          onClick={() => handleExpandClick('panel1')}
          sx={{ paddingRight: '5px' }}
        >
          {expandedPanels.has('panel1') ? <RemoveIcon /> : <AddIcon />}
        </IconButton>
      </Box>
      <Collapse in={expandedPanels.has('panel1')} timeout='auto' unmountOnExit>
        <Box
          sx={{
            padding: '0rem 1rem',
            marginTop: '-12px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked
                sx={{
                  '& .MuiSvgIcon-root': { fontSize: 15 },
                  '&.Mui-checked': {
                    color: '#2F4CDD',
                  },
                }}
                inputProps={{ 'aria-label': 'Checkbox demo' }}
              />
            }
            label={
              <Typography sx={{ ...textStyle, fontSize: '10px' }}>
                Port Label
              </Typography>
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked
                sx={{
                  '& .MuiSvgIcon-root': { fontSize: 15 },
                  '&.Mui-checked': {
                    color: '#2F4CDD',
                  },
                }}
                inputProps={{ 'aria-label': 'Checkbox demo' }}
              />
            }
            label={
              <Typography sx={{ ...textStyle, fontSize: '10px' }}>
                LOP Alarms
              </Typography>
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked
                sx={{
                  '& .MuiSvgIcon-root': { fontSize: 15 },
                  '&.Mui-checked': {
                    color: '#2F4CDD',
                  },
                }}
                inputProps={{ 'aria-label': 'Checkbox demo' }}
              />
            }
            label={
              <Typography sx={{ ...textStyle, fontSize: '10px' }}>
                Timeslots
              </Typography>
            }
          />{' '}
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked
                sx={{
                  '& .MuiSvgIcon-root': { fontSize: 15 },
                  '&.Mui-checked': {
                    color: '#2F4CDD',
                  },
                }}
                inputProps={{ 'aria-label': 'Checkbox demo' }}
              />
            }
            label={
              <Typography sx={{ ...textStyle, fontSize: '10px' }}>
                Service Affecting Alarms
              </Typography>
            }
          />{' '}
        </Box>
      </Collapse>
      <Divider />

      {/* Select/UN-select rent Pair */}
      <Box
        display='flex'
        alignItems='center'
        justifyContent='space-between'
        sx={customPadding}
      >
        <Typography sx={textStyle}>Select/UN-select rent Pair</Typography>
        <IconButton
          onClick={() => handleExpandClick('panel2')}
          sx={{ paddingRight: '5px' }}
        >
          {expandedPanels.has('panel2') ? <RemoveIcon /> : <AddIcon />}
        </IconButton>
      </Box>
      <Collapse in={expandedPanels.has('panel2')} timeout='auto' unmountOnExit>
        <Box sx={{ ...customPadding, mt: 1 }}>
          <Typography sx={textStyle}>
            Content for Select/UN-select rent Pair
          </Typography>
        </Box>
      </Collapse>
      <Divider />
      {/* Manage */}
      <Box
        display='flex'
        alignItems='center'
        justifyContent='space-between'
        sx={customPadding}
      >
        <Typography sx={textStyle}>Manage</Typography>
        <IconButton
          onClick={() => handleExpandClick('panel3')}
          sx={{ paddingRight: '5px' }}
        >
          {expandedPanels.has('panel3') ? <RemoveIcon /> : <AddIcon />}
        </IconButton>
      </Box>
      <Collapse in={expandedPanels.has('panel3')} timeout='auto' unmountOnExit>
        <FormControl
          sx={{
            marginLeft: '16px',
            marginBottom: '16px',
            width: 150,
            '& .MuiOutlinedInput-root': {
              '&.Mui-focused fieldset': {
                borderColor: '#0000FF',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: '#00F',
                backgroundColor: 'rgba(220, 226, 255, 0.5)',
              },
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: '2px solid #D9E1E7',
            },
          }}
          size='small'
        >
          <InputLabel id='demo-simple-select-label' sx={{ fontSize: '10px' }}>
            Select Option
          </InputLabel>
          <Select
            labelId='demo-simple-select-label'
            id='demo-simple-select'
            label='Select Option'
            value={selectedOption}
            onChange={handleChange}
            IconComponent={KeyboardArrowDownIcon}
            sx={{ fontSize: '10px' }}
            MenuProps={{
              PaperProps: {
                sx: {
                  backgroundColor: '#292929', // Background color of the menu
                  '& .MuiMenuItem-root': {
                    color: '#FFFFFF', // Text color of the menu items
                    '&:hover': {
                      backgroundColor: 'gray', // Background color of menu item on hover
                    },
                    '&.Mui-selected': {
                      backgroundColor: '#3348FF', // Background color of the selected menu item
                    },
                  },
                },
              },
              disableScrollLock: true,
            }}
          >
            {options.map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
                sx={{
                  fontSize: '10px',
                }}
              >
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Collapse>
      <Divider />
      <Box textAlign='center' sx={customPadding}>
        <Button
          variant='outlined'
          sx={{
            ...textStyle,
            '&:active': {
              border: '2px solid #00F',
              backgroundColor: '#ECF3F9',
            },
            '&:hover': {
              border: '2px solid #00F',
              backgroundColor: '#ECF3F9',
            },
            maxWidth: '199px',
            width: '100%',
            height: '36px',
            alignContent: 'center',
            padding: '1.3rem .5rem 1.3rem .5rem',
            borderRadius: '5px',
            border: '2px solid #D0D5DE',
            background: '#ECF3F9',
          }}
        >
          Miscellaneous
        </Button>
      </Box>
      <Divider />
      <Box textAlign='center' sx={customPadding}>
        <Button
          variant='outlined'
          sx={{
            ...textStyle,
            '&:active': {
              border: '2px solid #00F',
              backgroundColor: '#ECF3F9',
            },
            '&:hover': {
              border: '2px solid #00F',
              backgroundColor: '#ECF3F9',
            },
            maxWidth: '199px',
            width: '100%',
            height: '36px',
            alignContent: 'center',
            padding: '1.3rem .5rem 1.3rem .5rem',
            borderRadius: '5px',
            border: '2px solid #D0D5DE',
            background: '#ECF3F9',
          }}
        >
          Topology
        </Button>
      </Box>
      <Divider />
      <Box sx={customPadding}>
        <FormControlLabel
          control={
            <Checkbox
              defaultChecked
              sx={{
                '& .MuiSvgIcon-root': { fontSize: 18 },
                '&.Mui-checked': {
                  color: '#2F4CDD',
                },
              }}
              inputProps={{ 'aria-label': 'Checkbox demo' }}
            />
          }
          label={<Typography sx={textStyle}>Show Outgoing Links</Typography>}
        />
        <FormControlLabel
          control={
            <Checkbox
              defaultChecked
              sx={{
                '& .MuiSvgIcon-root': { fontSize: 18 },
                '&.Mui-checked': {
                  color: '#2F4CDD',
                },
              }}
              inputProps={{ 'aria-label': 'Checkbox demo' }}
            />
          }
          label={<Typography sx={textStyle}>Show Incoming Links</Typography>}
        />
        {selectedValue === 'p2p' && layout === 'sdh' && (
          <Button
            variant='outlined'
            sx={{
              ...textStyle,
              ...CircuitButtonStyle,
              mt: '10px',
            }}
          >
            Show Selection
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default CircuitSidebar;
