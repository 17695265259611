import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import AppRoutes from './route/AppRoutes';
import { CircuitProvider } from './contexts/CircuitContext';
import { ToastProvider } from './components/toaster/CustomToast';

import store from './store/store';

function App() {
  return (
    <div>
      <Provider store={store}>
        <ToastProvider>
          <CircuitProvider>
            <Router>
              <AppRoutes />
            </Router>
          </CircuitProvider>
        </ToastProvider>
      </Provider>
    </div>
  );
}

export default App;
