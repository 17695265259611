import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Divider, FormControl, MenuItem } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import refresh from '../../assets/svg/refresh.svg';
import deleteIcon from '../../assets/svg/delete.svg';
import backArrow from '../../assets/svg/backArrow.svg';
import info from '../../assets/images/infoIcon.png';

import { textStyle } from '../../themes/summaryStyle';
import { useStepContext } from '../../contexts/StepProviderContext';
import { useCircuitContext } from '../../contexts/CircuitContext';

const CircuitHeader: React.FC = () => {
  const { selectedValue, subTypeSelect, setSelectedValue, setSubTypeSelect } =
    useCircuitContext();

  const navigate = useNavigate();
  const { setActiveStep } = useStepContext();

  const handleChange = (event: SelectChangeEvent) => {
    const value = event.target.value as string;
    setSelectedValue(value);
  };

  const subTypeHandleChange = (event: SelectChangeEvent) => {
    const value = event.target.value as string;
    setSubTypeSelect(value);
  };

  const options = [
    { value: 'p2p', label: 'Point to Point' },
    { value: 'mep', label: 'MEP' },
  ];

  const options_subType = [
    { value: 'dwdm', label: 'DWDM' },
    { value: 'wson', label: 'WSON' },
    { value: 'otn', label: 'OTN' },
    { value: 'otn-gmpls', label: 'OTN-GMPLS' },
    { value: 'sdh', label: 'SDH' },
  ];

  const handleNavigation = () => {
    if (selectedValue === 'p2p') {
      if (subTypeSelect === 'otn') {
        navigate('/p2p/otn/summary');
      } else if (subTypeSelect === 'otn-gmpls') {
        navigate('/p2p/otn-gmpls/summary');
      } else if (subTypeSelect === 'dwdm') {
        navigate('/p2p/dwdm/summary');
      } else if (subTypeSelect === 'sdh') {
        navigate('/p2p/sdh/summary');
      }else if (subTypeSelect === 'wson') {
        navigate('/p2p/wson/summary');
      }
    } else if (selectedValue === 'mep') {
      if (subTypeSelect === 'otn') {
        navigate('/mep/otn/summary');
      } 
    }
  };

  

  return (
    <Box
      sx={{
        background: '#292C37',
        position: 'fixed',
        left: 0,
        right: 0,
        zIndex: 10,
        top: 0,
      }}
    >
      <Box
        sx={{
          maxWidth: '1600px',
          margin: 'auto',
          background: '#292C37',
          height: 'calc(66px-100vh)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box
            component='img'
            src={backArrow}
            alt='refresh icon'
            onClick={() => {
              setActiveStep(5);
              handleNavigation();
            }}
            sx={{
              marginLeft: { xs: '20px', md: '27px' },
              marginRight: { xs: '20px', md: '23px' },
              cursor: 'pointer',
              height: '28px',
              width: '28px',
              marginTop: '21px',
              marginBottom: '17px',
            }}
          />
          <Divider
            orientation='vertical'
            flexItem
            sx={{
              background: '#515151',
              width: '1px',
              height: '66px',
              margin: { xs: '0px 0px 0px 0px', md: '0px 19px 0px 0px' },
            }}
          />
          <FormControl
            sx={{
              width: '120px',
              marginRight: { xs: '2px', md: '27px' },
              marginTop: '9.5px',
              marginBottom: '5.5px',
            }}
          >
            <Select
              id='demo-simple-select'
              value={selectedValue}
              onChange={handleChange}
              displayEmpty
              inputProps={{
                readOnly: true,
              }}
              renderValue={(selected) => {
                if (!selected) {
                  return <em>None</em>;
                }
                const selectedOption = options.find(
                  (option) => option.value === selected
                );
                return selectedOption ? selectedOption.label : selected;
              }}
              IconComponent={(props) => (
                <KeyboardArrowDownIcon
                  {...props}
                  sx={{ color: '#E2E2E2', marginRight: '0px' }}
                />
              )}
              sx={{
                '& .MuiSelect-select': {
                  color: '#E2E2E2',
                  fontFamily: 'Poppins',
                  fontSize: '12px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: 'normal',
                  paddingRight: '32px', // Adjust paddingRight to bring text closer to icon
                  display: 'flex',
                  alignItems: 'center',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                  padding: '0px',
                },
                '& .MuiSelect-icon': {
                  color: '#E2E2E2',
                  marginRight: '-8px', // Adjust marginRight to bring the icon closer to the text
                },
              }}
              MenuProps={{
                disableScrollLock: true,
              }}
            >
              {options.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  sx={{ fontSize: '12px' }}
                >
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl
            sx={{ width: '110px', marginTop: '9.5px', marginBottom: '5.5px' }}
          >
            <Select
              id='demo-simple-select'
              value={subTypeSelect}
              onChange={subTypeHandleChange}
              displayEmpty
              inputProps={{
                readOnly: true,
              }}
              renderValue={(selected) => {
                if (!selected) {
                  return <em>None</em>;
                }
                const selectedOption = options_subType.find(
                  (option) => option.value === selected
                );
                return selectedOption ? selectedOption.label : selected;
              }}
              IconComponent={(props) => (
                <KeyboardArrowDownIcon
                  {...props}
                  sx={{ color: '#E2E2E2', marginRight: '0px' }}
                />
              )}
              sx={{
                '& .MuiSelect-select': {
                  color: '#E2E2E2',
                  fontFamily: 'Poppins',
                  fontSize: '12px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: 'normal',
                  paddingRight: '32px', // Adjust paddingRight to bring text closer to icon
                  display: 'flex',
                  alignItems: 'center',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
                '& .MuiSelect-icon': {
                  color: '#E2E2E2',
                  marginRight: '-8px', // Adjust marginRight to bring the icon closer to the text
                },
              }}
              MenuProps={{
                disableScrollLock: true,
              }}
            >
              {options_subType.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  sx={{ fontSize: '12px' }}
                >
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Divider
            orientation='vertical'
            flexItem
            sx={{
              background: '#515151',
              width: '1px',
              height: '66px',
              margin: { xs: '0px 10px 0px 10px', md: '0px 18px 0px 13px' },
            }}
          />
          <Box
            component='img'
            src={refresh}
            alt='refresh icon'
            sx={{
              marginRight: { xs: '10px', md: '22px' },
              cursor: 'pointer',
              marginTop: '23px',
              marginBottom: '21px',
            }}
          />
          <Box
            component='img'
            src={deleteIcon}
            alt='delete icon'
            sx={{ cursor: 'pointer', marginTop: '23px', marginBottom: '21px' }}
          />
        </Box>
        <Box display='flex' alignItems='center'>
          <Button
            sx={{
              ...textStyle,
              color: '#D0D5DE',
              width: '111px',
              height: '35px',
              fontSize: '12px',
              borderRadius: ' 5px',
              border: '1px solid #2F4CDD',
              background: '#2F4CDD',
              padding: '0px',
              marginRight: '12px',
              marginTop: '16px',
              marginBottom: '15px',
            }}
            variant='contained'
          >
            Create Circuit{' '}
          </Button>
          <Button
            sx={{
              ...textStyle,
              color: '#D0D5DE',
              width: '179px',
              height: '35px',
              fontSize: '12px',
              borderRadius: ' 5px',
              border: '1px solid #99B2C6',
              padding: '0px',
              marginRight: { xs: '20px', md: '55px' },
              marginTop: '16px',
              marginBottom: '15px',
            }}
            variant='outlined'
          >
            Create & Activate Circuit
          </Button>
          <Box
            component='img'
            src={info}
            alt='refresh icon'
            sx={{
              marginRight: '1.5rem',
              height: '26px',
              width: '26px',
              cursor: 'pointer',
              marginTop: '22px',
              marginBottom: '18px',
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default CircuitHeader;
