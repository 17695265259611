import React, { createContext, useContext, useState, ReactNode } from 'react';

interface StepContextProps {
  activeStep: number;
  setActiveStep: (step: number) => void;
  clickedStep: number;
  setClickedStep: (step: number) => void;
  formNumber: number;
  setFormNumber: (step: number) => void;
}

const StepContext = createContext<StepContextProps>({
  activeStep: 0,
  setActiveStep: () => {},
  clickedStep: 1, // Default to the first step
  setClickedStep: () => {},
  formNumber: 0,
  setFormNumber: () => {},
});

export const useStepContext = () => useContext(StepContext);

interface StepProviderProps {
  children: ReactNode;
}

export const StepProvider: React.FC<StepProviderProps> = ({ children }) => {
  const [activeStep, setActiveStep] = useState<number>(0);
  const [clickedStep, setClickedStep] = useState<number>(1); // Initialize with a default step, e.g., the first step
  const [formNumber, setFormNumber] = useState<number>(0);

  return (
    <StepContext.Provider
      value={{
        activeStep,
        setActiveStep,
        clickedStep,
        setClickedStep,
        formNumber,
        setFormNumber,
      }}
    >
      {children}
    </StepContext.Provider>
  );
};
