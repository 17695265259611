// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./assets/fonts/fonts.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* html{
  overflow: hidden;
} */

body {
  overflow: auto !important;
  max-height: 100vh;
  background-color: #f8f9fa;
}

.poppins-font {
  font-family: "Poppins", sans-serif !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
}

.poppins-error-font {
  font-family: "Poppins", sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  font-style: normal;
  line-height: normal !important;
}

.poppins-font-primary {
  font-family: "Poppins", sans-serif !important;
  font-size: 10px !important;
  font-weight: 400 !important;
  font-style: normal;
  line-height: normal !important;
}

.poppins-font-secondry {
  font-family: "Poppins", sans-serif !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  font-style: normal;
  line-height: normal !important;
}

.poppins-font-heading {
  font-family: "Poppins", sans-serif !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAEA;EACE,sBAAsB;EACtB,SAAS;EACT,UAAU;AACZ;;AAEA;;GAEG;;AAEH;EACE,yBAAyB;EACzB,iBAAiB;EACjB,yBAAyB;AAC3B;;AAEA;EACE,6CAA6C;EAC7C,0BAA0B;EAC1B,2BAA2B;EAC3B,4BAA4B;AAC9B;;AAEA;EACE,6CAA6C;EAC7C,0BAA0B;EAC1B,2BAA2B;EAC3B,kBAAkB;EAClB,8BAA8B;AAChC;;AAEA;EACE,6CAA6C;EAC7C,0BAA0B;EAC1B,2BAA2B;EAC3B,kBAAkB;EAClB,8BAA8B;AAChC;;AAEA;EACE,6CAA6C;EAC7C,0BAA0B;EAC1B,2BAA2B;EAC3B,kBAAkB;EAClB,8BAA8B;AAChC;;AAEA;EACE,6CAA6C;EAC7C,0BAA0B;EAC1B,6BAA6B;EAC7B,2BAA2B;EAC3B,8BAA8B;AAChC","sourcesContent":["@import url(\"../src/assets/fonts/fonts.css\");\n\n* {\n  box-sizing: border-box;\n  margin: 0;\n  padding: 0;\n}\n\n/* html{\n  overflow: hidden;\n} */\n\nbody {\n  overflow: auto !important;\n  max-height: 100vh;\n  background-color: #f8f9fa;\n}\n\n.poppins-font {\n  font-family: \"Poppins\", sans-serif !important;\n  font-size: 16px !important;\n  font-weight: 400 !important;\n  line-height: 24px !important;\n}\n\n.poppins-error-font {\n  font-family: \"Poppins\", sans-serif !important;\n  font-size: 14px !important;\n  font-weight: 400 !important;\n  font-style: normal;\n  line-height: normal !important;\n}\n\n.poppins-font-primary {\n  font-family: \"Poppins\", sans-serif !important;\n  font-size: 10px !important;\n  font-weight: 400 !important;\n  font-style: normal;\n  line-height: normal !important;\n}\n\n.poppins-font-secondry {\n  font-family: \"Poppins\", sans-serif !important;\n  font-size: 12px !important;\n  font-weight: 400 !important;\n  font-style: normal;\n  line-height: normal !important;\n}\n\n.poppins-font-heading {\n  font-family: \"Poppins\", sans-serif !important;\n  font-size: 18px !important;\n  font-style: normal !important;\n  font-weight: 500 !important;\n  line-height: normal !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
