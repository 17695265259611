import { createTheme } from "@mui/material/styles";

const customTheme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            borderRadius: "10px",
            "& fieldset": {
              border: "2px solid #D9E1E7",
            },
            "&:hover fieldset": {
              border: "2px solid #D9E1E7",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#0000FF",
            },
          },
          "& .MuiInputLabel-root": {
            color: "#06152B",
            fontWeight: 400,
            fontFamily: "Poppins, sans-serif",
          },
          "& .MuiInputLabel-asterisk": {
            color: "red", // Red color for the asterisk
          },
          "& .Mui-focused .MuiInputLabel-root": {
            color: "black", // Black color for the label when focused
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: "grey",
          color: "white",
          "&:hover": {
            backgroundColor: "blue",
          },
        },
      },
    },
    // MuiSelect: {
    //   styleOverrides: {
    //     root: {
    //       '& .MuiOutlinedInput-root': {
    //         '& fieldset': {
    //           borderColor: 'blue',
    //         },
    //         '&:hover fieldset': {
    //           borderColor: 'blue',
    //         },
    //         '&.Mui-focused fieldset': {
    //           borderColor: 'blue',
    //         },
    //       },
    //       '& .MuiInputLabel-root': {
    //         color: 'black', // Default color for the label
    //       },
    //       '& .MuiInputLabel-asterisk': {
    //         color: 'red', // Red color for the asterisk
    //       },
    //       '& .Mui-focused .MuiInputLabel-root': {
    //         color: 'black', // Black color for the label when focused
    //       },
    //     },
    //   },
    // },
  },
});

export default customTheme;
