// StyledSelect.tsx
import React from 'react';
import {
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';

interface StyledSelectProps {
  value: string;
  options: Array<{ label: string; value: string }>;
  onChange: (event: SelectChangeEvent<string>) => void;
  placeholder?: string;
}

const StyledSelect: React.FC<StyledSelectProps> = ({
  value,
  options,
  onChange,
}) => {
  return (
    <FormControl sx={{ p: 0 }}>
      <Select
        className="poppins-font-secondry"
        displayEmpty
        value={value}
        inputProps={{
          readOnly: true,
        }}
        onChange={onChange}
        sx={{
          width: '237px',
          maxHeight: '35px',
          color: '#06152B',
          mt: 1,
          padding: '-4px',
          borderRadius: '4px',
          background: '#F3F3FE',
          border: '1px solid #D9E1E7',
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
          '& .MuiSvgIcon-root.MuiSelect-icon': {
            display: 'none', // Hides the dropdown arrow
          },
        }}
        MenuProps={{
          disableScrollLock: true,
        }}
      >
        {/* {placeholder && (
          <MenuItem value="" disabled>
            {placeholder}
          </MenuItem>
        )} */}
        {options.map((option, index) => (
          <MenuItem key={index} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default StyledSelect;
