import { Box, IconButton, Popper, Fade, Paper } from '@mui/material';

import starIcon from '../../assets/svg/star.svg';
import node from '../../assets/svg/node.png';
import route from '../../assets/svg/route.svg';
import triIcon from '../../assets/svg/tri-icon.svg';

interface LeftSideBarProps {
  anchorEl: HTMLButtonElement | null;
  open: boolean;
  content: string;
  handleClick: (event: any, contentId: string) => void;
  renderContent: () => React.ReactNode;
}
const LeftSideBar = (props: LeftSideBarProps) => {
  const { content, anchorEl, open, handleClick, renderContent } = props;

  const iconsData = [
    { label: 'circuit type', icon: triIcon, contentId: 'circuit-type' },
    { label: 'source information', icon: starIcon, contentId: 'source-info' },
    { label: 'node info', icon: node, contentId: 'node-info' },
    { label: 'route info', icon: route, contentId: 'route-info' },
  ];

  return (
    <>
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement={'right-start'}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper
              sx={{
                ml: '10px',
                boxShadow: '0px 8px 7px 0px rgba(0, 0, 0, 0.17)',
                border: '1px #E4E4E4',
              }}
            >
              {renderContent()}
            </Paper>
          </Fade>
        )}
      </Popper>
      <Box
        sx={{
          mt: '50px',
          pt: '35px',
          width: { md: '78px', sm: '69px' },
          height: '100vh',
          backgroundColor: '#152365',
        }}
      >
        {/* <Box sx={{ paddingInline: '18px', paddingBlock: '25px' }}>
          <IconButton aria-label="info" onClick={() => navigate(-1)}>
            <img src={backButton} alt="back button" />
          </IconButton>
        </Box> */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {iconsData.map(({ label, icon, contentId }) => (
            <Box
              key={contentId}
              sx={{
                paddingInline: { md: '18px', sm: '11px' },
                paddingBlock: '12px',
                backgroundColor:
                  content === contentId && open ? '#1935BD' : 'transparent',
              }}
              onClick={(e) => handleClick(e, contentId)}
            >
              <IconButton aria-label={label}>
                <img src={icon} alt={`${label} icon`} />
              </IconButton>
            </Box>
          ))}
        </Box>
      </Box>
    </>
  );
};

export default LeftSideBar;
