// validationSchema.ts
import { z } from 'zod';

export const customerSchema = z.object({
  circuitName: z.string().min(1, { message: 'Circuit name is required' }),
  customerName: z.string().min(1, { message: 'Customer name is required' }),
  capacity: z.string().min(1, { message: 'Capacity is required' }),
  copies: z.string().min(1, { message: 'Copies is required' }),
  classOfService: z
    .string()
    .min(1, { message: 'Class of service is required' }),
  autoPlanned: z.boolean(),
});
export type validationCustomerSchemaType = z.infer<typeof customerSchema>;

export const otnCustomerSchema = z.object({
  circuitName: z.string().min(1, { message: 'Circuit name is required' }),
  customerName: z.string().min(1, { message: 'Customer name is required' }),
  capacity: z.string().min(1, { message: 'Capacity is required' }),
  copies: z.string().min(1, { message: 'Copies is required' }),
  classOfService: z
    .string()
    .min(1, { message: 'Class of service is required' }),
});
export type validationOtnCustomerSchemaType = z.infer<typeof otnCustomerSchema>;

export const sourceInfoSchema = z.object({
  sourceTrafficType: z
    .string()
    .min(1, { message: 'Source traffic type is required' }),
  ipAddress: z
    .string({
      required_error: 'IP address is required',
      invalid_type_error: 'IP address is required',
    })
    .ip({ message: 'Please provide a valid IP address' }),
  // ipAddress: z
  //   . string()
  //   .min(1, { message: 'IP address is required' })
  //   .regex(
  //     /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
  //     { message: 'Invalid IP address format' }
  //   ),
  selectPort: z.string().min(1, { message: 'Select port is required' }),
  timeSlot: z.string().min(1, { message: 'Time slot is required' }),
});

// Infer type from source info schema
export type validationSourceInfoSchemaType = z.infer<typeof sourceInfoSchema>;

export const destinationInfoSchema = z.object({
  destinationTrafficType: z
    .string()
    .min(1, { message: 'Source traffic type is required' }),
  ipAddress: z
    .string({
      required_error: 'IP address is required',
      invalid_type_error: 'IP address is required',
    })
    .ip({ message: 'Please provide a valid IP address' }),
  selectPort: z.string().min(1, { message: 'Select port is required' }),
  timeSlot: z.string().min(1, { message: 'Time slot is required' }),
});

// Infer type from source info schema
export type validationDestinationInfoSchemaType = z.infer<
  typeof destinationInfoSchema
>;

export const gmplsConstrainInfoSchema = z.object({
  servicePairTunnel: z
    .string()
    .min(1, { message: 'Service Pair Tunnel is required' }),

  excludeConstraints: z.boolean().refine((val) => val === true, {
    message: 'This field is required',
  }),

  ExcludeConstSelect: z.string().min(1, { message: 'This field is required' }),

  includeConstSelect: z
    .string()
    .min(1, { message: 'Include Constraints is required' }),

  nodeIpAddress: z
    .string({
      required_error: 'IP address is required',
      invalid_type_error: 'IP address is required',
    })
    .ip({ message: 'Please provide a valid IP address' }),

  selectPort: z
    .array(z.string())
    .min(1, { message: 'Select Port is required' }),

  includeNodeIpAddress: z
    .string({
      required_error: 'IP address is required',
      invalid_type_error: 'IP address is required',
    })
    .ip({ message: 'Please provide a valid IP address' }),

  includeSelectPort: z
    .array(z.string())
    .min(1, { message: 'Select Port is required' }),

  includeConstraints: z.boolean().refine((val) => val === true, {
    message: 'This field is required',
  }),

  addIncludeExcludeConstraints: z.boolean().refine((val) => val === true, {
    message: 'This field is required',
  }),

  workPath: z
    .string()
    .min(1, { message: 'Please select an option' })
    .nullable() // Allow null values, but enforce the string validation
    .refine((val) => val !== null, {
      message: 'Please select an option',
    }),

  protectPath: z
    .string()
    .min(1, { message: 'Please select an option' })
    .nullable() // Allow null values, but enforce the string validation
    .refine((val) => val !== null, {
      message: 'Please select an option',
    }),

  srlgDisjoint: z.boolean().refine((val) => val === true, {
    message: 'This field is required',
  }),
});

// Infer type from source info schema
export type validationGmplsConstrainInfoSchema = z.infer<
  typeof gmplsConstrainInfoSchema
>;

export const dwdmCustomerSchema = z.object({
  circuitName: z.string().min(1, { message: 'Circuit name is required' }),
  customerName: z.string().min(1, { message: 'Customer name is required' }),
  copies: z.string().min(1, { message: 'Copies is required' }),
  classOfService: z
    .string()
    .min(1, { message: 'Class of service is required' }),
  // autoPlanned: z.boolean(),
});
export type validationDwdmCustomerSchemaType = z.infer<
  typeof dwdmCustomerSchema
>;

export const dwdmSourceInfoSchema = z.object({
  ipAddress: z
    .string({
      required_error: 'IP address is required',
      invalid_type_error: 'IP address is required',
    })
    .ip({ message: 'Please provide a valid IP address' }),
  selectPort: z.string().min(1, { message: ' Port is required' }),
  frequency: z.string().min(1, { message: 'Frequency is required' }),
});

export type validationDwdmSourceInfoSchemaType = z.infer<
  typeof dwdmSourceInfoSchema
>;

// OTN - DWDM

export const dwdmDestinationInfoSchema = z.object({
  ipAddress: z
    .string({
      required_error: 'IP address is required',
      invalid_type_error: 'IP address is required',
    })
    .ip({ message: 'Please provide a valid IP address' }),
  selectPort: z.string().min(1, { message: 'Select port is required' }),
  frequency: z.string().min(1, { message: 'Frequency is required' }),
});

export type validationDwdmDestinationInfoSchemaType = z.infer<
  typeof dwdmDestinationInfoSchema
>;

export const dwdmConstrainInfoSchema = z.object({
  addIncludeExcludeConstraints: z.boolean().refine((val) => val === true, {
    message: 'This field is required',
  }),

  workPath: z
    .string()
    .min(1, { message: 'Please select an option' })
    .nullable()
    .refine((val) => val !== null, {
      message: 'Please select an option',
    }),

  protectPath: z
    .string()
    .min(1, { message: 'Please select an option' })
    .nullable()
    .refine((val) => val !== null, {
      message: 'Please select an option',
    }),

  srlgDisjoint: z.boolean().refine((val) => val === true, {
    message: 'This field is required',
  }),
});

export type validationDwdmConstrainInfoSchema = z.infer<
  typeof dwdmConstrainInfoSchema
>;

// otn constrains

export const otnConstrainInfoSchema = z.object({
  addIncludeExcludeConstraints: z.boolean().refine((val) => val === true, {
    message: 'This field is required',
  }),

  workPath: z
    .string()
    .min(1, { message: 'Please select an option' })
    .nullable()
    .refine((val) => val !== null, {
      message: 'Please select an option',
    }),

  protectPath: z
    .string()
    .min(1, { message: 'Please select an option' })
    .nullable()
    .refine((val) => val !== null, {
      message: 'Please select an option',
    }),

  srlgDisjoint: z.boolean().refine((val) => val === true, {
    message: 'This field is required',
  }),
});

export type validationOtnConstrainInfoSchema = z.infer<
  typeof otnConstrainInfoSchema
>;

// WSON

export const wsonConstrainInfoSchema = z.object({
  servicePairTunnel: z
    .string()
    .min(1, { message: 'Service Pair Tunnel is required' }),

  excludeConstraints: z.boolean().refine((val) => val === true, {
    message: 'This field is required',
  }),

  ExcludeConstSelect: z.string().min(1, { message: 'This field is required' }),

  includeConstSelect: z
    .string()
    .min(1, { message: 'Include Constraints is required' }),

  nodeIpAddress: z
    .string({
      required_error: 'IP address is required',
      invalid_type_error: 'IP address is required',
    })
    .ip({ message: 'Please provide a valid IP address' }),

  selectPort: z
    .array(z.string())
    .min(1, { message: 'Select Port is required' }),

  includeNodeIpAddress: z
    .string({
      required_error: 'IP address is required',
      invalid_type_error: 'IP address is required',
    })
    .ip({ message: 'Please provide a valid IP address' }),

  includeSelectPort: z
    .array(z.string())
    .min(1, { message: 'Select Port is required' }),

  includeConstraints: z.boolean().refine((val) => val === true, {
    message: 'This field is required',
  }),

  addIncludeExcludeConstraints: z.boolean().refine((val) => val === true, {
    message: 'This field is required',
  }),

  workPath: z
    .string()
    .min(1, { message: 'Please select an option' })
    .nullable() // Allow null values, but enforce the string validation
    .refine((val) => val !== null, {
      message: 'Please select an option',
    }),

  protectPath: z
    .string()
    .min(1, { message: 'Please select an option' })
    .nullable() // Allow null values, but enforce the string validation
    .refine((val) => val !== null, {
      message: 'Please select an option',
    }),

  srlgDisjoint: z.boolean().refine((val) => val === true, {
    message: 'This field is required',
  }),
});

export type validationWsonConstrainInfoSchema = z.infer<
  typeof wsonConstrainInfoSchema
>;

export const summaryConstrainInfoSchema = z.object({
  direction: z
    .string()
    .min(1, { message: 'Direction is required. Please select an option.' })
    .nullable()
    .refine((val) => val !== null, {
      message: 'Direction is required. Please select an option.',
    }),
  autoPlanned: z.boolean().refine((val) => val === true, {
    message: 'Auto Path must be selected.',
  }),
  disjointness: z.string().min(1, { message: 'Disjointness is required.' }),
  noOfPath: z.string().min(1, { message: 'Number of paths is required.' }),
});

// Infer type from source info schema
export type validationSummaryConstrainInfoSchema = z.infer<
  typeof summaryConstrainInfoSchema
>;

// otn-gmpls summary

export const gmplsSummaryConstrainInfoSchema = z.object({
  tlBandwidth: z.boolean().refine((val) => val === true, {
    message: 'TL Bandwidth Calculation must be selected.',
  }),
  direction: z
    .string()
    .min(1, { message: 'Direction is required. Please select an option.' })
    .nullable()
    .refine((val) => val !== null, {
      message: 'Direction is required. Please select an option.',
    }),
  autoPlanned: z.boolean().refine((val) => val === true, {
    message: 'Auto Path must be selected.',
  }),
  disjointness: z.string().min(1, { message: 'Disjointness is required.' }),
  noOfPath: z.string().min(1, { message: 'Number of paths is required.' }),
});

// Infer type from source info schema
export type validationGmplsSummaryConstrainInfoSchema = z.infer<
  typeof gmplsSummaryConstrainInfoSchema
>;

// DWDM summary

export const dwdmSummaryConstrainInfoSchema = z.object({
  tlBandwidth: z.boolean().refine((val) => val === true, {
    message: 'TL Bandwidth Calculation must be selected.',
  }),
  direction: z
    .string()
    .min(1, { message: 'Direction is required. Please select an option.' })
    .nullable()
    .refine((val) => val !== null, {
      message: 'Direction is required. Please select an option.',
    }),
  autoPlanned: z.boolean().refine((val) => val === true, {
    message: 'Auto Path must be selected.',
  }),
  disjointness: z.string().min(1, { message: 'Disjointness is required.' }),
  noOfPath: z.string().min(1, { message: 'Number of paths is required.' }),
});

export type validationDwdmSummaryConstrainInfoSchema = z.infer<
  typeof dwdmSummaryConstrainInfoSchema
>;

// DWDM summary

export const otnSummaryConstrainInfoSchema = z.object({
  tlBandwidth: z.boolean().refine((val) => val === true, {
    message: 'TL Bandwidth Calculation must be selected.',
  }),
  direction: z
    .string()
    .min(1, { message: 'Direction is required. Please select an option.' })
    .nullable()
    .refine((val) => val !== null, {
      message: 'Direction is required. Please select an option.',
    }),
  autoPlanned: z.boolean().refine((val) => val === true, {
    message: 'Auto Path must be selected.',
  }),
  disjointness: z.string().min(1, { message: 'Disjointness is required.' }),
  noOfPath: z.string().min(1, { message: 'Number of paths is required.' }),
});

export type validationOtnSummaryConstrainInfoSchema = z.infer<
  typeof otnSummaryConstrainInfoSchema
>;

// sdh constraints

export const SdhConstrainInfoSchema = z.object({
  addIncludeExcludeConstraints: z.boolean().refine((val) => val === true, {
    message: 'This field is required',
  }),

  workPath: z
    .string()
    .min(1, { message: 'Please select an option' })
    .nullable()
    .refine((val) => val !== null, {
      message: 'Please select an option',
    }),

  protectPath: z
    .string()
    .min(1, { message: 'Please select an option' })
    .nullable()
    .refine((val) => val !== null, {
      message: 'Please select an option',
    }),

  srlgDisjoint: z.boolean().refine((val) => val === true, {
    message: 'This field is required',
  }),
});

export type validationSdhConstrainInfoSchema = z.infer<
  typeof SdhConstrainInfoSchema
>;

// SDH summary

export const sdhSummarySchema = z.object({
  tlBandwidth: z.boolean().optional(),
  direction: z
    .string()
    .optional()
    .nullable()
    .refine((val) => val === null || val === undefined || val.length > 0, {
      message: 'Direction is required. Please select an option.',
    }),
  autoPlanned: z.boolean().optional(),
  disjointness: z.string().min(1, { message: 'Disjointness is required.' }),
  noOfPath: z.string().min(1, { message: 'Number of paths is required.' }),
});

export type validationSdhSummarySchema = z.infer<typeof sdhSummarySchema>;

// MEP OTN summary

export const mepOtnSummarySchema = z.object({
  tlBandwidth: z.boolean().optional(),
  direction: z
    .string()
    .optional()
    .nullable()
    .refine((val) => val === null || val === undefined || val.length > 0, {
      message: 'Direction is required. Please select an option.',
    }),
  autoPlanned: z.boolean().optional(),
  disjointness: z.string().min(1, { message: 'Disjointness is required.' }),
  noOfPath: z.string().min(1, { message: 'Number of paths is required.' }),
});

export type validationMepOtnSummarySchema = z.infer<typeof mepOtnSummarySchema>;

export const wsonCustomerSchema = z.object({
  circuitName: z.string().min(1, { message: 'Circuit name is required' }),
  customerName: z.string().min(1, { message: 'Customer name is required' }),
  copies: z.string().min(1, { message: 'Copies is required' }),
  classOfService: z
    .string()
    .min(1, { message: 'Class of service is required' }),
  autoPlanned: z.boolean(),
});
export type validationWsonCustomerSchemaType = z.infer<
  typeof wsonCustomerSchema
>;

export const mepCustomerSchema = z.object({
  circuitName: z.string().min(1, { message: 'Circuit name is required' }),
  customerName: z.string().min(1, { message: 'Customer name is required' }),
  capacity: z.string().min(1, { message: 'Capacity is required' }),
  copies: z.string().min(1, { message: 'Copies is required' }),
  classOfService: z
    .string()
    .min(1, { message: 'Class of service is required' }),
});
export type validationMepCustomerSchemaType = z.infer<typeof mepCustomerSchema>;
