import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { Box, Button, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';


import CustomTextField from '../../../components/CustomTextField/CustomTextField';
import TimeSlotModal from '../../../components/modal/TimeSlotModal';
import SearchAutoComplete from '../../../components/SearchAutoComplete/SearchAutoComplete';

import {
  sourceInfoSchema,
  validationSourceInfoSchemaType,
} from '../../../schema/schema';
import CustomSelect from '../../../components/CustomSelect/CustomSelect';
import { commonBtn, submitBtn } from '../../../themes/summaryStyle';
import { useStepContext } from '../../../contexts/StepProviderContext';

interface OtnGmplsSourceInfoProps {
  onNext: () => void;
}

const OtnGmplsSouceInfo: React.FC<OtnGmplsSourceInfoProps> = ({ onNext }) => {
  // const [formData, setFormData] = useState({
  //   sourceTrafficType: '',
  //   ipAddress: '',
  //   selectPort: '',
  //   timeSlot: '',
  // });
  const ipAddresses = [
    '192.168.0.1',
    '10.0.0.1',
    '172.16.0.1',
    '192.168.1.1',
    '203.0.113.1',
    '8.8.8.8',
    '192.168.100.1',
    '192.0.2.1',
    '198.51.100.1',
    '255.255.255.255',
  ];
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<validationSourceInfoSchemaType>({
    resolver: zodResolver(sourceInfoSchema),
  });
  const { formNumber, setFormNumber } = useStepContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const handleChange = (e: any) => {
  //   const { name, value } = e.target;
  //   setFormData((prevData: any) => ({
  //     ...prevData,
  //     [name]: value,
  //   }));
  // };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const onSubmit = (data: validationSourceInfoSchemaType) => {
    setFormNumber(2);
    onNext();
  };

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box mt={4}>
          <CustomTextField
            label={
              <>
                Source Traffic Type<span style={{ color: 'red' }}>*</span>
              </>
            }
            {...register('sourceTrafficType')}
          />
          {errors.sourceTrafficType && (
            <Typography
              mt="4px"
              ml={2}
              sx={{ color: '#F90000' }}
              className="poppins-error-font"
            >
              {errors.sourceTrafficType.message}
            </Typography>
          )}
        </Box>
        <Box mt={3}>
          <Controller
            name="ipAddress"
            control={control}
            render={({ field }) => (
              <SearchAutoComplete
                ipAddresses={ipAddresses}
                value={field.value?.toString() || ''}
                onChange={(event: any, value: any) => {
                  field.onChange(value);
                }}
                label={
                  <>
                    Node/Ip Address<span style={{ color: 'red' }}>*</span>
                  </>
                }
              />
            )}
          />
          {errors.ipAddress && (
            <Typography
              mt="4px"
              ml={2}
              sx={{ color: '#F90000' }}
              className="poppins-error-font"
            >
              {errors.ipAddress.message}
            </Typography>
          )}
        </Box>

        <Box mt={3}>
          <CustomSelect
            label={
              <>
                Select Port <span style={{ color: 'red' }}>*</span>
              </>
            }
            options={[
              { value: 'common-1-3-1', label: 'common-1-3-1' },
              { value: 'ocommon-1-3-2', label: 'common-1-3-2' },
            ]}
            {...register('selectPort')}
          />
          {errors.selectPort && (
            <Typography
              className="poppins-error-font"
              mt="4px"
              ml={2}
              sx={{ color: '#F90000' }}
            >
              {errors.selectPort.message}
            </Typography>
          )}
        </Box>
        <Box sx={{ display: 'flex', gap: 2 }} mt={3}>
          <Box>
            <CustomTextField
              label={
                <>
                  Select Slot<span style={{ color: 'red' }}>*</span>
                </>
              }
              {...register('timeSlot')}
            />
            {errors.timeSlot && (
              <Typography
                mt="4px"
                ml={2}
                sx={{ color: '#F90000' }}
                className="poppins-error-font"
              >
                {errors.timeSlot.message}
              </Typography>
            )}
          </Box>
          <Button
            className="poppins-font"
            variant="outlined"
            sx={{
              ...commonBtn,
              maxHeight: '49px',
            }}
            onClick={handleOpenModal}
          >
            +Time Slot
          </Button>
        </Box>
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }} mt={3}>
          <Typography
            sx={{
              color: '#000',
              fontFeatureSettings: "'clig' off, 'liga' off",
              fontFamily: 'Poppins',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: 'normal',
            }}
          >
            Add Source client port protection
          </Typography>

          <Button
            className="poppins-font"
            variant="outlined"
            sx={{
              ...commonBtn,
              minWidth: 180,
              minHeight: 55,
            }}
            startIcon={<AddIcon />}

          >
            Add new
          </Button>
        </Box>
        <Box mt={3}>
          <Button
            type="submit"
            className="poppins-font"
            sx={submitBtn}
            disabled={formNumber<1 }
          >
            Proceed
          </Button>
        </Box>
        <TimeSlotModal open={isModalOpen} handleClose={handleCloseModal} />
      </form>
    </Box>
  );
};

export default OtnGmplsSouceInfo;
