import React from "react";

import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  ThemeProvider,
  FormHelperText,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import customTheme from "../../themes/customTheme";
import { poppinsFormText } from "../../themes/summaryStyle";

interface CustomSelectProps {
  label: any;
  options: { value: string; label: string }[];
  onChange: any;
  value?: string;
  required?: boolean;
  name: string;
  width?: { lg?: string; md: string; sm: string; xs: string }; // Add width property
  error?: boolean; // Add error prop
  helperText?: any;
  borderRadius?: string;
}

const CustomSelect = React.forwardRef((props: CustomSelectProps, ref: any) => {
  const {
    label,
    options,
    onChange,
    // value,
    required,
    name,
    width,
    borderRadius,
    error,
    helperText,
  } = props;

  return (
    <ThemeProvider theme={customTheme}>
      <FormControl
        {...props}
         sx={{
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
              borderColor: "#0000FF",
              borderWidth: "2px",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#00F", // Change border to red on hover
              backgroundColor: "rgba(220, 226, 255, 0.5)", // Semi-transparent background
            },
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: "2px solid #D9E1E7",
            borderRadius: "10px",
          },
          "& label.Mui-focused": {
            ...poppinsFormText,
          },
        }}

        variant="outlined"
        error={error}
        required={required}
      >
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select
          {...props}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          // value={value}
          onChange={onChange}
          label={label}
          ref={ref}
          sx={{
            height: "49px",
            borderRadius: borderRadius ? borderRadius : 3,
            width: width || { md: "496px", sm: "200px", xs: "150px" },
          }}
          name={name}
          MenuProps={{
            disableScrollLock: true,
          }}
          IconComponent={KeyboardArrowDownIcon}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
          {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </Select>
      </FormControl>
    </ThemeProvider>
  );
});

export default CustomSelect;
