import React, { useState } from 'react';

import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Radio from '@mui/material/Radio';

import CustomButton from '../../../components/button/CustomButton';
import { checkboxStyle } from '../../../themes/summaryStyle';

interface OtnAdvSettingProps {
  handleClose: () => void;
  setSelectOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface FormValues {
  networkMonitoring: string;
  yCableProtectionType: string;
  autoPlanned: boolean;
  singleFiberMode: boolean;
  regeneratorPortPairing: boolean;
  cst: boolean;
  timeSlotSelection: string;
  direction: string;
}

const OtnAdvSettings: React.FC<OtnAdvSettingProps> = ({
  handleClose,
  setSelectOpen,
}) => {
  const poppinsFormText = {
    color: '#06152b',
    fontFamily: 'Poppins, sans-serif',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
  };

  const [formValues, setFormValues] = useState<FormValues>({
    networkMonitoring: 'Inherent',
    yCableProtectionType: 'Client',
    autoPlanned: false,
    singleFiberMode: false,
    regeneratorPortPairing: false,
    cst: false,
    timeSlotSelection: 'Manual',
    direction: 'Uni-Direction',
  });

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setFormValues({
      ...formValues,
      [name]: checked,
    });
  };

  return (
    <Box sx={{ padding: '1rem 2rem 3rem 3rem' }}>
      <Grid container display='flex' alignItems='center' spacing={3}>
        <Grid item xs={4}>
          <Typography sx={poppinsFormText}>Network Monitoring</Typography>
        </Grid>
        <Grid item xs={8} display='flex' alignItems='center' gap='1rem'>
          <FormControl
            sx={{
              width: '100%',
              maxWidth: '127px',
              height: '36px',
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                  borderColor: '#0000FF',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#00F',
                  backgroundColor: 'rgba(220, 226, 255, 0.5)',
                },
              },
              '& .MuiOutlinedInput-notchedOutline': {
                border: '2px solid #D9E1E7',
              },
            }}
          >
            <Select
              labelId='networkMonitoring-select-label'
              id='networkMonitoring-select'
              name='networkMonitoring'
              value={formValues.networkMonitoring}
              onChange={handleSelectChange}
              IconComponent={KeyboardArrowDownIcon}
              inputProps={{ 'aria-label': 'Without label' }}
              sx={{
                width: '100%',
                maxWidth: '127px',
                height: '36px',
                '& .MuiSelect-select': {
                  color: formValues.networkMonitoring ? '#707070' : 'inherit',
                },
              }}
              onOpen={() => setSelectOpen(true)}
              MenuProps={{
                disableScrollLock: true,
                TransitionProps: {
                  onExited: () => setSelectOpen(false),
                },
              }}
            >
              <MenuItem value='Inherent'>Inherent</MenuItem>
              <MenuItem value='NonInherent'>Non-Inherent</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={4}>
          <Typography sx={poppinsFormText}>Y Cable Protection type</Typography>
        </Grid>
        <Grid item xs={8} display='flex' alignItems='center' gap='1rem'>
          <FormControl
            sx={{
              width: '100%',
              maxWidth: '127px',
              height: '36px',
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                  borderColor: '#0000FF',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#00F',
                  backgroundColor: 'rgba(220, 226, 255, 0.5)',
                },
              },
              '& .MuiOutlinedInput-notchedOutline': {
                border: '2px solid #D9E1E7',
              },
            }}
          >
            <Select
              labelId='yCableProtectionType-select-label'
              id='yCableProtectionType-select'
              name='yCableProtectionType'
              value={formValues.yCableProtectionType}
              onChange={handleSelectChange}
              IconComponent={KeyboardArrowDownIcon}
              inputProps={{ 'aria-label': 'Without label' }}
              sx={{
                width: '100%',
                maxWidth: '127px',
                height: '36px',
                '& .MuiSelect-select': {
                  color: formValues.yCableProtectionType
                    ? '#707070'
                    : 'inherit',
                },
              }}
              onOpen={() => setSelectOpen(true)}
              MenuProps={{
                disableScrollLock: true,
                TransitionProps: {
                  onExited: () => setSelectOpen(false),
                },
              }}
            >
              <MenuItem value='Client'>Client</MenuItem>
              <MenuItem value='Network'>Network</MenuItem>
              <MenuItem value='Both'>Both</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6} md={12}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  name='singleFiberMode'
                  checked={formValues.singleFiberMode}
                  onChange={handleCheckboxChange}
                  sx={checkboxStyle}
                />
              }
              label='Single Fiber mode'
              sx={{ '& .MuiFormControlLabel-label': poppinsFormText }}
            />
          </FormGroup>
        </Grid>

        <Grid item xs={6} md={12} sx={{ marginTop: '-1rem' }}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  name='regeneratorPortPairing'
                  checked={formValues.regeneratorPortPairing}
                  onChange={handleCheckboxChange}
                  sx={checkboxStyle}
                />
              }
              label='Regenerator Port Pairing'
              sx={{ '& .MuiFormControlLabel-label': poppinsFormText }}
            />
          </FormGroup>
        </Grid>

        <Grid item xs={6} md={12} sx={{ marginTop: '-1rem' }}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  name='cst'
                  checked={formValues.cst}
                  onChange={handleCheckboxChange}
                  sx={checkboxStyle}
                />
              }
              label='CST'
              sx={{ '& .MuiFormControlLabel-label': poppinsFormText }}
            />
          </FormGroup>
        </Grid>

        <Grid item xs={4}>
          <Typography sx={{ ...poppinsFormText, fontWeight: 600 }}>
            Time slot selection
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <FormControl>
            <RadioGroup
              aria-labelledby='time-slot-selection-group'
              name='timeSlotSelection'
              value={formValues.timeSlotSelection}
              onChange={handleInputChange}
              row
              sx={{ gap: 6 }}
            >
              <FormControlLabel
                value='Manual'
                control={
                  <Radio
                    sx={{
                      color: 'lightgray',
                      '&.Mui-checked': {
                        color: 'white',
                      },
                      '&.Mui-checked .MuiSvgIcon-root': {
                        backgroundColor: 'blue',
                        borderRadius: '50%',
                      },
                      '& .MuiSvgIcon-root': {
                        fontSize: 28,
                      },
                    }}
                  />
                }
                label='Manual'
              />
              <FormControlLabel
                value='Auto'
                control={
                  <Radio
                    sx={{
                      color: 'lightgray',
                      '&.Mui-checked': {
                        color: 'white',
                      },
                      '&.Mui-checked .MuiSvgIcon-root': {
                        backgroundColor: 'blue',
                        borderRadius: '50%',
                      },
                      '& .MuiSvgIcon-root': {
                        fontSize: 28,
                      },
                    }}
                  />
                }
                label='Auto'
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item xs={4}>
          <Typography sx={{ ...poppinsFormText, fontWeight: 600 }}>
            Direction
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <FormControl>
            <RadioGroup
              aria-labelledby='direction-group'
              name='direction'
              value={formValues.direction}
              onChange={handleInputChange}
              row
              sx={{ gap: 1 }}
            >
              <FormControlLabel
                value='Uni-Direction'
                control={
                  <Radio
                    sx={{
                      color: 'lightgray',
                      '&.Mui-checked': {
                        color: 'white',
                      },
                      '&.Mui-checked .MuiSvgIcon-root': {
                        backgroundColor: 'blue',
                        borderRadius: '50%',
                      },
                      '& .MuiSvgIcon-root': {
                        fontSize: 28,
                      },
                    }}
                  />
                }
                label='Uni-Direction'
              />
              <FormControlLabel
                value='Bi-Direction'
                control={
                  <Radio
                    sx={{
                      color: 'lightgray',
                      '&.Mui-checked': {
                        color: 'white',
                      },
                      '&.Mui-checked .MuiSvgIcon-root': {
                        backgroundColor: 'blue',
                        borderRadius: '50%',
                      },
                      '& .MuiSvgIcon-root': {
                        fontSize: 28,
                      },
                    }}
                  />
                }
                label='Bi-Direction'
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12} display='flex' gap='1rem'>
          <CustomButton
            variantType='primary'
            size='small'
            padding='1rem 2.5rem'
            width='179px'
            onClick={handleClose}
          >
            Submit
          </CustomButton>
          <CustomButton
            variantType='secondary'
            size='small'
            padding='1rem 2.5rem'
            width='179px'
            onClick={handleClose}
          >
            Cancel
          </CustomButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default OtnAdvSettings;
