import React from 'react';

import CommonAccordion from '../../components/accordion/CommonAccordion';
import OtnBasicCircuit from '../../forms/mep/Otn/OtnBasicCicuit';
import OtnSouceInfo from '../../forms/mep/Otn/OtnSouceInfo';
import OtnDestinationInfo from '../../forms/mep/Otn/OtnDestinationInfo';
import OtnConstraints from '../../forms/mep/Otn/OtnConstraints';

const MEPOTN: React.FC = () => {
  const accordions = [
    {
      title: 'Circuit Primitive Information',
      description:
        'Please enter a few details below Listed Mandatory Fields, Need More Please filter From advance',
      content: <OtnBasicCircuit onNext={() => {}} />, // Pass a dummy onNext function for now
    },
    {
      title: 'Source Information',
      description: 'Please enter a few details below Listed Mandatory Fields.',
      content: <OtnSouceInfo onNext={() => {}} />, // Pass a dummy onNext function for now
    },
    {
      title: 'Destination Information',
      description: 'Please enter a few details below Listed Mandatory Fields.',
      content: <OtnDestinationInfo onNext={() => {}} />, // Pass a dummy onNext function for now
    },
    {
      title: 'Constraints & Route Creations',
      description: 'Please enter a few details below Listed Mandatory Fields.',
      content: <OtnConstraints onNext={() => {}} />, // Pass a dummy onNext function for now
    },
  ];
  return (
    <div>
      <CommonAccordion accordions={accordions} />
    </div>
  );
};

export default MEPOTN;
