import { Outlet } from 'react-router-dom';

import { Box } from '@mui/material';

import VerticalLinearStepper from '../components/VerticalLinearStepper/VerticalLinearStepper';
import Navbar from '../components/Navbar/Navbar';

import { StepProvider } from '../contexts/StepProviderContext';

const Layout: React.FC = () => {
  return (
    <StepProvider>
      <Box>
        <Box
          sx={{
            position: 'relative',
          }}
        >
          <Box
            sx={{
              position: 'fixed',
              zIndex: 2,
              width: '100%',
            }}
          >
            <Navbar />
          </Box>
          <Box sx={{ margin: 'auto', maxWidth: '1600px' }}>
            <Box
              display='flex'
              sx={{
                paddingTop: '3rem',
                paddingLeft: { xs: '20px', lg: '40px' },
              }}
            >
              <Box
                sx={{
                  width: { lg: '320px', md: '284px', sm: '322px' },
                  padding: '40px 0 20px 20px',
                  position: 'fixed',
                  top: '3.8rem', // Adjust this value to match the height of the Navbar
                  height: 'calc(100vh - 4rem)', // Adjust this value to match the height of the Navbar
                  overflowY: 'auto',
                  '&::-webkit-scrollbar': {
                    width: '0px',
                  },
                  '&::-webkit-scrollbar-track': {
                    background: '#f1f1f1',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    borderRadius: '10px',
                    border: '2px solid #f1f1f1',
                  },
                }}
              >
                <VerticalLinearStepper />
              </Box>
              <Box
                sx={{
                  marginTop: '3.4rem',
                  marginLeft: { lg: '310px', md: '274px', sm: '252px' },
                  padding: '0px 24px',
                  height: 'calc(100vh - 6.1rem)',
                  overflowY: 'scroll',
                  '&::-webkit-scrollbar': {
                    width: '0px',
                  },
                }}
              >
                <Outlet />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </StepProvider>
  );
};

export default Layout;
