import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Typography,
  StepConnector,
} from '@mui/material';

import { useNavigate } from 'react-router-dom';

import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

import blueBg from '../../assets/svg/blue-bg.svg';
import CheckCircleIcon from '../../assets/svg/CheckCircleIcon.svg';

import { useStepContext } from '../../contexts/StepProviderContext';
import { useCircuitContext } from '../../contexts/CircuitContext';

const steps = [
  {
    label: 'Circuit Primitive Information',
    description: ``,
  },
  {
    label: 'Source Information',
    description: '',
  },
  {
    label: 'Destination Information',
    description: ``,
  },
  {
    label: 'Constraints',
    description: '',
  },
  {
    label: 'Summary',
    description: '',
  },
];

function StepIconComponent(props: any) {
  const { formNumber } = useStepContext();
  const { completed, icon } = props;

  const isActive = icon === formNumber + 1; // Check if the step is active

  return completed ? (
    <Box
      component='img'
      src={CheckCircleIcon}
      alt='circle checked'
      sx={{ color: 'white', width: 40, height: 40, padding: '5px' }}
    />
  ) : (
    <RadioButtonUncheckedIcon
      sx={{
        color: 'white',
        width: 35,
        height: 35,
        stroke: isActive ? 'white' : 'transparent', // Apply stroke for active step
        strokeWidth: isActive ? 1.5 : 0, // Apply strokeWidth for active step
      }}
    />
  );
}

const CustomConnector = (props: any) => (
  <StepConnector
    {...props}
    sx={{
      '& .MuiStepConnector-line': {
        borderWidth: 2,
        ml: '2px',
        mb: '4px',
        minHeight: '15px',
      },
    }}
  />
);

export default function VerticalLinearStepper() {
  const { formNumber, activeStep, setClickedStep } = useStepContext();
  const { selectedValue, subTypeSelect } = useCircuitContext();
  const navigate = useNavigate();
  const handleStepClick = (index: number) => {
    setClickedStep(index);

    if (activeStep === 4 && index === 5) {
      navigate(`${selectedValue}/${subTypeSelect}/summary`);
    } else {
      navigate(`${selectedValue}/${subTypeSelect}`);
    }
  };

  return (
    <Box
      sx={{
        maxWidth: 300,
        background: `url(${blueBg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        paddingInline: 4,
        paddingBlock: 6,
        borderRadius: 4,
        width: { lg: '290px', md: '254px', sm: '232px' },
      }}
    >
      <Stepper
        activeStep={activeStep}
        orientation='vertical'
        connector={<CustomConnector />}
        sx={{
          '& .MuiStepLabel-iconContainer': {
            display: 'flex',
            alignItems: 'center',
          },
        }}
      >
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              StepIconComponent={StepIconComponent}
              onClick={() => handleStepClick(index + 1)}
              color='white'
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'baseline',
                cursor: 'pointer',
                '& .MuiStepLabel-iconContainer': {
                  display: 'flex',
                },
              }}
            >
              <Typography
                className='poppins-error-font'
                sx={{
                  color: 'white',
                  cursor: 'pointer',
                  ml: 1,
                  mt: 1,
                  minWidth: { md: '230px', sm: '100px' },
                }}
              >
                {step.label}
              </Typography>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
