import React from 'react';

import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import { ThemeProvider } from '@mui/material';

import customTheme from '../../themes/customTheme';
import { poppinsFormText } from '../../themes/summaryStyle';

interface SearchAutoCompleteProps {
  ipAddresses: string[];
  value: string;
  onChange: any;
  label?: any;
}

const SearchAutoComplete = React.forwardRef(
  (props: SearchAutoCompleteProps, ref: any) => {
    const { ipAddresses, value, onChange, label } = props;

    return (
      <ThemeProvider theme={customTheme}>
        <Stack>
          <Autocomplete
            ref={ref}
            value={value}
            onChange={onChange}
            id="free-solo-demo"
            freeSolo
            options={ipAddresses}
            //   onChange={onChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label={
                  //   <Typography className="poppins-error-font">
                  label
                  //   </Typography>
                }
                ref={ref}
                variant="outlined"
              />
            )}
            sx={{
              width: { md: '496px', sm: '200px', xs: '150px' },
              height: '49px',
              '& label.Mui-focused': {
                ...poppinsFormText,
              },
              '& .MuiInputLabel-root': {
                ...poppinsFormText,
              },
              '& .MuiOutlinedInput-root': {
                height: '49px',
                '& fieldset': {
                  borderColor: '#D9E1E7', // Default border color
                },
                '&:hover fieldset': {
                  borderColor: '#00F', // Border color on hover
                },
                '&:hover': {
                  backgroundColor: 'rgba(220, 226, 255, 0.5)', // Semi-transparent background
                },
                '& input': {
                  color: '#000', // Ensure font color is black
                },
                '& .MuiFormHelperText-root': {
                  color: '#000', // Ensure helper text color is black
                },
              },
            }}
          />
        </Stack>
      </ThemeProvider>
    );
  }
);

export default SearchAutoComplete;
