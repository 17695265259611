import React from "react";

import {
  Box,
  MenuItem,
  FormControl,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import {
  Done as DoneIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from "@mui/icons-material";

interface CommonDropdownProps {
  label: string;
  options: Array<{ value: string; label: string }>;
  selectedValue: string;
  onChange: (event: SelectChangeEvent) => void;
  width?: string;
}

const CommonDropdown: React.FC<CommonDropdownProps> = ({
  options,
  selectedValue,
  onChange,
  width = "139px",
}) => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl sx={{ m: 1, minWidth: 120 }}>
        <Select
          value={selectedValue}
          onChange={onChange}
          onOpen={handleOpen}
          onClose={handleClose}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          IconComponent={KeyboardArrowDownIcon}
          sx={{
            width: { xs: "120px", md: width },
            color: "#707070",
            border: "1px solid #D9E1E7",
            height: "36px",
            borderRadius: "5px",
            bgcolor: "#FFFFFF",
            padding: "14px 5px",
            fontSize: { xs: "12px", md: "14px" },
          }}
          MenuProps={{
            disableScrollLock: true,
            disablePortal: true,
            PaperProps: {
              sx: {
                boxShadow: "0px 13px 18px 0px rgba(0, 0, 0, 0.25)",
                padding: 0,
              },
            },
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          }}
        >
          {options.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              sx={{
                fontSize: { xs: "12px", md: "14px" },
                color: "#707070",
                display: "flex",
                alignItems: "center",
                width: { xs: "140px", md: "172px" },
                padding:
                  open && option.value !== selectedValue
                    ? "5px 5px 5px 30px"
                    : "5px",
                backgroundColor:
                  option.value === selectedValue ? "#DFDFFF" : "transparent",
              }}
            >
              <span
                style={{ flexGrow: 2, display: "flex", alignItems: "center" }}
              >
                {open && option.value === selectedValue && (
                  <DoneIcon
                    sx={{
                      fontSize: "14px",
                      marginRight: "8px",
                      color: "#707070",
                    }}
                  />
                )}
                {option.label}
              </span>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default CommonDropdown;
