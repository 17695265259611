import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import { Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import CustomTextField from '../CustomTextField/CustomTextField';
import CustomSelect from '../CustomSelect/CustomSelect';
import { useState } from 'react';
import PhoneNumberInput from '../PhoneNumberInput/PhoneNumberInput';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid blue',
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

interface NewCustomerModalProps {
  open: boolean;
  handleClose: () => void;
  handleChangeForCustomer: any;
  customerData: any;
}

const NewCustomerModal: React.FC<NewCustomerModalProps> = ({
  open,
  handleClose,
  customerData,
  handleChangeForCustomer,
}) => {
  const [countryCode, setCountryCode] = useState('+91');
  const [phoneNumber, setPhoneNumber] = useState('');
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography>Create Customer</Typography>
          <CloseIcon onClick={handleClose} sx={{ cursor: 'pointer' }} />
        </Box>
        <Box sx={{ display: 'flex', columnGap: 2, mt: 3 }}>
          <CustomTextField
            label="Customer Name"
            required
            value={customerData.customerName}
            onChange={handleChangeForCustomer}
            width={{ md: '412px', sm: '200px', xs: '150px' }}
            name="customerName"
          />
          <CustomTextField
            label="Email"
            required
            value={customerData.email}
            onChange={handleChangeForCustomer}
            width={{ md: '412px', sm: '200px', xs: '150px' }}
            name="email"
          />
        </Box>
        <Box sx={{ display: 'flex', columnGap: 2, mt: 3 }}>
          <CustomTextField
            label="Contact Person"
            required
            value={customerData.contactPerson}
            onChange={handleChangeForCustomer}
            width={{ md: '412px', sm: '200px', xs: '150px' }}
            name="contactPerson"
          />
          {/* <CustomTextField
            label="Phone Number"
            required
            value={customerData.email}
            onChange={handleChangeForCustomer}
            width={{ md: '412px', sm: '200px', xs: '150px' }}
            name="phoneNumber"
          /> */}
          <div>
            <PhoneNumberInput
              countryCode={countryCode}
              setCountryCode={setCountryCode}
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
            />
          </div>
        </Box>
        <Box mt={3}>
          <CustomTextField
            label="Address"
            required
            value={customerData.email}
            onChange={handleChangeForCustomer}
            width={{ md: '840px', sm: '415px', xs: '315px' }}
            name="address"
          />
        </Box>
        <Box sx={{ display: 'flex', columnGap: 2, mt: 3 }}>
          <CustomSelect
            label="City"
            options={[
              { value: 'option1', label: 'Option 1' },
              { value: 'option2', label: 'Option 2' },
            ]}
            onChange={handleChangeForCustomer}
            value={customerData.city}
            name="city"
            width={{ md: '412px', sm: '200px', xs: '150px' }}
          />
          <CustomSelect
            label="Country"
            options={[
              { value: 'option1', label: 'Option 1' },
              { value: 'option2', label: 'Option 2' },
            ]}
            width={{ md: '412px', sm: '200px', xs: '150px' }}
            onChange={handleChangeForCustomer}
            value={customerData.country}
            name="country"
          />
        </Box>
        <Box sx={{ mt: 3 }}>
          <CustomSelect
            label="Fax"
            options={[
              { value: 'option1', label: 'Option 1' },
              { value: 'option2', label: 'Option 2' },
            ]}
            onChange={handleChangeForCustomer}
            value={customerData.city}
            name="fax"
            width={{ md: '412px', sm: '200px', xs: '150px' }}
          />
        </Box>
        <Box sx={{ display: 'flex', columnGap: 2, mt: 3 }}>
          <Button
            className="poppins-font"
            onClick={handleClose}
            sx={{
              backgroundColor: '#D9E1E7',
              '&:hover': {
                backgroundColor: '#D9E1E7', // Same background color on hover
              },
              color: 'black',
              minWidth: 180,
              minHeight: 60,
              borderRadius: 2,
              textTransform: 'none', // To prevent text from being converted to uppercase
            }}
          >
            Cancel
          </Button>
          <Button
            className="poppins-font"
            sx={{
              backgroundColor: "#00F",
              "&:hover": {
                backgroundColor: "#0000BA",
                borderColor:"#0000BA"
              },
              color: 'white',
              minWidth: 180,
              minHeight: 60,
              borderRadius: 2,
              textTransform: 'none', // To prevent text from being converted to uppercase
            }}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default NewCustomerModal;
