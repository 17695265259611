import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import {
  Box,
  Button,
  Popper,
  Typography,
  Divider,
  ClickAwayListener,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { fetchPostsStart } from '../../../store/slice/postsSlice';
import { AppDispatch, RootState } from '../../../store/store';

import CustomTextField from '../../../components/CustomTextField/CustomTextField';
import CustomSelect from '../../../components/CustomSelect/CustomSelect';

import NewCustomerModal from '../../../components/modal/NewCustomerModal';
import {
  otnCustomerSchema,
  validationOtnCustomerSchemaType,
} from '../../../schema/schema';

import Filter from '../../../assets/svg/Filter.svg';
import {
  btnStyle,
  commonBtn,
  poppinsFormText,
} from '../../../themes/summaryStyle';
import OtnAdvSettings from './OtnAdvSettings';
import { useStepContext } from '../../../contexts/StepProviderContext';

interface OtnGmplsBasicCircuitProps {
  onNext: () => void;
}

const OtnBasicCircuit: React.FC<OtnGmplsBasicCircuitProps> = ({ onNext }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { posts, loading, error } = useSelector(
    (state: RootState) => state.posts
  );
  console.log('posts', posts, loading, error);

  useEffect(() => {
    dispatch(fetchPostsStart());
  }, [dispatch]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { setFormNumber } = useStepContext();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<validationOtnCustomerSchemaType>({
    resolver: zodResolver(otnCustomerSchema),
  });

  const [selectOpen, setSelectOpen] = useState(false);
  const [customerData, setCustomerData] = useState({
    customerName: '',
    email: '',
    contactPerson: '',
    PhoneNumber: '',
    address: '',
    city: '',
    country: '',
    fax: '',
  });

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleChangeForCustomer = (e: any) => {
    const { name, value } = e.target;
    setCustomerData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSubmit = (data: validationOtnCustomerSchemaType) => {
    setFormNumber(1);
    onNext();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  return (
    <Box mt={'10px'}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CustomTextField
          label={
            <>
              Circuit Name <span style={{ color: 'red' }}>*</span>
            </>
          }
          {...register('circuitName')}
        />
        {errors.circuitName && (
          <Typography
            mt='4px'
            ml={2}
            sx={{ color: '#F90000' }}
            className='poppins-error-font'
          >
            {errors.circuitName.message}
          </Typography>
        )}

        <Box sx={{ display: 'flex', gap: 2 }} mt={3}>
          <Box>
            <CustomSelect
              label={
                <>
                  Customer Name <span style={{ color: 'red' }}>*</span>
                </>
              }
              options={[
                { value: 'option1', label: 'Option 1' },
                { value: 'option2', label: 'Option 2' },
              ]}
              {...register('customerName')}
            />
            {errors.customerName && (
              <Typography
                className='poppins-error-font'
                mt='4px'
                ml={2}
                sx={{ color: '#F90000' }}
              >
                {errors.customerName.message}
              </Typography>
            )}
          </Box>
          <Button
            variant='outlined'
            className='poppins-font'
            sx={{
              ...commonBtn,
              width: '139px',
            }}
            onClick={handleOpenModal}
          >
            + Customer
          </Button>
        </Box>
        <Box sx={{ display: 'flex', gap: 2 }} mt={3}>
          <Box>
            <CustomSelect
              label={
                <>
                  Capacity <span style={{ color: 'red' }}>*</span>
                </>
              }
              options={[
                { value: 'option1', label: 'Option 1' },
                { value: 'option2', label: 'Option 2' },
              ]}
              {...register('capacity')}
            />
            {errors.capacity && (
              <Typography
                className='poppins-error-font'
                mt='4px'
                ml={2}
                sx={{ color: '#F90000' }}
              >
                {errors.capacity.message}
              </Typography>
            )}
          </Box>
          <Button
            className='poppins-font'
            variant='outlined'
            sx={{
              ...commonBtn,
              padding: '5px 13px',
            }}
          >
            + Flex Capacity
          </Button>
        </Box>
        <Box mt={3}>
          <CustomTextField
            type='number'
            label={
              <>
                Copies <span style={{ color: 'red' }}>*</span>
              </>
            }
            {...register('copies')}
          />
          {errors.copies && (
            <Typography
              className='poppins-error-font'
              mt='4px'
              ml={2}
              sx={{ color: '#F90000' }}
            >
              {errors.copies.message}
            </Typography>
          )}
        </Box>
        <Box sx={{ display: 'flex', gap: 2 }} mt={3}>
          <Box>
            <CustomSelect
              options={[
                { value: 'class 1', label: 'class 1' },
                { value: 'class 2', label: 'class 2' },
              ]}
              label={
                <>
                  Class of service <span style={{ color: 'red' }}>*</span>
                </>
              }
              {...register('classOfService')}
            />
            {errors.classOfService && (
              <Typography
                className='poppins-error-font'
                mt='4px'
                ml={2}
                sx={{ color: '#F90000' }}
              >
                {errors.classOfService.message}
              </Typography>
            )}
          </Box>
          <Button
            startIcon={
              <img
                src={Filter}
                alt='filter'
                style={{ width: '15px', height: '18px' }}
              />
            }
            className='poppins-font'
            aria-describedby={id}
            sx={{
              ...commonBtn,
            }}
            variant='outlined'
            onClick={handleClick}
          >
            Advanced config
          </Button>
          {open && (
            <ClickAwayListener
              onClickAway={() => {
                if (!selectOpen) return setAnchorEl(null);
              }}
            >
              <Popper
                id={id}
                open={open}
                anchorEl={anchorEl}
                sx={{
                  border: '1px solid #E4E4E4',
                  boxShadow: '0px 13px 18px 0px rgba(0, 0, 0, 0.17) !important',
                  maxWidth: { xs: 'auto', md: '742px' },
                  width: { md: '100%', xs: '80%' },
                  zIndex: 2,
                  background: '#fff',
                }}
                placement='bottom-end'
              >
                <Box>
                  <Box
                    display='flex'
                    justifyContent='space-between'
                    alignItems='center'
                    sx={{ padding: '17px 18px 17px 48px' }}
                  >
                    <Typography sx={{ ...poppinsFormText, fontWeight: 500 }}>
                      Select The Options
                    </Typography>
                    <CloseIcon
                      onClick={handleClose}
                      sx={{ cursor: 'pointer' }}
                    />
                  </Box>
                  <Divider />
                  <Box
                    sx={{
                      height: '500px',
                      overflowY: 'scroll',
                      zIndex: '10000',
                      background: '#fff',
                      '&::-webkit-scrollbar': {
                        width: '5px',
                      },
                      '&::-webkit-scrollbar-thumb': {
                        backgroundColor: '#809FB8',
                        borderRadius: '10px',
                      },
                      '&::-webkit-scrollbar-thumb:hover': {
                        backgroundColor: '#555',
                      },
                    }}
                  >
                    <OtnAdvSettings
                      handleClose={handleClose}
                      setSelectOpen={setSelectOpen}
                    />
                  </Box>
                </Box>
              </Popper>
            </ClickAwayListener>
          )}
        </Box>
        <Box mt={4}>
          <Button
            type='submit'
            className='poppins-font'
            sx={{
              backgroundColor: '#00F',
              '&:hover': {
                backgroundColor: '#0000BA',
                borderColor: '#0000BA',
              },
              color: 'white',
              minWidth: 316,
              minHeight: 59,
              borderRadius: '10px',
              textTransform: 'none', // To prevent text from being converted to uppercase
            }}
          >
            Proceed
          </Button>
        </Box>
        <NewCustomerModal
          open={isModalOpen}
          handleClose={handleCloseModal}
          handleChangeForCustomer={handleChangeForCustomer}
          customerData={customerData}
        />
      </form>
    </Box>
  );
};

export default OtnBasicCircuit;
