import React from 'react';

import CommonAccordion from '../../components/accordion/CommonAccordion';
import DdwmBasicCircuit from '../../forms/p2p/Dwdm/DwdmBasicCircuit';
import DwdmSouceInfo from '../../forms/p2p/Dwdm/DwdmSourceInfo';
import DwdmDestinationInfo from '../../forms/p2p/Dwdm/DwdmDestinationInfo';
import DwdmConstraints from '../../forms/p2p/Dwdm/DwdmConstraints';

const DWDM: React.FC = () => {
  const accordions = [
    {
      title: 'Circuit Primitive Information',
      description:
        'Please enter a few details below Listed Mandatory Fields, Need More Please filter From advance',
      content: <DdwmBasicCircuit onNext={() => {}} />, // Pass a dummy onNext function for now
    },
    {
      title: 'Source Information',
      description:
        'Please enter a few details below Listed Mandatory Fields.',
      content: <DwdmSouceInfo onNext={() => {}} />, // Pass a dummy onNext function for now
    },
    {
      title: 'Destination Information',
      description:
        'Please enter a few details below Listed Mandatory Fields.',
      content: <DwdmDestinationInfo onNext={() => {}} />, // Pass a dummy onNext function for now
    },
    {
      title: 'Constraints & Route Creations',
      description:
        'Please enter a few details below Listed Mandatory Fields. ',
      content: <DwdmConstraints onNext={() => {}} />, // Pass a dummy onNext function for now
    },
  ];
  return (
    <div>
      <CommonAccordion accordions={accordions}/>
    </div>
  );
};

export default DWDM;
