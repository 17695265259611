export interface RowData {
  srcME: string;
  sourcePort: string;
  destinationEMS: string;
  destinationME: string;
  destPort: string;
  ho: boolean;
  trunkLabel: string;
}

// constants/tableData.ts
export const dynamicData = {
  title: 'Outgoing links for NMS_ASOM_21 (EMS-181)',
  includedText: 'Nodes/TLs Included',
  excludedText: 'Nodes/TLs Excluded',
  includedColor: '#ED8E00',
  excludedColor: '#ED00E3',
};


export const tableHeaders = [
  { id: 'srcME', label: 'Src ME', minWidth: 270 },
  { id: 'sourcePort', label: 'Source Port', minWidth: 150 },
  { id: 'destinationEMS', label: 'Destination EMS', minWidth: 150 },
  { id: 'destinationME', label: 'Destination ME', minWidth: 150 },
  { id: 'destPort', label: 'Dest Port', minWidth: 150 },
  { id: 'ho', label: 'HO', minWidth: 130 },
  { id: 'trunkLabel', label: 'Trunk Label', minWidth: 170 },
];

export const initialRows: RowData[] = [
  {
    srcME: '+ NMS ASON 21',
    sourcePort: 'STM64-1-7-6',
    destinationEMS: 'EMS-184',
    destinationME: '+ NMS ASON 21',
    destPort: 'STM64-1-7-2',
    ho: true,
    trunkLabel: 'TejusNetworksTrunk-1',
  },
  {
    srcME: '+ NMS ASON 22',
    sourcePort: 'STM64-1-7-7',
    destinationEMS: 'EMS-185',
    destinationME: '+ NMS ASON 22',
    destPort: 'STM64-1-7-3',
    ho: true,
    trunkLabel: 'TejusNetworksTrunk-2',
  },
  {
    srcME: '+ NMS ASON 23',
    sourcePort: 'STM64-1-7-8',
    destinationEMS: 'EMS-186',
    destinationME: '+ NMS ASON 23',
    destPort: 'STM64-1-7-4',
    ho: true,
    trunkLabel: 'TejusNetworksTrunk-3',
  },
  {
    srcME: '+ NMS ASON 24',
    sourcePort: 'STM64-1-7-9',
    destinationEMS: 'EMS-187',
    destinationME: '+ NMS ASON 24',
    destPort: 'STM64-1-7-5',
    ho: true,
    trunkLabel: 'TejusNetworksTrunk-4',
  },
  {
    srcME: '+ NMS ASON 25',
    sourcePort: 'STM64-1-7-10',
    destinationEMS: 'EMS-188',
    destinationME: '+ NMS ASON 25',
    destPort: 'STM64-1-7-6',
    ho: true,
    trunkLabel: 'TejusNetworksTrunk-5',
  },
  {
    srcME: '+ NMS ASON 26',
    sourcePort: 'STM64-1-7-11',
    destinationEMS: 'EMS-189',
    destinationME: '+ NMS ASON 26',
    destPort: 'STM64-1-7-7',
    ho: true,
    trunkLabel: 'TejusNetworksTrunk-6',
  },
];
