import React, { useEffect, useRef, useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { styled } from '@mui/system';
import { circuitHeading, circuitSubtext } from '../../themes/summaryStyle';

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  borderRadius: '15px',
  border: '1px solid #E7E7F1',
  background: '#FFF',
  boxShadow: '0px 2px 6px 4px rgba(0, 0, 0, 0.03)',
  padding: '',
  '&.Mui-expanded': {
    // margin: "1.2rem 0",
  },
  '&:not(.Mui-expanded)': {
    // margin: "1.2rem 0",
  },
  '&::before': {
    display: 'none',
  },
}));

interface ContentWithOnNextProps {
  onNext: () => void;
}

interface AccordionContent {
  title: string;
  description: string;
  content: React.ReactElement<ContentWithOnNextProps>;
}

interface CommonAccordionProps {
  checkbox?: boolean;
  accordions: AccordionContent[];
  sendCheckedItems: (e: boolean[]) => void;
}

const AccordionWithFeatures: React.FC<CommonAccordionProps> = ({
  accordions,
  checkbox,
  sendCheckedItems,
}) => {
  const [expanded, setExpanded] = React.useState<string[]>([
    accordions[0].title,
  ]);

  // New state for managing checkbox status
  const [selectAll, setSelectAll] = useState(false);
  const [checkedItems, setCheckedItems] = useState<boolean[]>(
    Array(accordions.length).fill(false)
  );

  useEffect(() => {
    sendCheckedItems(checkedItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedItems]);

  const accordionRefs = useRef<(HTMLDivElement | null)[]>([]);

  const handleToggle =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded((prevExpanded) =>
        isExpanded
          ? [...prevExpanded, panel]
          : prevExpanded.filter((expandedPanel) => expandedPanel !== panel)
      );
    };

  // Handle "Select All" checkbox change
  const handleSelectAllChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = event.target.checked;
    setSelectAll(isChecked);
    setCheckedItems(Array(accordions.length).fill(isChecked));
  };

  // Handle individual checkbox change
  const handleAccordionCheckboxChange = (index: number) => {
    const updatedCheckedItems = [...checkedItems];
    updatedCheckedItems[index] = !updatedCheckedItems[index];
    setCheckedItems(updatedCheckedItems);

    // If all checkboxes are selected, check the "Select All" box
    setSelectAll(updatedCheckedItems.every((item) => item));
  };

  return (
    <div id='parent'>
      {/* "Select All" Checkbox */}
      {checkbox && (
        <FormControlLabel
          sx={{
            marginLeft: '1rem',
            marginBottom: '4px',
          }}
          control={
            <Checkbox checked={selectAll} onChange={handleSelectAllChange} />
          }
          label='Select All'
        />
      )}
      {accordions.map((accordion, index) => (
        <StyledAccordion
          id={`accId-${index}`}
          key={accordion.title}
          ref={(el) => (accordionRefs.current[index] = el)}
          expanded={expanded.includes(accordion.title)}
          onChange={handleToggle(accordion.title)}
          disableGutters
          square
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ fontSize: '2rem' }} />}
            aria-controls={`${accordion.title}-content`}
            id={`${accordion.title}-header`}
            sx={{ alignItems: 'center' }}
          >
            <Box
              display='flex'
              alignItems='start'
              justifyContent='space-between'
              width='100%'
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                <Box>
                  {checkbox && (
                    <Checkbox
                      checked={checkedItems[index]}
                      onChange={() => handleAccordionCheckboxChange(index)}
                    />
                  )}
                </Box>
                <Box>
                  <Typography sx={circuitHeading}>{accordion.title}</Typography>
                  <Typography sx={{ ...circuitSubtext, marginTop: '6px' }}>
                    {accordion.description}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            {React.isValidElement(accordion.content) &&
              React.cloneElement(accordion.content)}
          </AccordionDetails>
        </StyledAccordion>
      ))}
    </div>
  );
};

export default AccordionWithFeatures;
