import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from 'react';

interface CircuitContextProps {
  selectedValue: string;
  subTypeSelect: string;
  setSelectedValue: (value: string) => void;
  setSubTypeSelect: (value: string) => void;
}

const CircuitContext = createContext<CircuitContextProps>({
  selectedValue: 'p2p',
  subTypeSelect: 'otn-gmpls',
  setSelectedValue: () => {},
  setSubTypeSelect: () => {},
});

export const useCircuitContext = () => useContext(CircuitContext);

interface CircuitProviderProps {
  children: ReactNode;
}

export const CircuitProvider: React.FC<CircuitProviderProps> = ({
  children,
}) => {
  const [selectedValue, setSelectedValue] = useState<string>('p2p');
  const [subTypeSelect, setSubTypeSelect] = useState<string>('otn-gmpls');

  useEffect(() => {
    if (selectedValue === 'mep') {
      setSubTypeSelect('otn');
    }
  }, [selectedValue]);

  return (
    <CircuitContext.Provider
      value={{
        selectedValue,
        subTypeSelect,
        setSelectedValue,
        setSubTypeSelect,
      }}
    >
      {children}
    </CircuitContext.Provider>
  );
};
