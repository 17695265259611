import React from 'react';
import { useState } from 'react';

import Dialog from '@mui/material/Dialog';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Slide from '@mui/material/Slide';
import backArrow from '../../assets/svg/backward-arrow.svg';
import forwardArrow from '../../assets/svg/forward-arrow.svg';
import {
  Box,
  Typography,
  AppBar,
  Toolbar,
  Select,
  MenuItem,
  IconButton,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { TransitionProps } from '@mui/material/transitions';
import CustomTextField from '../CustomTextField/CustomTextField';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

interface SettingProps {
  open: boolean;
  handleClose: () => void;
}

const Setting = (props: SettingProps) => {
  const { open, handleClose } = props;
  const [circuitType, setCircuitType] = useState('Point to Point');
  const [subType, setSubType] = useState('OTN-GMPLS');
  const [checkboxState, setCheckboxState] = useState<{
    [key: number]: boolean;
  }>({});
  const [rightItems, setRightItems] = useState([
    {
      key: 1,
      content: (
        <CustomTextField
          label={
            <>
              Source Traffic Type<span style={{ color: 'red' }}>*</span>
            </>
          }
          onChange={undefined}
          name={''}
          width={{ md: '465px', sm: '200px', xs: '150px' }}
        />
      ),
    },
    {
      key: 2,
      content: (
        <CustomTextField
          label={
            <>
              Node/Ip Address <span style={{ color: 'red' }}>*</span>
            </>
          }
          onChange={undefined}
          name={''}
          width={{ md: '465px', sm: '200px', xs: '150px' }}
        />
      ),
    },
    {
      key: 3,
      content: (
        <CustomTextField
          label={
            <>
              Select Port <span style={{ color: 'red' }}>*</span>
            </>
          }
          onChange={undefined}
          name={''}
          width={{ md: '465px', sm: '200px', xs: '150px' }}
        />
      ),
    },
    {
      key: 4,
      content: (
        <CustomTextField
          label={
            <>
              Time Slot <span style={{ color: 'red' }}>*</span>
            </>
          }
          onChange={undefined}
          name={''}
          width={{ md: '465px', sm: '200px', xs: '150px' }}
        />
      ),
    },
    {
      key: 5,
      content: (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 1,
          }}
        >
          <Box
            sx={{
              border: '1px solid #D3D3D3',
              paddingInline: '18px',
              ml: '-13px',
              height: '107px',
              pt: '14px',
              mt: '9px',
              width: { md: '478px', sm: '210px', xs: '150px' },
            }}
          >
            <Typography className='poppins-font'>Circuit Mode</Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{
                        '&.Mui-checked': {
                          color: 'blue',
                        },
                      }}
                    />
                  }
                  sx={{
                    '& .MuiFormControlLabel-label': {
                      fontSize: { md: '16px', sm: '10px' },
                    },
                  }}
                  label='Single Fiber Mode'
                />
              </FormGroup>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{
                        '&.Mui-checked': {
                          color: 'blue',
                        },
                      }}
                    />
                  }
                  sx={{
                    '& .MuiFormControlLabel-label': {
                      fontSize: { md: '16px', sm: '10px' },
                    },
                  }}
                  label='Regenerator Port Pairing'
                />
              </FormGroup>
            </Box>
          </Box>
        </Box>
      ),
    },
    {
      key: 6,
      content: (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 1,
          }}
        >
          <Box
            sx={{
              border: '1px solid #D3D3D3',
              paddingInline: '18px',
              pt: '14px',
              ml: '-13px',
              mt: '-13px',
              height: '107px',
              width: { md: '478px', sm: '210px', xs: '150px' },
            }}
          >
            <Typography className='poppins-font'>Directions</Typography>
            <Box>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby='demo-row-radio-buttons-group-label'
                  name='row-radio-buttons-group'
                  sx={{
                    gap: 3,
                  }}
                >
                  <FormControlLabel
                    value='uni'
                    control={
                      <Radio
                        sx={{
                          '&.Mui-checked': {
                            color: 'blue',
                          },
                        }}
                      />
                    }
                    sx={{
                      '& .MuiFormControlLabel-label': {
                        fontSize: { md: '16px', sm: '10px' },
                      },
                    }}
                    label='Uni'
                  />
                  <FormControlLabel
                    value='bi'
                    control={
                      <Radio
                        sx={{
                          '&.Mui-checked': {
                            color: 'blue',
                          },
                        }}
                      />
                    }
                    sx={{
                      '& .MuiFormControlLabel-label': {
                        fontSize: { md: '16px', sm: '10px' },
                      },
                    }}
                    label='Bi'
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          </Box>
        </Box>
      ),
    },
    {
      key: 7,
      content: (
        <Box
          sx={{
            border: '1px solid #D3D3D3',
            paddingInline: '18px',
            pt: '14px',
            ml: '-13px',
            mt: '-13px',
            height: '107px',
            minWidth: { md: '478px', sm: '210px', xs: '150px' },
          }}
        >
          <Box>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      '&.Mui-checked': {
                        color: 'blue',
                      },
                    }}
                  />
                }
                sx={{
                  '& .MuiFormControlLabel-label': {
                    fontSize: { md: '16px', sm: '10px' },
                  },
                }}
                label='GST'
              />
            </FormGroup>
          </Box>
        </Box>
      ),
    },
  ]);
  const [leftItems, setLeftItems] = useState<
    { key: number; content: JSX.Element }[]
  >([]);

  const handleCircuitTypeChange = (event: any) => {
    setCircuitType(event.target.value);
  };

  const handleSubTypeChange = (event: any) => {
    setSubType(event.target.value);
  };
  const handleCheckboxChange = (key: number) => {
    setCheckboxState((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };
  const handleForwardItems = () => {
    const checkedItems = rightItems.filter((item) => checkboxState[item.key]);
    const uncheckedItems = rightItems.filter(
      (item) => !checkboxState[item.key]
    );

    setLeftItems((prevItems) => [...prevItems, ...checkedItems]);
    setRightItems(uncheckedItems);

    // Clear the checkbox state of moved items
    const newCheckboxState = { ...checkboxState };
    checkedItems.forEach((item) => {
      delete newCheckboxState[item.key];
    });
    setCheckboxState(newCheckboxState);
  };

  const handleBackItems = () => {
    const checkedItems = leftItems.filter((item) => checkboxState[item.key]);
    const uncheckedItems = leftItems.filter((item) => !checkboxState[item.key]);

    setRightItems((prevItems) => [...prevItems, ...checkedItems]);
    setLeftItems(uncheckedItems);

    // Clear the checkbox state of moved items
    const newCheckboxState = { ...checkboxState };
    checkedItems.forEach((item) => {
      delete newCheckboxState[item.key];
    });
    setCheckboxState(newCheckboxState);
  };

  return (
    <React.Fragment>
      <Dialog
        fullScreen
        sx={{ mt: '123px' }}
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar
          sx={{
            mt: '30px',
            maxWidth: '1600px',
            marginInline: 'auto',
          }}
          position='static'
          color='transparent'
          elevation={0}
        >
          <Toolbar sx={{ justifyContent: 'space-between' }}>
            <Typography
              className='poppins-font-heading'
              sx={{ ml: { xl: '140px', md: '55px', sm: '8px' } }}
            >
              Circuit Fields Customization
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                mr: { xl: '120px', md: '25px', sm: '1px' },
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  gap: '10px',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography className='poppins-error-font'>
                  Circuit Type
                </Typography>
                <FormControl variant='outlined' size='small'>
                  <Select
                    value={circuitType}
                    className='poppins-error-font'
                    onChange={handleCircuitTypeChange}
                    label='Circuit type'
                    sx={{
                      width: { md: '150px', sm: '75px', xs: '60px' },
                      border: '1px solid #D9E1E7',
                      color: '#707070',
                      borderRadius: '5px',
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                      },
                    }}
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                  >
                    <MenuItem value='Point to Point'>Point to Point</MenuItem>
                    <MenuItem value='Point to Multipoint'>
                      Point to Multipoint
                    </MenuItem>
                    {/* Add more options as needed */}
                  </Select>
                </FormControl>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  gap: '10px',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography className='poppins-error-font'>Sub-Type</Typography>
                <FormControl variant='outlined' size='small'>
                  <Select
                    value={subType}
                    className='poppins-error-font'
                    onChange={handleSubTypeChange}
                    label='Sub-type'
                    sx={{
                      color: '#707070',
                      width: { md: '150px', sm: '75px', xs: '60px' },
                      border: '1px solid #D9E1E7',
                      borderRadius: '5px',
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                      },
                    }}
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                  >
                    <MenuItem value='OTN-GMPLS'>OTN-GMPLS</MenuItem>
                    <MenuItem value='OTN'>OTN</MenuItem>
                    <MenuItem value='WSON'>WSON</MenuItem>
                    <MenuItem value='SDH'>SDH</MenuItem>
                    <MenuItem value='DWDM'>DWDM</MenuItem>
                    {/* Add more options as needed */}
                  </Select>
                </FormControl>
              </Box>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            mb: 10,
            mt: '40px',
          }}
        >
          <Box>
            <Typography className='poppins-font-heading'>
              Total Filters Available
            </Typography>
            <Box
              sx={{
                height: '900px',
                width: { md: '584px', sm: '300px', xs: '150px' },
                border: '1px solid blue',
                pt: '36px',
                pl: '28px',
                pr: '44px',
                mt: '20px',
              }}
            >
              <Typography className='poppins-font-heading'>
                Basic Circuit Information
              </Typography>
              {rightItems.map((box, ind) => (
                <Box
                  sx={{ display: 'flex', mt: 3, gap: 1, alignItems: 'center' }}
                  key={ind}
                >
                  <React.Fragment>{box.content}</React.Fragment>
                  <FormGroup key={box.key}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={() => handleCheckboxChange(box.key)}
                          key={1}
                          sx={{
                            '& .MuiSvgIcon-root': {
                              fontSize: '22px',
                              borderRadius: '3px',
                            },
                            '&.Mui-checked': {
                              color: 'blue',
                            },
                          }}
                        />
                      }
                      label=''
                    />
                  </FormGroup>
                </Box>
              ))}
            </Box>
          </Box>
          <Box
            sx={{ display: 'flex', flexDirection: 'column', gap: 1, p: '32px' }}
          >
            <img
              src={forwardArrow}
              onClick={handleForwardItems}
              style={{ cursor: 'pointer' }}
              alt='arrow-forward'
            />

            <img
              src={backArrow}
              onClick={handleBackItems}
              style={{ cursor: 'pointer' }}
              alt='arrow-backward'
            />
          </Box>
          <Box sx={{}}>
            <Typography className='poppins-font-heading'>
              Advanced Setting
            </Typography>
            <Box
              sx={{
                height: '900px',
                width: { md: '584px', sm: '300px', xs: '150px' },
                border: '1px solid gray',
                pt: '36px',
                pl: '28px',
                pr: '44px',
                mt: '20px',
              }}
            >
              {leftItems.map((box, ind) => (
                <Box
                  sx={{ display: 'flex', mt: 3, gap: 1, alignItems: 'center' }}
                  key={ind}
                >
                  <React.Fragment>{box.content}</React.Fragment>
                  <FormGroup key={box.key}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={() => handleCheckboxChange(box.key)}
                          key={1}
                          sx={{
                            '&.Mui-checked': {
                              color: 'blue',
                            },
                          }}
                        />
                      }
                      label=''
                    />
                  </FormGroup>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            columnGap: 2,
            justifyContent: 'center',
            mb: 3,
          }}
        >
          <Button
            className='poppins-font'
            onClick={handleClose}
            sx={{
              backgroundColor: '#D9E1E7',
              '&:hover': {
                backgroundColor: '#D9E1E7', // Same background color on hover
              },
              color: 'black',
              minWidth: 180,
              minHeight: 60,
              borderRadius: 2,
              textTransform: 'none', // To prevent text from being converted to uppercase
            }}
          >
            Cancel
          </Button>
          <Button
            className='poppins-font'
            sx={{
              backgroundColor: 'blue',
              '&:hover': {
                backgroundColor: 'blue', // Same background color on hover
              },
              color: 'white',
              minWidth: 180,
              minHeight: 60,
              borderRadius: 2,
              textTransform: 'none', // To prevent text from being converted to uppercase
            }}
          >
            Submit
          </Button>
        </Box>
      </Dialog>
    </React.Fragment>
  );
};
export default Setting;
