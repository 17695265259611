import React, { useState } from 'react';

import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  OutlinedInput,
  RadioGroup,
  Select,
  Typography,
} from '@mui/material';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Radio from '@mui/material/Radio';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import CustomButton from '../../../components/button/CustomButton';
import {
  checkboxStyle,
  selectFormControlStyle,
} from '../../../themes/summaryStyle';

interface OtnGmplsAdvSettingProps {
  handleClose: () => void;
  setSelectOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface FormValues {
  networkMonitoring: string;
  autoPlanned: boolean;
  reRouteMax: number;
  reTryInterval: number;
  reTryMax: number;
  workReRouteMax: number;
  protectReRouteMax: number;
  priority: number;
  disjointness: string;
  disjointnessDegree: string;
  timeSlotSelection: string;
  direction: string;
}

const OtnGmplsAdvSetting: React.FC<OtnGmplsAdvSettingProps> = ({
  handleClose,
  setSelectOpen,
}) => {
  const poppinsFormText = {
    color: '#06152b',
    fontFamily: 'Poppins, sans-serif',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
  };

  const [formValues, setFormValues] = useState<FormValues>({
    networkMonitoring: 'Inherent',
    autoPlanned: false,
    reRouteMax: 0,
    reTryInterval: 0,
    reTryMax: 0,
    workReRouteMax: 0,
    protectReRouteMax: 0,
    priority: 0,
    disjointness: '',
    disjointnessDegree: '',
    timeSlotSelection: 'Manual',
    direction: 'Uni-Direction',
  });

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name!]: value,
    });
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setFormValues({
      ...formValues,
      [name]: checked,
    });
  };

  const handleNumberChange = (name: keyof FormValues, increment: boolean) => {
    setFormValues((prev) => {
      const currentValue = prev[name];
      // Ensure the current value is a number before performing arithmetic
      if (typeof currentValue === 'number') {
        return {
          ...prev,
          [name]: increment ? currentValue + 1 : currentValue - 1,
        };
      }
      return prev;
    });
  };

  return (
    <Box
      sx={{
        padding: '1rem 2rem 3rem 3rem',
      }}
    >
      <Grid container display='flex' alignItems='center' spacing={3}>
        <Grid item xs={4}>
          <Typography sx={poppinsFormText}>Network Monitoring</Typography>
        </Grid>
        <Grid
          item
          display='flex'
          alignItems='center'
          justifyContent='start'
          gap='1rem'
          xs={8}
        >
          <FormControl sx={selectFormControlStyle}>
            <Select
              labelId='networkMonitoring-select-label'
              id='networkMonitoring-select'
              name='networkMonitoring'
              value={formValues.networkMonitoring}
              onChange={handleChange}
              IconComponent={KeyboardArrowDownIcon}
              inputProps={{ 'aria-label': 'Without label' }}
              sx={{
                width: '100%',
                maxWidth: '127px',
                height: '36px',
                '& .MuiSelect-select': {
                  color: formValues.networkMonitoring ? '#707070' : 'inherit',
                },
              }}
              onOpen={() => setSelectOpen(true)}
              MenuProps={{
                disableScrollLock: true,
                TransitionProps: {
                  onExited: () => setSelectOpen(false),
                },
              }}
            >
              <MenuItem value={'Inherent'}>Inherent</MenuItem>
              <MenuItem value={'NonInherent'}>Non-Inherent</MenuItem>
            </Select>
          </FormControl>
          <Typography
            sx={{
              maxWidth: '85px',
              width: '100%',
              fontStyle: 'italic',
              fontSize: '13px',
              fontFamily: 'Poppins, sans-serif',
              fontWeight: 400,
              color: '#117C00',
            }}
          >
            Inherent Nonintrusive
          </Typography>
        </Grid>

        <Grid item xs={6} md={4}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  name='autoPlanned'
                  checked={formValues.autoPlanned}
                  onChange={handleCheckboxChange}
                  sx={checkboxStyle}
                />
              }
              label='Re-Route Option'
              sx={{
                '& .MuiFormControlLabel-label': {
                  ...poppinsFormText,
                },
              }}
            />
          </FormGroup>
        </Grid>

        <Grid item xs={6} md={8} display='flex' alignItems='center' gap='1rem'>
          <Typography sx={poppinsFormText}>Re-route Max</Typography>
          <FormControl>
            <OutlinedInput
              name='reRouteMax'
              value={formValues.reRouteMax}
              onChange={handleChange}
              sx={{
                width: '100%',
                maxWidth: '75px',
                padding: '2px',
                lineHeight: '0',
                height: '36px',
                color: '#707070',
                border: '1px solid #809FB8', // Default border color
                transition:
                  'border-color 0.3s ease, background-color 0.3s ease, color 0.3s ease',
                '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button':
                  {
                    WebkitAppearance: 'none',
                    margin: 0,
                  },
                '& input[type="number"]': {
                  MozAppearance: 'textfield',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#0000FF', // Blue color when focused
                },
                '&:hover fieldset': {
                  border: ' 2px solid #0000FF !important',
                },
                '&:hover': {
                  backgroundColor: 'rgba(220, 226, 255, 0.5)', // Semi-transparent background
                },
                '& input': {
                  color: '#000', // Ensure font color is black
                },
              }}
              type='number'
              endAdornment={
                <Box sx={{ position: 'relative', paddingRight: '40%' }}>
                  <KeyboardArrowUpIcon
                    sx={{
                      height: '20px',
                      position: 'absolute',
                      color: '#707070',
                      right: 0,
                      bottom: '-3px',
                      cursor: 'pointer',
                    }}
                    onClick={() => handleNumberChange('reRouteMax', true)}
                  />
                  <KeyboardArrowDownIcon
                    sx={{
                      height: '20px',
                      position: 'absolute',
                      color: '#707070',
                      right: 0,
                      top: '-4px',
                      cursor: 'pointer',
                    }}
                    onClick={() => handleNumberChange('reRouteMax', false)}
                  />
                </Box>
              }
            />
          </FormControl>
        </Grid>

        <Grid item xs={5} md={3.5}>
          <Typography sx={poppinsFormText}>Re-Try Interval</Typography>
        </Grid>
        <Grid item xs={7} md={8.5}>
          <FormControl>
            <OutlinedInput
              name='reTryInterval'
              value={formValues.reTryInterval}
              onChange={handleChange}
              sx={{
                width: '100%',
                maxWidth: '75px',
                padding: '2px',
                lineHeight: '0',
                height: '36px',
                color: '#707070',
                border: '1px solid #809FB8',
                '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button':
                  {
                    WebkitAppearance: 'none',
                    margin: 0,
                  },
                '& input[type="number"]': {
                  MozAppearance: 'textfield',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#0000FF', // Blue color when focused
                },
                '&:hover fieldset': {
                  border: ' 2px solid #0000FF !important',
                },
                '&:hover': {
                  backgroundColor: 'rgba(220, 226, 255, 0.5)', // Semi-transparent background
                },
                '& input': {
                  color: '#000', // Ensure font color is black
                },
              }}
              type='number'
              endAdornment={
                <Box sx={{ position: 'relative', paddingRight: '40%' }}>
                  <KeyboardArrowUpIcon
                    sx={{
                      height: '20px',
                      position: 'absolute',
                      color: '#707070',
                      right: 0,
                      bottom: '-3px',
                      cursor: 'pointer',
                    }}
                    onClick={() => handleNumberChange('reTryInterval', true)}
                  />
                  <KeyboardArrowDownIcon
                    sx={{
                      height: '20px',
                      position: 'absolute',
                      color: '#707070',
                      right: 0,
                      top: '-4px',
                      cursor: 'pointer',
                    }}
                    onClick={() => handleNumberChange('reTryInterval', false)}
                  />
                </Box>
              }
            />
          </FormControl>
        </Grid>

        <Grid item xs={5} md={3.5}>
          <Typography sx={poppinsFormText}>Re-Try Max</Typography>
        </Grid>
        <Grid item xs={7} md={8.5}>
          <FormControl>
            <OutlinedInput
              name='reTryMax'
              value={formValues.reTryMax}
              onChange={handleChange}
              sx={{
                width: '100%',
                maxWidth: '75px',
                padding: '2px',
                lineHeight: '0',
                height: '36px',
                color: '#707070',
                border: '1px solid #809FB8',
                '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button':
                  {
                    WebkitAppearance: 'none',
                    margin: 0,
                  },
                '& input[type="number"]': {
                  MozAppearance: 'textfield',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#0000FF', // Blue color when focused
                },
                '&:hover fieldset': {
                  border: ' 2px solid #0000FF !important',
                },
                '&:hover': {
                  backgroundColor: 'rgba(220, 226, 255, 0.5)', // Semi-transparent background
                },
                '& input': {
                  color: '#000', // Ensure font color is black
                },
              }}
              type='number'
              endAdornment={
                <Box sx={{ position: 'relative', paddingRight: '40%' }}>
                  <KeyboardArrowUpIcon
                    sx={{
                      height: '20px',
                      position: 'absolute',
                      color: '#707070',
                      right: 0,
                      bottom: '-3px',
                      cursor: 'pointer',
                    }}
                    onClick={() => handleNumberChange('reTryMax', true)}
                  />
                  <KeyboardArrowDownIcon
                    sx={{
                      height: '20px',
                      position: 'absolute',
                      color: '#707070',
                      right: 0,
                      top: '-4px',
                      cursor: 'pointer',
                    }}
                    onClick={() => handleNumberChange('reTryMax', false)}
                  />
                </Box>
              }
            />
          </FormControl>
        </Grid>

        <Grid item xs={5} md={3.5}>
          <Typography sx={poppinsFormText}>Work Re-route max</Typography>
        </Grid>
        <Grid item xs={7} md={8.5}>
          <FormControl>
            <OutlinedInput
              name='workReRouteMax'
              value={formValues.workReRouteMax}
              onChange={handleChange}
              sx={{
                width: '100%',
                maxWidth: '75px',
                padding: '2px',
                lineHeight: '0',
                height: '36px',
                color: '#707070',
                border: '1px solid #809FB8',
                '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button':
                  {
                    WebkitAppearance: 'none',
                    margin: 0,
                  },
                '& input[type="number"]': {
                  MozAppearance: 'textfield',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#0000FF', // Blue color when focused
                },
                '&:hover fieldset': {
                  border: ' 2px solid #0000FF !important',
                },
                '&:hover': {
                  backgroundColor: 'rgba(220, 226, 255, 0.5)', // Semi-transparent background
                },
                '& input': {
                  color: '#000', // Ensure font color is black
                },
              }}
              type='number'
              endAdornment={
                <Box sx={{ position: 'relative', paddingRight: '40%' }}>
                  <KeyboardArrowUpIcon
                    sx={{
                      height: '20px',
                      position: 'absolute',
                      color: '#707070',
                      right: 0,
                      bottom: '-3px',
                      cursor: 'pointer',
                    }}
                    onClick={() => handleNumberChange('workReRouteMax', true)}
                  />
                  <KeyboardArrowDownIcon
                    sx={{
                      height: '20px',
                      position: 'absolute',
                      color: '#707070',
                      right: 0,
                      top: '-4px',
                      cursor: 'pointer',
                    }}
                    onClick={() => handleNumberChange('workReRouteMax', false)}
                  />
                </Box>
              }
            />
          </FormControl>
        </Grid>

        <Grid item xs={5} md={3.5}>
          <Typography sx={poppinsFormText}>Protect Re-route max</Typography>
        </Grid>
        <Grid item xs={7} md={8.5}>
          <FormControl>
            <OutlinedInput
              name='protectReRouteMax'
              value={formValues.protectReRouteMax}
              onChange={handleChange}
              sx={{
                width: '100%',
                maxWidth: '75px',
                padding: '2px',
                lineHeight: '0',
                height: '36px',
                color: '#707070',
                border: '1px solid #809FB8',
                '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button':
                  {
                    WebkitAppearance: 'none',
                    margin: 0,
                  },
                '& input[type="number"]': {
                  MozAppearance: 'textfield',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#0000FF', // Blue color when focused
                },
                '&:hover fieldset': {
                  border: ' 2px solid #0000FF !important',
                },
                '&:hover': {
                  backgroundColor: 'rgba(220, 226, 255, 0.5)', // Semi-transparent background
                },
                '& input': {
                  color: '#000', // Ensure font color is black
                },
              }}
              type='number'
              endAdornment={
                <Box sx={{ position: 'relative', paddingRight: '40%' }}>
                  <KeyboardArrowUpIcon
                    sx={{
                      height: '20px',
                      position: 'absolute',
                      color: '#707070',
                      right: 0,
                      bottom: '-3px',
                      cursor: 'pointer',
                    }}
                    onClick={() =>
                      handleNumberChange('protectReRouteMax', true)
                    }
                  />
                  <KeyboardArrowDownIcon
                    sx={{
                      height: '20px',
                      position: 'absolute',
                      color: '#707070',
                      right: 0,
                      top: '-4px',
                      cursor: 'pointer',
                    }}
                    onClick={() =>
                      handleNumberChange('protectReRouteMax', false)
                    }
                  />
                </Box>
              }
            />
          </FormControl>
        </Grid>

        <Grid item xs={5} md={3.5}>
          <Typography sx={poppinsFormText}>Priority</Typography>
        </Grid>
        <Grid item xs={7} md={8.5}>
          <FormControl>
            <OutlinedInput
              name='priority'
              value={formValues.priority}
              onChange={handleChange}
              sx={{
                width: '100%',
                maxWidth: '75px',
                padding: '2px',
                lineHeight: '0',
                height: '36px',
                color: '#707070',
                border: '1px solid #809FB8',
                '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button':
                  {
                    WebkitAppearance: 'none',
                    margin: 0,
                  },
                '& input[type="number"]': {
                  MozAppearance: 'textfield',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#0000FF', // Blue color when focused
                },
                '&:hover fieldset': {
                  border: ' 2px solid #0000FF !important',
                },
                '&:hover': {
                  backgroundColor: 'rgba(220, 226, 255, 0.5)', // Semi-transparent background
                },
                '& input': {
                  color: '#000', // Ensure font color is black
                },
              }}
              type='number'
              endAdornment={
                <Box sx={{ position: 'relative', paddingRight: '40%' }}>
                  <KeyboardArrowUpIcon
                    sx={{
                      height: '20px',
                      position: 'absolute',
                      color: '#707070',
                      right: 0,
                      bottom: '-3px',
                      cursor: 'pointer',
                    }}
                    onClick={() => handleNumberChange('priority', true)}
                  />
                  <KeyboardArrowDownIcon
                    sx={{
                      height: '20px',
                      position: 'absolute',
                      color: '#707070',
                      right: 0,
                      top: '-4px',
                      cursor: 'pointer',
                    }}
                    onClick={() => handleNumberChange('priority', false)}
                  />
                </Box>
              }
            />
          </FormControl>
        </Grid>

        <Grid item xs={5} md={4}>
          <Typography sx={poppinsFormText}>Disjointness</Typography>
        </Grid>
        <Grid
          item
          display='flex'
          alignItems='center'
          justifyContent='start'
          gap='.8rem'
          xs={7}
          md={8}
        >
          <FormControl sx={selectFormControlStyle}>
            <Select
              labelId='disjointness-select-label'
              id='disjointness-select'
              name='disjointness'
              value={formValues.disjointness}
              onChange={handleChange}
              IconComponent={KeyboardArrowDownIcon}
              inputProps={{ 'aria-label': 'Without label' }}
              sx={{
                width: '100%',
                maxWidth: '104px',
                height: '36px',
                '& .MuiSelect-select': {
                  color: formValues.networkMonitoring ? '#707070' : 'inherit',
                },
              }}
              onOpen={() => setSelectOpen(true)}
              MenuProps={{
                disableScrollLock: true,
                TransitionProps: {
                  onExited: () => setSelectOpen(false),
                },
              }}
            >
              <MenuItem value='Link'>Link</MenuItem>
              <MenuItem value='Node'>Node</MenuItem>
              <MenuItem value='SRLC'>SRLC</MenuItem>
            </Select>
          </FormControl>
          <Typography
            sx={{
              maxWidth: '46px',
              width: '100%',
              fontStyle: 'italic',
              fontSize: '13px',
              fontFamily: 'Poppins, sans-serif',
              fontWeight: 400,
              color: '#117C00',
            }}
          >
            ! Link 2 Node 3 SRLC{' '}
          </Typography>
        </Grid>

        <Grid item xs={5} md={4}>
          <Typography sx={poppinsFormText}>Disjointness Degree</Typography>
        </Grid>
        <Grid
          item
          display='flex'
          alignItems='center'
          justifyContent='start'
          gap='.8rem'
          xs={7}
          md={8}
        >
          <FormControl sx={selectFormControlStyle}>
            <Select
              labelId='disjointness-degree-select-label'
              id='disjointness-degree-select'
              name='disjointnessDegree'
              value={formValues.disjointnessDegree}
              onChange={handleChange}
              IconComponent={KeyboardArrowDownIcon}
              inputProps={{ 'aria-label': 'Without label' }}
              sx={{
                width: '100%',
                maxWidth: '104px',
                height: '36px',
                '& .MuiSelect-select': {
                  color: formValues.networkMonitoring ? '#707070' : 'inherit',
                },
              }}
              onOpen={() => setSelectOpen(true)}
              MenuProps={{
                disableScrollLock: true,
                TransitionProps: {
                  onExited: () => setSelectOpen(false),
                },
              }}
            >
              <MenuItem value='Full'>Full</MenuItem>
              <MenuItem value='Partial'>Partial</MenuItem>
            </Select>
          </FormControl>
          <Typography
            sx={{
              maxWidth: '68px',
              width: '100%',
              fontStyle: 'italic',
              fontSize: '13px',
              fontFamily: 'Poppins, sans-serif',
              fontWeight: 400,
              color: '#117C00',
            }}
          >
            ! Full 2 Maximum{' '}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography sx={{ ...poppinsFormText, fontWeight: 600 }}>
            Time slot selection
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <FormControl>
            <RadioGroup
              aria-labelledby='time-slot-selection-group'
              name='timeSlotSelection'
              value={formValues.timeSlotSelection}
              onChange={handleChange}
              row
              sx={{ gap: 5 }}
            >
              <FormControlLabel
                value='Manual'
                control={
                  <Radio
                    sx={{
                      color: 'lightgray',
                      '&.Mui-checked': {
                        color: 'white',
                      },
                      '&.Mui-checked .MuiSvgIcon-root': {
                        backgroundColor: 'blue',
                        borderRadius: '50%',
                      },
                      '& .MuiSvgIcon-root': {
                        fontSize: 28,
                      },
                    }}
                  />
                }
                label='Manual'
              />
              <FormControlLabel
                value='Auto'
                control={
                  <Radio
                    sx={{
                      color: 'lightgray',
                      '&.Mui-checked': {
                        color: 'white',
                      },
                      '&.Mui-checked .MuiSvgIcon-root': {
                        backgroundColor: 'blue',
                        borderRadius: '50%',
                      },
                      '& .MuiSvgIcon-root': {
                        fontSize: 28,
                      },
                    }}
                  />
                }
                label='Auto'
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item xs={4}>
          <Typography sx={{ ...poppinsFormText, fontWeight: 600 }}>
            Direction:
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <FormControl>
            <RadioGroup
              aria-labelledby='direction-group'
              name='direction'
              value={formValues.direction}
              onChange={handleChange}
              row
            >
              <FormControlLabel
                value='Uni-Direction'
                control={
                  <Radio
                    sx={{
                      color: 'lightgray',
                      '&.Mui-checked': {
                        color: 'white',
                      },
                      '&.Mui-checked .MuiSvgIcon-root': {
                        backgroundColor: 'blue',
                        borderRadius: '50%',
                      },
                      '& .MuiSvgIcon-root': {
                        fontSize: 28,
                      },
                    }}
                  />
                }
                label='Uni-Direction'
              />
              <FormControlLabel
                value='Bi-Direction'
                control={
                  <Radio
                    sx={{
                      color: 'lightgray',
                      '&.Mui-checked': {
                        color: 'white',
                      },
                      '&.Mui-checked .MuiSvgIcon-root': {
                        backgroundColor: 'blue',
                        borderRadius: '50%',
                      },
                      '& .MuiSvgIcon-root': {
                        fontSize: 28,
                      },
                    }}
                  />
                }
                label='Bi-Direction'
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12} display='flex' gap='1rem'>
          <CustomButton
            variantType='primary'
            size='small'
            padding='1rem 2.5rem'
            width='179px'
            onClick={handleClose}
          >
            Submit
          </CustomButton>
          <CustomButton
            variantType='secondary'
            size='small'
            padding='1rem 2.5rem'
            width='179px'
            onClick={handleClose}
          >
            Cancel
          </CustomButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default OtnGmplsAdvSetting;
