import * as React from 'react';

import CloseIcon from '@mui/icons-material/Close';

import { useState } from 'react';

import {
  Box,
  Button,
  Grid,
  Typography,
  IconButton,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Modal,
} from '@mui/material';

const items = [
  '01',
  '02',
  '01',
  '02',
  '01',
  '02',
  '01',
  '02',
  '01',
  '02',
  '01',
  '02',
  '01',
  '02',
  '01',
  '02',
  '01',
  '02',
  '01',
  '02',
  '01',
  '02',
  '01',
  '02',
  '01',
  '02',
  '01',
  '02',
  '01',
  '02',
  '01',
  '02',
  '01',
  '02',
  '01',
  '02',
  '01',
  '02',
  '01',
  '02',
  '01',
  '02',
  '01',
  '02',
  '01',
  '02',
  '01',
  '02',
];
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid blue',
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
  width: { md: '970px', sm: '700px', xs: '200px' },
  //   height: '670px',
};

interface TimeSlotModalProps {
  open: boolean;
  handleClose: () => void;
  //   handleChangeForCustomer: any;
  //   customerData: any;
}

const TimeSlotModal: React.FC<TimeSlotModalProps> = ({ open, handleClose }) => {
  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  const handleSelect = (item: string) => {
    setSelectedItems([...selectedItems, item]);
  };

  const handleRemove = (index: number) => {
    setSelectedItems(selectedItems.filter((_, i) => i !== index));
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography> OTN-GMPLS Channel</Typography>
          <CloseIcon onClick={handleClose} sx={{ cursor: 'pointer' }} />
        </Box>

        <Box sx={{ display: 'flex', gap: 1, mt: 2 }}>
          {/* <Paper sx={{ p: 2, flex: 3 }}> */}
          <Grid
            container
            spacing={1}
            sx={{
              '--Grid-borderWidth': '1px',
              borderTop: 'var(--Grid-borderWidth) solid',
              borderLeft: 'var(--Grid-borderWidth) solid',
              borderColor: 'divider',
              '& > div': {
                borderRight: 'var(--Grid-borderWidth) solid',
                borderBottom: 'var(--Grid-borderWidth) solid',
                borderColor: 'divider',
              },
            }}
          >
            {items.map((item, index) => (
              <Grid
                item
                xs={1.5}
                key={index}
                sx={{
                  backgroundColor: selectedItems.includes(item)
                    ? '#e0e0e0'
                    : '#fff',
                }}
              >
                <Box
                  sx={{
                    textAlign: 'center',
                    cursor: 'pointer',
                    height: '30px',
                    width: '30px',
                    backgroundColor: selectedItems.includes(item)
                      ? '#e0e0e0'
                      : '#fff',
                    // '&:hover': {
                    //   backgroundColor: '#f0f0f0',
                    // },
                  }}
                  onClick={() => handleSelect(item)}
                >
                  <Typography sx={{ textAlign: 'center' }}>{item}</Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
          {/* </Paper> */}
          <Paper
            sx={{
              //   p: 2,
              width: { md: '150px', sm: '100px', xs: '70px' },
              marginTop: '-8px',
              backgroundColor: 'lightgray',
              padding: 1,
            }}
          >
            <List>
              {selectedItems.map((item, index) => (
                <ListItem
                  key={index}
                  sx={{
                    border: '1px solid #D7D7D7',
                    backgroundColor: 'white',
                    height: '37px',
                    borderRadius: 1,
                    mt: 1,
                  }}
                >
                  <ListItemText primary={item} />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" onClick={() => handleRemove(index)}>
                      <CloseIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </Paper>
        </Box>
        <Box
          sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end', mt: 3 }}
        >
          <Button
            className="poppins-font"
            sx={{
              backgroundColor: '#D9E1E7',
              '&:hover': {
                backgroundColor: '#D9E1E7', // Same background color on hover
              },
              color: 'black',
              minWidth: 180,
              minHeight: 60,
              borderRadius: 2,
              textTransform: 'none', // To prevent text from being converted to uppercase
            }}
          >
            + Channel
          </Button>
          <Button
            className="poppins-font"
            sx={{
              backgroundColor: '#D9E1E7',
              '&:hover': {
                backgroundColor: '#D9E1E7', // Same background color on hover
              },
              color: 'black',
              minWidth: 180,
              minHeight: 60,
              borderRadius: 2,
              textTransform: 'none', // To prevent text from being converted to uppercase
            }}
          >
            Auto
          </Button>
          <Button
            variant="contained"
            className="poppins-font"
            sx={{
              backgroundColor: 'blue',
              '&:hover': {
                backgroundColor: 'blue', // Same background color on hover
              },
              color: 'white',
              minWidth: 180,
              minHeight: 60,
              borderRadius: 2,
              textTransform: 'none', // To prevent text from being converted to uppercase
            }}
          >
            Apply
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default TimeSlotModal;
