// src/components/CustomTable.tsx

import React from 'react';

import {
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Checkbox,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import {
  checkboxStyle,
  tableCell,
  tableHeading,
  textStyle,
} from '../../themes/summaryStyle';

import { RowData } from '../../constants/tableData';

interface CustomTableProps {
  rows: RowData[];
  tableHeaders: { id: string; label: string; minWidth: number }[];
  handleCheckboxChange: (index: number) => void;
  handleSrcClick: (srcME: string) => void;
  handleDestinationClick: (destinationME: string) => void;
  dynamicData: {
    title: string;
    includedText: string;
    excludedText: string;
    includedColor: string;
    excludedColor: string;
  };
  setTableVisibility: (isVisible: boolean) => void;
}

const CustomTable: React.FC<CustomTableProps> = ({
  rows,
  tableHeaders,
  handleCheckboxChange,
  handleSrcClick,
  handleDestinationClick,
  dynamicData,
  setTableVisibility,
}) => {
  return (
    <Box
      sx={{
        width: { sm: '400px', md: '700px', lg: '1090px' },
        background: '#FFF',
        boxShadow: '0px 8px 7px 0px rgba(0, 0, 0, 0.17)',
        border: '1px solid #E4E4E4',
        mt: '16px',
        mx: 'auto',
      }}
    >
      <Box
        display='flex'
        justifyContent='space-between'
        sx={{
          padding: '14px 14px 13px 27px',
          borderBottom: '1px solid #E4E4E4',
        }}
      >
        <Typography sx={{ ...tableHeading, fontSize: '12px' }}>
          {dynamicData.title}{' '}
        </Typography>
        <Box display='flex' justifyContent='space-between'>
          <Stack
            direction='row'
            alignItems='center'
            gap='6px'
            sx={{ paddingRight: '56px' }}
          >
            <Typography sx={textStyle}>{dynamicData.includedText}</Typography>
            <span
              style={{
                width: '36px',
                height: '9px',
                borderRadius: '20px',
                background: dynamicData.includedColor,
              }}
            ></span>
          </Stack>
          <Stack
            direction='row'
            alignItems='center'
            gap='6px'
            sx={{ paddingRight: '58px' }}
          >
            <Typography sx={textStyle}>{dynamicData.excludedText}</Typography>
            <span
              style={{
                width: '36px',
                height: '9px',
                borderRadius: '20px',
                background: dynamicData.excludedColor,
              }}
            ></span>
          </Stack>
          <CloseIcon onClick={() => setTableVisibility(false)} />
        </Box>
      </Box>
      <Box>
        <TableContainer
          component={Paper}
          sx={{
            '&::-webkit-scrollbar': {
              height: '6px',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#9E9E9E',
              borderRadius: '20px',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              backgroundColor: '#555',
            },
          }}
        >
          <Table
            aria-label='simple table'
            padding='none'
            stickyHeader
            sx={{ minWidth: 650 }}
          >
            <TableHead
              sx={{
                padding: '8px 0px',
                '& th': {
                  background: '#F8F9FE',
                },
              }}
            >
              <TableRow>
                {tableHeaders.map((header, index) => (
                  <TableCell
                    key={header.id}
                    sx={{
                      ...tableHeading,
                      fontSize: '13px',
                      padding:
                        header.id === 'srcME'
                          ? { xs: '8px 0px 8px 50px', lg: '8px 0px 8px 100px' }
                          : '8px 0px',
                      minWidth: header.minWidth,
                    }}
                    align='left'
                  >
                    {header.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow key={index} sx={{ padding: '80px 0px' }}>
                  <TableCell
                    component='th'
                    scope='row'
                    onClick={() => handleSrcClick(row.srcME)}
                    align='left'
                    sx={{
                      ...tableCell,
                      color: '#00F',
                      padding: {
                        xs: '8px 0px 8px 50px',
                        lg: '8px 0px 8px 100px',
                      },
                      cursor: 'pointer',
                    }}
                  >
                    {row.srcME}
                  </TableCell>
                  <TableCell
                    align='left'
                    sx={{ ...tableCell, color: '#141414', padding: '8px 0px' }}
                  >
                    {row.sourcePort}
                  </TableCell>
                  <TableCell
                    align='left'
                    sx={{ ...tableCell, color: '#141414', padding: '8px 0px' }}
                  >
                    {row.destinationEMS}
                  </TableCell>
                  <TableCell
                    align='left'
                    sx={{ ...tableCell, color: '#00F', cursor: 'pointer' }}
                    onClick={() => handleDestinationClick(row.destinationME)}
                  >
                    {row.destinationME}
                  </TableCell>
                  <TableCell
                    sx={{ ...tableCell, color: '#141414' }}
                    align='left'
                  >
                    {row.destPort}
                  </TableCell>
                  <TableCell align='left'>
                    <Checkbox
                      sx={checkboxStyle}
                      checked={row.ho}
                      onChange={() => handleCheckboxChange(index)}
                    />
                  </TableCell>
                  <TableCell
                    sx={{ ...tableCell, color: '#141414' }}
                    align='left'
                  >
                    {row.trunkLabel}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default CustomTable;
