import React, { useState } from 'react';

import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Radio from '@mui/material/Radio';

import CustomButton from '../../../components/button/CustomButton';
import {
  checkboxStyle,
  poppinsFormText,
  radioStyle,
  selectFormControlStyle,
  tableHeading,
} from '../../../themes/summaryStyle';

interface DwdmAdvSettingProps {
  handleClose: () => void;
  setSelectOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface FormValues {
  networkMonitoring: string;
  yCableProtectionType: string;
  autoPlanned: boolean;
  singleFiberMode: boolean;
  regeneratorPortPairing: boolean;
  flexiGridSupport: boolean;
  cst: boolean;
  timeSlotSelection: string;
  provisioningSelection: string;
  frequencyType: string;
  frequencyTypeTx: string;
  frequencyTypeRx: string;
  fpuCascade: boolean;
  noOfPaths: string;
  lamdaRegenerationEnable: boolean;
  isILU: boolean;
  osnrMonitoring: boolean;
  recurringHourly: boolean;
  recurringDaily: boolean;
  recurringWeekly: boolean;
  recurringMonthly: boolean;
  direction: string;
}

const DwdmAdvSettings: React.FC<DwdmAdvSettingProps> = ({
  handleClose,
  setSelectOpen,
}) => {
  const [formValues, setFormValues] = useState<FormValues>({
    networkMonitoring: '1',
    yCableProtectionType: 'Client',
    autoPlanned: false,
    singleFiberMode: false,
    regeneratorPortPairing: false,
    flexiGridSupport: false,
    cst: false,
    timeSlotSelection: 'Manual',
    provisioningSelection: 'Frequency',
    frequencyType: 'All',
    frequencyTypeTx: 'Even',
    frequencyTypeRx: 'Even',
    fpuCascade: false,
    noOfPaths: '1',
    lamdaRegenerationEnable: false,
    isILU: false,
    osnrMonitoring: false,
    recurringHourly: false,
    recurringDaily: false,
    recurringWeekly: false,
    recurringMonthly: false,
    direction: 'Uni-Direction',
  });

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setFormValues({
      ...formValues,
      [name]: checked,
    });
  };

  const handleInputChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value, type } = event.target;
    const checked = (event.target as HTMLInputElement).checked; // Cast to HTMLInputElement

    setFormValues({
      ...formValues,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  return (
    <Box sx={{ padding: '1rem 2rem 3rem 3rem' }}>
      <Grid container display='flex' alignItems='center'>
        <Grid item xs={12} md={12}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  name='singleFiberMode'
                  checked={formValues.singleFiberMode}
                  onChange={handleInputChange}
                  sx={checkboxStyle}
                />
              }
              label='Single Fiber mode'
              sx={{ '& .MuiFormControlLabel-label': poppinsFormText }}
            />
          </FormGroup>
        </Grid>

        <Grid item xs={12} md={12} display='flex' alignItems='center'>
          <Grid item xs={5} md={4}>
            {' '}
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    name='flexiGridSupport'
                    checked={formValues.flexiGridSupport}
                    onChange={handleInputChange}
                    sx={checkboxStyle}
                  />
                }
                label='Flexi Grid Support'
                sx={{ '& .MuiFormControlLabel-label': poppinsFormText }}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={5} md={8}>
            <Box display='flex' alignItems='center' gap='11px'>
              <Typography sx={{ ...tableHeading, fontWeight: 600 }}>
                Spectrum Slots(n*6.25Ghz)
              </Typography>
              <FormControl
                sx={{
                  ...selectFormControlStyle,
                  maxWidth: '75px',
                }}
              >
                <Select
                  labelId='networkMonitoring-select-label'
                  id='networkMonitoring-select'
                  name='networkMonitoring'
                  value={formValues.networkMonitoring}
                  onChange={handleSelectChange}
                  IconComponent={KeyboardArrowDownIcon}
                  inputProps={{ 'aria-label': 'Without label' }}
                  sx={{
                    width: '100%',
                    maxWidth: '127px',
                    height: '36px',
                    '& .MuiSelect-select': {
                      color: formValues.networkMonitoring
                        ? '#707070'
                        : 'inherit',
                    },
                  }}
                  onOpen={() => setSelectOpen(true)}
                  MenuProps={{
                    disableScrollLock: true,
                    TransitionProps: {
                      onExited: () => setSelectOpen(false),
                    },
                  }}
                >
                  <MenuItem value='1'>1</MenuItem>
                  <MenuItem value='2'>2</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>
        </Grid>

        <Grid item xs={5} md={4} mt={'17px'}>
          <Typography sx={{ ...poppinsFormText, fontWeight: 600 }}>
            Frequency Selection:
          </Typography>
        </Grid>
        <Grid item xs={7} md={8} mt={'17px'}>
          <FormControl>
            <RadioGroup
              aria-labelledby='time-slot-selection-group'
              name='timeSlotSelection'
              value={formValues.timeSlotSelection}
              onChange={handleInputChange}
              row
              sx={{ gap: { xs: 4, md: 6 } }}
            >
              <FormControlLabel
                value='Manual'
                control={<Radio sx={radioStyle} />}
                label='Manual'
              />
              <FormControlLabel
                value='Auto'
                control={<Radio sx={radioStyle} />}
                label='Auto'
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item xs={5} md={4}>
          <Typography sx={{ ...poppinsFormText, fontWeight: 600 }}>
            Provisioning Selection
          </Typography>
        </Grid>
        <Grid item xs={7} md={8}>
          <FormControl>
            <RadioGroup
              aria-labelledby='provisioning-selection-group'
              name='provisioningSelection'
              value={formValues.provisioningSelection}
              onChange={handleInputChange}
              row
              sx={{ gap: { xs: 1, md: 3 } }}
            >
              <FormControlLabel
                value='Frequency'
                control={<Radio sx={radioStyle} />}
                label='Frequency'
              />
              <FormControlLabel
                value='Port'
                control={<Radio sx={radioStyle} />}
                label='Port'
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item xs={5} md={4}>
          <Typography sx={{ ...poppinsFormText, fontWeight: 600 }}>
            Frequency Type{' '}
          </Typography>
        </Grid>
        <Grid item xs={7} md={8}>
          <FormControl>
            <RadioGroup
              aria-labelledby='frequency-type-group'
              name='frequencyType'
              value={formValues.frequencyType}
              onChange={handleInputChange}
              row
              sx={{ gap: { md: 10.3 } }}
            >
              <FormControlLabel
                value='All'
                control={<Radio sx={radioStyle} />}
                label='All'
              />
              <FormControlLabel
                value='Even'
                control={<Radio sx={radioStyle} />}
                label='Even'
              />
              <FormControlLabel
                value='Odd'
                control={<Radio sx={radioStyle} />}
                label='Odd'
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item xs={5} md={4}>
          <Typography sx={{ ...poppinsFormText, fontWeight: 600 }}>
            Frequency Type Tx
          </Typography>
        </Grid>
        <Grid item xs={7} md={8}>
          <FormControl>
            <RadioGroup
              aria-labelledby='frequency-type-tx-group'
              name='frequencyTypeTx'
              value={formValues.frequencyTypeTx}
              onChange={handleInputChange}
              row
              sx={{ gap: { xs: 6, md: 8 } }}
            >
              <FormControlLabel
                value='Even'
                control={<Radio sx={radioStyle} />}
                label='Even'
              />
              <FormControlLabel
                value='Odd'
                control={<Radio sx={radioStyle} />}
                label='Odd'
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item xs={5} md={4}>
          <Typography sx={{ ...poppinsFormText, fontWeight: 600 }}>
            Frequency Type Rx
          </Typography>
        </Grid>
        <Grid item xs={7} md={8}>
          <FormControl>
            <RadioGroup
              aria-labelledby='frequency-type-rx-group'
              name='frequencyTypeRx'
              value={formValues.frequencyTypeRx}
              onChange={handleInputChange}
              row
              sx={{ gap: { xs: 6, md: 8 } }}
            >
              <FormControlLabel
                value='Even'
                control={<Radio sx={radioStyle} />}
                label='Even'
              />
              <FormControlLabel
                value='Odd'
                control={<Radio sx={radioStyle} />}
                label='Odd'
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item xs={5} md={4}>
          <Typography sx={{ ...poppinsFormText, fontWeight: 600 }}>
            Direction:
          </Typography>
        </Grid>
        <Grid item xs={7} md={8}>
          <FormControl>
            <RadioGroup
              aria-labelledby='direction-group'
              name='direction'
              value={formValues.direction}
              onChange={handleInputChange}
              row
              sx={{ gap: { md: 1 } }}
            >
              <FormControlLabel
                value='Uni-Direction'
                control={<Radio sx={radioStyle} />}
                label='Uni-Direction'
              />
              <FormControlLabel
                value='Bi-Direction'
                control={<Radio sx={radioStyle} />}
                label='Bi-Direction'
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        {/*  */}

        <Grid item xs={12} md={12} display='flex' alignItems='center' mt={2}>
          <Grid item xs={4}>
            {' '}
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    name='fpuCascade'
                    checked={formValues.fpuCascade}
                    onChange={handleCheckboxChange}
                    sx={checkboxStyle}
                  />
                }
                label='FPU Cascade'
                sx={{ '& .MuiFormControlLabel-label': poppinsFormText }}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={8}>
            <Box display='flex' alignItems='center' gap='11px'>
              <Typography sx={{ ...tableHeading, fontWeight: 600 }}>
                No Of Paths
              </Typography>
              <FormControl
                sx={{
                  ...selectFormControlStyle,
                  maxWidth: '75px',
                }}
              >
                <Select
                  labelId='no-of-paths-select-label'
                  id='no-of-paths-select'
                  name='noOfPaths'
                  value={formValues.noOfPaths}
                  onChange={handleSelectChange}
                  IconComponent={KeyboardArrowDownIcon}
                  inputProps={{ 'aria-label': 'Without label' }}
                  sx={{
                    width: '100%',
                    maxWidth: '127px',
                    height: '36px',
                    '& .MuiSelect-select': {
                      color: formValues.networkMonitoring
                        ? '#707070'
                        : 'inherit',
                    },
                  }}
                  onOpen={() => setSelectOpen(true)}
                  MenuProps={{
                    disableScrollLock: true,
                    TransitionProps: {
                      onExited: () => setSelectOpen(false),
                    },
                  }}
                >
                  <MenuItem value='1'>1</MenuItem>
                  <MenuItem value='2'>2</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>
        </Grid>

        <Grid item xs={12} md={12}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  name='lamdaRegenerationEnable'
                  checked={formValues.lamdaRegenerationEnable}
                  onChange={handleCheckboxChange}
                  sx={checkboxStyle}
                />
              }
              label='Lamda Regeneration Enable'
              sx={{ '& .MuiFormControlLabel-label': poppinsFormText }}
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12} md={12}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  name='isILU'
                  checked={formValues.isILU}
                  onChange={handleCheckboxChange}
                  sx={checkboxStyle}
                />
              }
              label='Is ILU'
              sx={{ '& .MuiFormControlLabel-label': poppinsFormText }}
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12} md={12} display='flex' alignItems='start' gap='25px'>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  name='osnrMonitoring'
                  checked={formValues.osnrMonitoring}
                  onChange={handleCheckboxChange}
                  sx={checkboxStyle}
                />
              }
              label='OSNR Monitoring'
              sx={{ '& .MuiFormControlLabel-label': poppinsFormText }}
            />
          </FormGroup>
          <Box
            sx={{
              borderRadius: '10px',
              border: '1px solid #E0E0E0',
              background: '#FDFDFD',
              boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
              maxWidth: '414px',
              padding: '20px',
            }}
          >
            <Typography sx={{ ...poppinsFormText, fontWeight: 600 }}>
              Recurring
            </Typography>
            <Box>
              <FormGroup
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  columnGap: '34px',
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      name='recurringHourly'
                      checked={formValues.recurringHourly}
                      onChange={handleCheckboxChange}
                      sx={checkboxStyle}
                    />
                  }
                  label='Hourly'
                  sx={{ '& .MuiFormControlLabel-label': poppinsFormText }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name='recurringDaily'
                      checked={formValues.recurringDaily}
                      onChange={handleCheckboxChange}
                      sx={checkboxStyle}
                    />
                  }
                  label='Daily'
                  sx={{ '& .MuiFormControlLabel-label': poppinsFormText }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name='recurringWeekly'
                      checked={formValues.recurringWeekly}
                      onChange={handleCheckboxChange}
                      sx={checkboxStyle}
                    />
                  }
                  label='Weekly'
                  sx={{ '& .MuiFormControlLabel-label': poppinsFormText }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name='recurringMonthly'
                      checked={formValues.recurringMonthly}
                      onChange={handleCheckboxChange}
                      sx={checkboxStyle}
                    />
                  }
                  label='Monthly'
                  sx={{ '& .MuiFormControlLabel-label': poppinsFormText }}
                />
              </FormGroup>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} display='flex' gap='1rem' mt={'40px'}>
          <CustomButton
            variantType='primary'
            size='small'
            padding='1rem 2.5rem'
            width='179px'
            onClick={handleClose}
          >
            Submit
          </CustomButton>
          <CustomButton
            variantType='secondary'
            size='small'
            padding='1rem 2.5rem'
            width='179px'
            onClick={handleClose}
          >
            Cancel
          </CustomButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DwdmAdvSettings;
