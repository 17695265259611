import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { Box, Button, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import CustomTextField from '../../../components/CustomTextField/CustomTextField';
import {
  destinationInfoSchema,
  validationDestinationInfoSchemaType,
} from '../../../schema/schema';
import TimeSlotModal from '../../../components/modal/TimeSlotModal';
import SearchAutoComplete from '../../../components/SearchAutoComplete/SearchAutoComplete';
import CustomSelect from '../../../components/CustomSelect/CustomSelect';
import { useStepContext } from '../../../contexts/StepProviderContext';
import { commonBtn, submitBtn } from '../../../themes/summaryStyle';

interface OtnGmplsDestinationInfoProps {
  onNext: () => void;
}

const OtnDestinationInfo: React.FC<OtnGmplsDestinationInfoProps> = ({
  onNext,
}) => {
  // const [formData, setFormData] = useState({
  //   destinationTrafficType: '',
  //   nodeIPAddress: '',
  //   selectPort: '',
  //   timeSlot: '',
  // });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<validationDestinationInfoSchemaType>({
    resolver: zodResolver(destinationInfoSchema),
  });
  const ipAddresses = [
    '192.168.0.1',
    '10.0.0.1',
    '172.16.0.1',
    '192.168.1.1',
    '203.0.113.1',
    '8.8.8.8',
    '192.168.100.1',
    '192.0.2.1',
    '198.51.100.1',
    '255.255.255.255',
  ];
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { formNumber, setFormNumber } = useStepContext();

  // const handleChange = (
  //   e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  // ) => {
  //   const { name, value } = e.target;
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     [name]: value,
  //   }));
  // };
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const onSubmit = (data: validationDestinationInfoSchemaType) => {
    setFormNumber(3);
    onNext();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box mt={2}>
        <CustomTextField
          label={
            <>
              Destination Traffic Type<span style={{ color: 'red' }}>*</span>
            </>
          }
          {...register('destinationTrafficType')}
        />
        {errors.destinationTrafficType && (
          <Typography
            mt='4px'
            ml={2}
            sx={{ color: '#F90000' }}
            className='poppins-error-font'
          >
            {errors.destinationTrafficType.message}
          </Typography>
        )}
        {/* <Box sx={{ display: 'flex', flexDirection: 'column' }} mt={3}>
          <CustomTextField
            label={
              <>
                Node/Ip Address<span style={{ color: 'red' }}>*</span>
              </>
            }
            {...register('ipAddress')}
          />
          {errors.ipAddress && (
            <Typography
              mt='4px'
              ml={2}
              sx={{ color: '#F90000' }}
              className='poppins-error-font'
            >
              {errors.ipAddress.message}
            </Typography>
          )}
          
        </Box> */}
        <Box mt={3} sx={{ display: 'flex', flexDirection: 'column' }}>
          <Controller
            name='ipAddress'
            control={control}
            render={({ field }) => (
              <SearchAutoComplete
                ipAddresses={ipAddresses}
                value={field.value?.toString() || ''}
                onChange={(event: any, value: any) => {
                  field.onChange(value);
                }}
                label={
                  <>
                    Node/Ip Address<span style={{ color: 'red' }}>*</span>
                  </>
                }
              />
            )}
          />
          {errors.ipAddress && (
            <Typography
              mt='4px'
              ml={2}
              sx={{ color: '#F90000' }}
              className='poppins-error-font'
            >
              {errors.ipAddress.message}
            </Typography>
          )}
        </Box>
        <Box mt={3}>
          <Box>
            <CustomSelect
              label={
                <>
                  Select Port <span style={{ color: 'red' }}>*</span>
                </>
              }
              options={[
                { value: 'common-1-3-1', label: 'common-1-3-1' },
                { value: 'ocommon-1-3-2', label: 'common-1-3-2' },
              ]}
              {...register('selectPort')}
            />
            {errors.selectPort && (
              <Typography
                className='poppins-error-font'
                mt='4px'
                ml={2}
                sx={{ color: '#F90000' }}
              >
                {errors.selectPort.message}
              </Typography>
            )}
          </Box>
        </Box>
        <Box mt={3} sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          <Box>
            <CustomTextField
              label={
                <>
                  Time Slot<span style={{ color: 'red' }}>*</span>
                </>
              }
              {...register('timeSlot')}
            />
            {errors.timeSlot && (
              <Typography
                mt='4px'
                ml={2}
                sx={{ color: '#F90000' }}
                className='poppins-error-font'
              >
                {errors.timeSlot.message}
              </Typography>
            )}
          </Box>
          <Button
            variant='outlined'
            className='poppins-font'
            onClick={handleOpenModal}
            sx={{
              ...commonBtn,
            }}
          >
            + Time Slot
          </Button>
        </Box>
        <Box my={4.5} sx={{ display: 'flex', gap: 3, alignItems: 'center' }}>
          <Typography
            className='poppins-font'
            sx={{
              fontSize: '14px',
              fontFamily: 'Poppins, sans-serif',
              fontWeight: 500,
            }}
          >
            Add Destination client port protection
          </Typography>
          <Button
            variant='outlined'
            className='poppins-font'
            sx={{
              ...commonBtn,
              minWidth: 180,
              minHeight: 55,
            }}
            startIcon={<AddIcon />}
          >
            Add New
          </Button>
        </Box>
        <Box mt={2}>
          <Button
            className='poppins-font'
            sx={submitBtn}
            disabled={formNumber < 2}
            type='submit'
          >
            Proceed
          </Button>
        </Box>
      </Box>
      <TimeSlotModal open={isModalOpen} handleClose={handleCloseModal} />
    </form>
  );
};

export default OtnDestinationInfo;
