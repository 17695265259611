import { call, put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import {
  fetchPostsStart,
  fetchPostsSuccess,
  fetchPostsFailure,
} from '../slice/postsSlice';

// Define the type of the data expected from the API
interface Post {
  userId: number;
  id: number;
  title: string;
  body: string;
}

// API function to fetch posts
async function fetchPostsAPI(): Promise<Post[]> {
  let limit = 20;
  const response = await axios.get<Post[]>(
    `https://jsonplaceholder.typicode.com/posts?_limit=${limit}`
  );
  return response.data;
}

// Saga function to handle the fetching process
function* fetchPosts(): Generator<unknown, void, Post[]> {
  try {
    const posts: Post[] = yield call(fetchPostsAPI);
    yield put(fetchPostsSuccess(posts));
  } catch (error) {
    yield put(fetchPostsFailure('Failed to fetch posts'));
  }
}

// Watcher saga to monitor the action type
export default function* postsSaga() {
  yield takeLatest(fetchPostsStart.type, fetchPosts);
}
