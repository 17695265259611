import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import customTheme from '../../themes/customTheme';
import { ThemeProvider } from '@mui/material';
import React from 'react';
import { poppinsFormText } from '../../themes/summaryStyle';

interface CustomTextFieldProps {
  label: any;
  required?: boolean;
  value?: string;
  onChange: any;
  name: string;
  width?: { md: string; sm: string; xs: string } | string;
  type?: string;
  error?: boolean;
  helperText?: any;
}

const CustomTextField = React.forwardRef(
  (props: CustomTextFieldProps, ref: any) => {
    const {
      label,
      required,
      value,
      onChange,
      name,
      width,
      type,
      error,
      helperText,
    } = props;

    return (
      <ThemeProvider theme={customTheme}>
        <Box>
          <TextField
            {...props}
            required={required}
            sx={{
              width: width || { md: '496px', sm: '200px', xs: '150px' },
              height: '49px',
              '& label.Mui-focused': {
                ...poppinsFormText,
              },
              '& .MuiInputLabel-root': {
                ...poppinsFormText,
              },
              // "& css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input":{
              //   height:"1em"
              // },
              '& .MuiOutlinedInput-root': {
                height: '49px',
                '& fieldset': {
                  borderColor: '#D9E1E7', // Default border color
                },
                '&:hover fieldset': {
                  borderColor: '#00F', // Border color on hover
                },
                '&:hover': {
                  backgroundColor: 'rgba(220, 226, 255, 0.5)', // Semi-transparent background
                },
                '& input': {
                  color: '#000', // Ensure font color is black
                },
                '& .MuiFormHelperText-root': {
                  color: '#000', // Ensure helper text color is black
                },
              },
            }}
            label={label}
            variant="outlined"
            value={value}
            ref={ref}
            // name={name}
            error={error}
            helperText={helperText}
            type={type || 'string'}
            // onChange={onChange}
            InputLabelProps={{ shrink: true }}
          />
        </Box>
      </ThemeProvider>
    );
  }
);

export default CustomTextField;
