import React, { useState } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import circuitIcon from '../../../assets/svg/circuit-icon.svg';
import StyledSelect from '../../../components/StyledSelect/StyledSelect';

interface OtnGmplsSourceFormProps {
  setOpen: (open: boolean) => void;
}

const OtnGmplsSourceForm: React.FC<OtnGmplsSourceFormProps> = ({ setOpen }) => {
  const [formValues, setFormValues] = useState({
    sourceTrafficType: 'VDH',
    nodeIpAddress: '10.10.10.10',
    selectPort: 'Port_1234',
    timeSlot: '05',
  });

  const handleSelectChange = (field: string) => (event: any) => {
    setFormValues({ ...formValues, [field]: event.target.value as string });
  };

  const sourceTrafficTypeOptions = [
    { label: 'VDH', value: 'VDH' },
    // Add more options as needed
  ];

  const nodeIpAddressOptions = [
    { label: '10.10.10.10', value: '10.10.10.10' },
    // Add more options as needed
  ];

  const selectPortOptions = [
    { label: 'Port_1234', value: 'Port_1234' },
    // Add more options as needed
  ];

  const timeSlotOptions = [
    { label: '05', value: '05' },
    // Add more options as needed
  ];

  return (
    <Box
      sx={{
        width: '268px',
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ borderBottom: '1px solid #D9E1E7', paddingBlock: '13px' }}
      >
        <Box display="flex" alignItems="center" sx={{ ml: '-1px' }}>
          <IconButton aria-label="circuit info">
            <img src={circuitIcon} alt="circuit icon" />
          </IconButton>
          <Typography className="poppins-error-font">
            Source Information
          </Typography>
        </Box>
        <Box sx={{ mr: '7px' }}>
          <IconButton
            size="small"
            aria-label="close"
            onClick={() => setOpen(false)}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
      <Box sx={{ paddingInline: '14px 17px' }}>
        <Box mt="18px">
          <Typography className="poppins-font-primary" ml={1}>
            Source Traffic Type
          </Typography>
          <StyledSelect
            value={formValues.sourceTrafficType}
            options={sourceTrafficTypeOptions}
            onChange={handleSelectChange('sourceTrafficType')}
            placeholder="Select a source traffic type"
          />
        </Box>
        <Box mt="18px" display="flex" alignItems="center" gap="15px">
          <Typography className="poppins-font-primary" sx={{}}>
            Node
          </Typography>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    height: '15px',
                    width: '15px',
                    '& .MuiSvgIcon-root': { fontSize: '15px' },
                    '&.Mui-checked': {
                      color: 'blue',
                    },
                  }}
                />
              }
              label={
                <Typography
                  className="poppins-font-primary"
                  sx={{ ml: '9px', fontSize: '10px' }}
                >
                  IP
                </Typography>
              }
            />
          </FormGroup>
        </Box>
        <Box mt="18px">
          <Typography className="poppins-font-primary" ml={1}>
            Node IP Address
          </Typography>
          <StyledSelect
            value={formValues.nodeIpAddress}
            options={nodeIpAddressOptions}
            onChange={handleSelectChange('nodeIpAddress')}
            placeholder="Select a node IP address"
          />
        </Box>
        <Box mt="18px">
          <Typography className="poppins-font-primary" ml={1}>
            Select Port
          </Typography>
          <StyledSelect
            value={formValues.selectPort}
            options={selectPortOptions}
            onChange={handleSelectChange('selectPort')}
            placeholder="Select a port"
          />
        </Box>
        <Box mt="18px" pb={3}>
          <Typography className="poppins-font-primary" ml={1}>
            Time Slot
          </Typography>
          <StyledSelect
            value={formValues.timeSlot}
            options={timeSlotOptions}
            onChange={handleSelectChange('timeSlot')}
            placeholder="Select a time slot"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default OtnGmplsSourceForm;
