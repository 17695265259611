import React from "react";
import { Box, TextField, Select, MenuItem, ThemeProvider } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import customTheme from "../../themes/customTheme";

const PhoneNumberInput = ({
  countryCode,
  setCountryCode,
  phoneNumber,
  setPhoneNumber,
}: any) => {
  const countryCodes = ["+91", "+01", "+44", "+61"]; // Add more country codes as needed

  const handleCountryCodeChange = (event: any) => {
    setCountryCode(event.target.value);
  };

  const handlePhoneNumberChange = (event: any) => {
    setPhoneNumber(event.target.value);
  };

  return (
    <ThemeProvider theme={customTheme}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {/* <FormControl>
          <Select
            value={countryCode}
            variant="outlined"
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            onChange={handleCountryCodeChange}
            sx={{
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
            }}
          >
            {countryCodes.map((code) => (
              <MenuItem key={code} value={code}>
                {code}
              </MenuItem>
            ))}
          </Select>
        </FormControl> */}
        <TextField
          variant="outlined"
          value={phoneNumber}
          label="Phone Number"
          type="number"
          onChange={handlePhoneNumberChange}
          sx={{
            width: { md: "412px", sm: "200px", xs: "150px" },
            color: "black",
            "& .MuiOutlinedInput-root": {
              padding: 0,
              "&.Mui-focused fieldset": {
                borderColor: "#0000FF",
                borderWidth: "2px",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "#00F", // Change border to red on hover
                backgroundColor: "rgba(220, 226, 255, 0.5)", // Semi-transparent background
              },
            },
          }}
          InputProps={{
            startAdornment: (
              <Select
                value={countryCode}
                variant="outlined"
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                onChange={handleCountryCodeChange}
                sx={{
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                  borderRadius: 0,
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                  },
                  "& .MuiOutlinedInput-root": {
                    border: "none",
                  },
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      border: "none", // Border none on focus
                    },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: "none", // Border none on active
                  },
                  "&:active .MuiOutlinedInput-notchedOutline": {
                    border: "none", // Border none on active
                  },
                  "&:hover ": {
                    border: "none",
                  },
                  mr: 0.5,
                  borderRight: "1px solid #D9E1E7",
                }}
                IconComponent={KeyboardArrowDownIcon}
                MenuProps={{
                  disableScrollLock: true,
                }}
              >
                {countryCodes.map((code) => (
                  <MenuItem key={code} value={code}>
                    {code}
                  </MenuItem>
                ))}
              </Select>
            ),
          }}
          InputLabelProps={{
            sx: {
              background:"white",
              height:"20px",
              zIndex: 5,
              "&.Mui-focused": {
                color: "#000", // Label color when focused
              },
              "&.MuiFormLabel-filled": {
                color: "black", // Label color when the input has a value
              },
            },
          }}
        />
      </Box>
    </ThemeProvider>
  );
};

export default PhoneNumberInput;
