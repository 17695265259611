import { useRoutes } from 'react-router-dom';

import WSON from '../pages/P2P/WSON';
import SDH from '../pages/P2P/SDH';
import OtnGmpls from '../pages/P2P/OtnGmpls';
import OTN from '../pages/P2P/OTN';
import DWDM from '../pages/P2P/DWDM';

import OtnGmplsCircuitCreation from '../pages/CircuitCreation/OtnGmplsCircuitCreation';
import SdhCircuitCreation from '../pages/CircuitCreation/SdhCircuitCreation';
import OtnCircuitCreation from '../pages/CircuitCreation/OtnCircuitCreation';
import DwdmCircuitCreation from '../pages/CircuitCreation/DwdmCircuitCreation';

import OtnGmplsSummary from '../forms/p2p/otnGmpls/OtnGmplsSummary';
import OtnSummary from '../forms/p2p/Otn/OtnSummary';
import DwdmSummary from '../forms/p2p/Dwdm/DwdmSummary';
import SdhSummary from '../forms/p2p/Sdh/SdhSummary';

import Layout from './Layout';
import MEPOTN from '../pages/MEP/MEPOTN';
import MepOtnSummary from '../forms/mep/Otn/MepOtnSummary';
import MepOtnCircuitCreation from '../pages/CircuitCreation/MepOtnCircuitCreation';
import WsonSummary from '../forms/p2p/WSON/WsonSummary';
import WsonCircuitCreation from '../pages/CircuitCreation/WsonCircuitCreation';

const AppRoutes = () => {
  const routes = useRoutes([
    {
      path: '/',
      element: <Layout />,
      children: [
        { path: '/', element: <OtnGmpls /> },
        {
          path: '/p2p/otn-gmpls',
          element: <OtnGmpls />,
        },
        {
          path: '/p2p/otn-gmpls/summary',
          element: <OtnGmplsSummary />,
        },
        { path: 'p2p/otn', element: <OTN /> },
        {
          path: '/p2p/otn/summary',
          element: <OtnSummary />,
        },
        { path: 'p2p/wson', element: <WSON /> },
        { path: 'p2p/sdh', element: <SDH /> },
        { path: 'p2p/sdh/summary', element: <SdhSummary /> },
        { path: 'p2p/dwdm', element: <DWDM /> },
        {
          path: '/p2p/dwdm/summary',
          element: <DwdmSummary />,
        },

        //MEP
        {
          path: 'mep/otn',
          element: <MEPOTN />,
        },
        {
          path: '/mep/otn/summary',
          element: <MepOtnSummary />,
        },
        //Page not found
        { path: '*', element: <h1>Page not found</h1> },
        {
          path: '/p2p/wson/summary',
          element: <WsonSummary />,
        },
      ],
    },
    {
      path: '/p2p/otn-gmpls/circuit-creation',
      element: <OtnGmplsCircuitCreation />,
    },
    {
      path: '/p2p/otn/circuit-creation',
      element: <OtnCircuitCreation />,
    },
    {
      path: '/p2p/dwdm/circuit-creation',
      element: <DwdmCircuitCreation />,
    },
    {
      path: '/p2p/sdh/circuit-creation',
      element: <SdhCircuitCreation />,
    },
    {
      path: '/mep/otn/circuit-creation',
      element: <MepOtnCircuitCreation />,
    },
    {
      path: '/p2p/wson/circuit-creation',
      element: <WsonCircuitCreation />,
    },
  ]);

  return routes;
};
export default AppRoutes;
