import React from "react";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  ThemeProvider,
} from "@mui/material";
import customTheme from "../../themes/customTheme";
import { poppinsFormText } from "../../themes/summaryStyle";

interface CustomMultiSelectProps {
  label: any;
  options: { value: string; label: string }[];
  onChange: any;
  value?: string[]; // Adjusted for multiple values
  required?: boolean;
  name: string;
  width?: { md: string; sm: string; xs: string }; // Add width property
  error?: boolean; // Add error prop
  helperText?: any;
  borderRadius?: string;
}

const CustomMultiSelect = React.forwardRef(
  (props: CustomMultiSelectProps, ref: any) => {
    const {
      label,
      options,
      onChange,
      value,
      required,
      name,
      width,
      borderRadius,
      error,
    } = props;

    return (
      <ThemeProvider theme={customTheme}>
        <FormControl
          {...props}
          sx={{
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused fieldset": {
                borderColor: "#0000FF",
                borderWidth: "2px",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "#00F", // Change border to red on hover
                backgroundColor: "rgba(220, 226, 255, 0.5)", // Semi-transparent background
              },
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "2px solid #D9E1E7",
            },
            "& label.Mui-focused": {
              ...poppinsFormText,
            },
          }}
          variant="outlined"
          error={error}
          required={required}
        >
          <InputLabel id="demo-multiple-select-label">{label}</InputLabel>
          <Select
            {...props}
            labelId="demo-multiple-select-label"
            id="demo-multiple-select"
            multiple
            value={value || []}
            onChange={onChange}
            label={label}
            ref={ref}
            sx={{
              height: "49px",
              borderRadius: borderRadius ? borderRadius : 3,
              width: width || { md: "490px", sm: "200px", xs: "150px" },
            }}
            name={name}
            MenuProps={{
              disableScrollLock: true,
            }}
          >
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </ThemeProvider>
    );
  }
);

export default CustomMultiSelect;
