import React, { useEffect, useRef } from 'react';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  IconButton,
  Typography,
} from '@mui/material';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import info from '../../assets/svg/info-svg.svg';
import { styled } from '@mui/system';

import { useStepContext } from '../../contexts/StepProviderContext';
import { circuitHeading, circuitSubtext } from '../../themes/summaryStyle';

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  borderRadius: '15px',
  border: '1px solid #E7E7F1',
  background: '#FFF',
  boxShadow: '0px 2px 6px 4px rgba(0, 0, 0, 0.03)',
  padding: '13px 15px',
  '&.Mui-expanded': {
    margin: '1.2rem 0',
  },
  '&:not(.Mui-expanded)': {
    margin: '1.2rem 0',
  },
  '&::before': {
    display: 'none',
  },
}));

interface ContentWithOnNextProps {
  onNext: () => void;
}

interface AccordionContent {
  title: string;
  description: string;
  content: React.ReactElement<ContentWithOnNextProps>;
}

interface CommonAccordionProps {
  accordions: AccordionContent[];
}

const CommonAccordion: React.FC<CommonAccordionProps> = ({ accordions }) => {
  const [expanded, setExpanded] = React.useState<string[]>([
    accordions[0].title,
  ]);

  const { activeStep, setActiveStep, clickedStep, setClickedStep } =
    useStepContext();
  const accordionRefs = useRef<(HTMLDivElement | null)[]>([]);

  const handleToggle =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded((prevExpanded) =>
        isExpanded
          ? [...prevExpanded, panel]
          : prevExpanded.filter((expandedPanel) => expandedPanel !== panel)
      );
    };

  const handleNextAccordion = (currentPanel: string) => {
    const currentIndex = accordions.findIndex(
      (accordion) => accordion.title === currentPanel
    );
    if (currentIndex < accordions.length - 1) {
      const nextPanel = accordions[currentIndex + 1].title;
      setExpanded((prevExpanded) => [...prevExpanded, nextPanel]);
      setTimeout(() => {
        const element = document.getElementById(`accId-${clickedStep + 1}`);
        if (element) {
          element.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
          // window.scrollBy(0, -100); // Adjust this value to set the desired margin from the top
        }
      }, 0);
    }
    if (currentIndex === 0) {
      setActiveStep(1);
      setClickedStep(2);
    } else if (currentIndex === 1) {
      setActiveStep(2);
      setClickedStep(3);
    } else if (currentIndex === 2) {
      setActiveStep(3);
      setClickedStep(4);
    } else if (currentIndex === 3) {
      setActiveStep(4);
      setClickedStep(5);
    }
  };

  const handleInfoClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation(); // Prevents the click event from propagating to the AccordionSummary
    alert('Info icon clicked!');
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    const scrollToElement = () => {
      const element = document.getElementById(`accId-${clickedStep - 1}`);
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
      }
    };

    const timeoutId = setTimeout(scrollToElement, 100); // Adding a delay of 100ms

    return () => clearTimeout(timeoutId);
  }, [activeStep, clickedStep]);

  // useEffect(() => {
  //   const element = document.getElementById(`accId+${activeStep - 1}`);
  //   console.log('ele', activeStep, element);
  //   if (element) {
  //     element.scrollIntoView({
  //       behavior: 'smooth',
  //       block: 'start',
  //     });
  //   }
  // }, [activeStep]);

  return (
    <div id='parent'>
      {accordions.map((accordion, index) => (
        <StyledAccordion
          id={`accId-${index}`}
          key={accordion.title}
          ref={(el) => (accordionRefs.current[index] = el)}
          expanded={expanded.includes(accordion.title)}
          onChange={handleToggle(accordion.title)}
          disableGutters
          square
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ fontSize: '2rem' }} />}
            aria-controls={`${accordion.title}-content`}
            id={`${accordion.title}-header`}
            sx={{ alignItems: 'start' }}
          >
            <Box
              display='flex'
              alignItems='start'
              justifyContent='space-between'
              width='100%'
            >
              <Box>
                <Typography sx={circuitHeading}>{accordion.title}</Typography>
                <Typography sx={{ ...circuitSubtext, marginTop: '6px' }}>
                  {accordion.description}
                </Typography>
              </Box>
              <Box display='flex' alignItems='center'>
                <IconButton
                  aria-label='info'
                  onClick={handleInfoClick}
                  sx={{ marginRight: '30px', marginTop: '-19px' }}
                >
                  <img
                    src={info}
                    style={{ width: '32px', height: '32px' }}
                    alt='info'
                  />
                </IconButton>
              </Box>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            {React.isValidElement(accordion.content) &&
              React.cloneElement(accordion.content, {
                onNext: () => handleNextAccordion(accordion.title),
              })}
          </AccordionDetails>
        </StyledAccordion>
      ))}
    </div>
  );
};

export default CommonAccordion;
