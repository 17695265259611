import React from 'react';
import CommonAccordion from '../../components/accordion/CommonAccordion';
import WsonBasicCircuit from '../../forms/p2p/WSON/WsonBasicCircuit';
import WsonSourceInfo from '../../forms/p2p/WSON/WsonSourceInfo';
import WsonConstraints from '../../forms/p2p/WSON/WsonConstraints';
import WsonDestinationInfo from '../../forms/p2p/WSON/WsonDestinationInfo';

const WSON: React.FC = () => {
  const accordions = [
    {
      title: 'Circuit Primitive Information',
      description:
        'Please enter a few details below Listed Mandatory Fields, Need More Please filter From advance',
      content: <WsonBasicCircuit onNext={() => {}} />,
    },
    {
      title: 'Source Information',
      description: 'Please enter a few details below Listed Mandatory Fields.',
      content: <WsonSourceInfo onNext={() => {}} />,
    },
    {
      title: 'Destination Information',
      description: 'Please enter a few details below Listed Mandatory Fields.',
      content: <WsonDestinationInfo onNext={() => {}} />,
    },
    {
      title: 'WSON Constraints',
      description: 'Please enter a few details below Listed Mandatory Fields. ',
      content: <WsonConstraints onNext={() => {}} />,
    },
  ];
  return (
    <div>
      <CommonAccordion accordions={accordions} />
    </div>
  );
};

export default WSON;
