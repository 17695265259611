import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { styled, alpha } from '@mui/material/styles';
import {
  AppBar,
  Box,
  Toolbar,
  InputBase,
  Typography,
  SelectChangeEvent,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import CommonDropdown from '../dropDown/CommonDropdown';
import settings from '../../assets/images/settings.svg';
import Setting from '../Setting/Setting';

import { useCircuitContext } from '../../contexts/CircuitContext';
import { useStepContext } from '../../contexts/StepProviderContext';
import NmsCircuitModal from '../modal/NmsCircuitModal';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: '50px',
  backgroundColor: '#FFFFFF',
  border: '1px solid #D9E1E7',
  '&:hover': {
    backgroundColor: alpha('#FFFFFF', 0.25),
  },
  marginLeft: 0,
  width: '438px',
  height: '39px',
  [theme.breakpoints.up('lg')]: {
    marginLeft: theme.spacing(3),
  },
  [theme.breakpoints.up('sm')]: {
    width: '250px',
    [theme.breakpoints.up('lg')]: {
      width: '438px', // Adjust width for medium devices within small and up
    },
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '39px',
  position: 'absolute',
  right: '0',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: '#F1F1F1',
  color: '#707070',
  borderTopRightRadius: '50px',
  borderBottomRightRadius: '50px',
  cursor: 'pointer',
  width: '45px',
  [theme.breakpoints.up('lg')]: {
    width: '62px',
  },
  [theme.breakpoints.up('md')]: {
    width: '60px',
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing('5px 19px'),
    // vertical padding + font size from searchIcon
    paddingRight: `calc(1em + ${theme.spacing(3)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    color: 'black',
    [theme.breakpoints.up('lg')]: {
      // paddingRight: `calc(5em + ${theme.spacing(3)})`,
      width: '330px',
    },
    '&::placeholder': {
      color: '#707070',
      fontFamily: 'Poppins, sans-serif',
      fontSize: '12px',
      fontWeight: 400,
      opacity: 1, // Ensure opacity is set to 1 for no blur effect
      filter: 'none',
    },
  },
}));

export default function Navbar() {
  // const [selectedValue, setSelectedValue] = React.useState('p2p');
  // const [subTypeSelect, setSubTypeSelect] = React.useState('otn-gmpls');
  const { setActiveStep, setFormNumber } = useStepContext();
  const { selectedValue, subTypeSelect, setSelectedValue, setSubTypeSelect } =
    useCircuitContext();
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);
  const [OpenNmsCircuitModal, setOpenNmsCircuitModal] =
    useState<boolean>(false);
  const [searchCircuit, setSearchCircuit] = useState<string>('');

  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const updateURL = (
    circuitType: string,
    subType: string,
    queryParams: Record<string, string> = {}
  ) => {
    // Create URLSearchParams object
    const searchParams = new URLSearchParams(queryParams);

    const newURL = `/${circuitType}/${subType}?${searchParams.toString()}`;

    // Navigate to the new URL
    navigate(newURL);

    // Return the constructed URL
    return newURL;
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleChange = (event: SelectChangeEvent) => {
    const value = event.target.value as string;
    setSelectedValue(value);
    setActiveStep(0);
    setFormNumber(0);
    if (value === 'mep') {
      updateURL(value, 'otn');
    } else {
      updateURL(value, subTypeSelect);
    }
  };

  const subTypeHandleChange = (event: SelectChangeEvent) => {
    const value = event.target.value as string;
    setSubTypeSelect(value);
    setActiveStep(0);
    setFormNumber(0);
    updateURL(selectedValue, value);
  };

  const options = [
    { value: 'p2p', label: 'Point to Point' },
    { value: 'mep', label: 'MEP' },
  ];

  const options_subType = [
    { value: 'dwdm', label: 'DWDM' },
    { value: 'wson', label: 'WSON' },
    { value: 'otn', label: 'OTN' },
    { value: 'otn-gmpls', label: 'OTN-GMPLS' },
    { value: 'sdh', label: 'SDH' },
  ];

  const options_subType_mep = [{ value: 'otn', label: 'OTN' }];

  const handleInputClick = () => {
    setDropdownVisible(true);
  };

  const handleOpenNmsCircuitModal = () => {
    setOpenNmsCircuitModal(true);
  };

  const handleOutsideClick = (e: MouseEvent) => {
    const target = e.target as HTMLElement;
    if (!target.closest('.search-dropdown')) {
      setDropdownVisible(false);
    }
  };

  useEffect(() => {
    const path = location.pathname.split('/');
    if (path.length >= 3) {
      setSelectedValue(path[1]);
      setSubTypeSelect(path[2]);
    } else {
      navigate('/p2p/otn-gmpls');
    }

    // Add event listener for closing dropdown on outside click
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, navigate]);

  // useEffect(() => {
  //   // Check if the current path is the root path
  //   if (window.location.pathname === '/') {
  //     // Set the URL to the default path
  //     window.history.replaceState({}, '', '/p2p/otn-gmpls');
  //   }

  //   // Get the path from the URL and remove leading slash
  //   const path = window.location.pathname.substring(1).split('/');
  //   const [circuitType, subType] = path;

  //   // Set the state with URL path or default values if they are not valid
  //   setSelectedValue(circuitType || 'p2p');
  //   setSubTypeSelect(subType || 'otn-gmpls');
  // }, [selectedValue, subTypeSelect]);

  useEffect(() => {
    const path = location.pathname.split('/');
    if (path.length >= 3) {
      if (path[1] === 'p2p') {
        setSelectedValue(path[1]);
        setSubTypeSelect(path[2]);
      } else if (path[1] === 'mep') {
        setSelectedValue(path[1]);
        setSubTypeSelect('otn');
        // updateURL('mep', 'otn');
      }
    } else {
      navigate('/p2p/otn-gmpls');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, navigate]);

  const [customerData, setCustomerData] = useState({
    customerName: '',
    email: '',
    contactPerson: '',
    PhoneNumber: '',
    address: '',
    city: '',
    country: '',
    fax: '',
  });

  // const [isModalOpen, setIsModalOpen] = useState(false);

  // const handleChangeForCustomer = (e: any) => {
  //   const { name, value } = e.target;
  //   setCustomerData((prevData) => ({
  //     ...prevData,
  //     [name]: value,
  //   }));
  // };

  // ================ Circuit Name data =====================//

  const nmsCircuits = Array.from({ length: 10 }, (_, index) => ({
    id: 21 + index,
    name: `NMS Circuit ${21 + index}`,
  }));

  const filteredCircuits = nmsCircuits.filter(
    (circuit: { id: number; name: string }) =>
      circuit?.name.toLowerCase().includes(searchCircuit.toLowerCase())
  );

  return (
    <Box>
      <AppBar
        position='static'
        sx={{
          background: '#FFF',
          boxShadow: 'none',
          border: '1px solid #D8D8D8',
          display: 'flex',
          justifyContent: 'center',
          margin: 'auto',
        }}
      >
        <Box>
          <Toolbar
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              height: '80px',
              padding: { xs: '30px 30px 26px 20px', lg: '30px 67px 26px 40px' },
              maxWidth: '1600px',
              margin: 'auto',
            }}
          >
            <Search onClick={handleInputClick} className='search-dropdown'>
              <SearchIconWrapper>
                <SearchIcon
                  sx={{ height: '20px', width: '20px', color: '707070' }}
                />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder='Enter Circuit Name...'
                onChange={(e: any) => setSearchCircuit(e.target.value)}
                value={searchCircuit}
                inputProps={{ 'aria-label': 'search' }}
              />
              {dropdownVisible && (
                <Box sx={{ position: 'absolute', top: '50px', left: 0 }}>
                  <Box
                    sx={{
                      maxHeight: '500px',
                      overflowY: 'scroll',
                      zIndex: '10000',
                      background: '#fff',
                      boxShadow: '1px 5px 5px 5px #8884',
                      width: '380px',
                      '&::-webkit-scrollbar': {
                        width: '5px',
                      },
                      '&::-webkit-scrollbar-thumb': {
                        backgroundColor: '#809FB8',
                        borderRadius: '10px',
                      },
                      '&::-webkit-scrollbar-thumb:hover': {
                        backgroundColor: '#555',
                      },
                    }}
                  >
                    {filteredCircuits?.map((item) => (
                      <Box
                        key={item?.id}
                        onClick={() => {
                          handleOpenNmsCircuitModal();
                          setSearchCircuit(item?.name);
                        }}
                      >
                        <Typography
                          sx={{
                            padding: '20px',
                            color: 'black',
                            cursor: 'pointer',
                            '&:hover': {
                              backgroundColor: 'rgba(173, 216, 230, 0.3)', // Light blue transparent
                              width: '100%', // Ensures the background covers the full text width
                            },
                          }}
                        >
                          {item?.name}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Box>
              )}
            </Search>
            <Box
              display='flex'
              alignItems='center'
              justifyContent='space-around'
              gap={{ sm: '20px', lg: '38px' }}
              sx={{ paddingLeft: { xs: '10px', md: '0' } }}
            >
              <Box
                sx={{
                  display: { xs: 'none', sm: 'flex' },
                  alignItems: 'center',
                }}
              >
                <Typography
                  sx={{
                    color: '#06152B',
                    fontFamily: 'Poppins, sans-serif',
                    fontSize: { xs: '10px', md: '14px' },
                    fontWeight: 400,
                    marginRight: { xs: '.4rem', md: '.8rem' },
                  }}
                >
                  Circuit type
                </Typography>
                <CommonDropdown
                  label='Circuit type'
                  options={options}
                  selectedValue={selectedValue}
                  onChange={handleChange}
                  width='142px'
                />{' '}
              </Box>
              <Box
                sx={{
                  display: { xs: 'none', sm: 'flex' },
                  alignItems: 'center',
                }}
              >
                <Typography
                  sx={{
                    color: ' #06152B',
                    fontFamily: 'Poppins, sans-serif',
                    fontSize: { xs: '10px', md: '14px' },
                    fontWeight: 400,
                    marginRight: { xs: '.4rem', md: '.8rem' },
                  }}
                >
                  Sub-type
                </Typography>
                <CommonDropdown
                  label='Sub-type'
                  options={
                    selectedValue === 'mep'
                      ? options_subType_mep
                      : options_subType
                  }
                  selectedValue={subTypeSelect}
                  onChange={subTypeHandleChange}
                  width='169px'
                />
              </Box>
              <img
                src={settings}
                style={{
                  cursor: 'pointer',
                  width: '32px',
                  height: '32px',
                }}
                alt='Setting icon'
                onClick={handleClickOpen}
              />
            </Box>
          </Toolbar>
        </Box>
      </AppBar>
      <Setting open={open} handleClose={handleClose} />
      <NmsCircuitModal
        open={OpenNmsCircuitModal}
        handleClose={() => {
          setOpenNmsCircuitModal(false);
          setSearchCircuit('');
        }}
        customerData={customerData}
        modalHeading={searchCircuit}
      />
    </Box>
  );
}
