import React, { useState } from 'react';
import { Box, Typography, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import circuitIcon from '../../../assets/svg/circuit-icon.svg';

import StyledSelect from '../../../components/StyledSelect/StyledSelect';
import filter from '../../../assets/svg/Filter.svg';
interface OtnGmplsCircuitTypeProps {
  setOpen: (open: boolean) => void;
}
const DwdmCircuitType = (props: OtnGmplsCircuitTypeProps) => {
  const { setOpen } = props;
  const [formValues, setFormValues] = useState({
    circuitName: 'Default Circuit',
    customerName: 'automation',
    copies: '02',
    classOfService: 'UN-Protected',
  });

  const handleSelectChange = (field: string) => (event: any) => {
    setFormValues({ ...formValues, [field]: event.target.value });
  };
  const circuitOptions = [
    { label: 'Default Circuit', value: 'Default Circuit' },
    // Add more options as needed
  ];

  const customerOptions = [
    { label: 'automation', value: 'automation' },
    // Add more options as needed
  ];

  // const capacityOptions = [
  //   { label: 'VC12', value: 'VC12' },
  //   // Add more options as needed
  // ];

  const copiesOptions = [
    { label: '02', value: '02' },
    // Add more options as needed
  ];

  const classOfServiceOptions = [
    { label: 'UN-Protected', value: 'UN-Protected' },
    // Add more options as needed
  ];

  return (
    <Box
      sx={{
        width: '268px',
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ borderBottom: '1px solid #D9E1E7', paddingBlock: '13px' }}
      >
        <Box display="flex" alignItems="center" sx={{ ml: '-1px' }}>
          <IconButton aria-label="circuit info">
            <img src={circuitIcon} alt="circuit icon" />
          </IconButton>
          <Typography className="poppins-error-font">Circuit type</Typography>
        </Box>
        <Box sx={{ mr: '7px' }}>
          <IconButton
            size="small"
            aria-label="close"
            onClick={() => {
              setOpen(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
      <Box sx={{ paddingInline: '14px 17px' }}>
        <Box mt="18px">
          <Typography className="poppins-font-primary" ml="11px">
            Circuit Name
          </Typography>
          <StyledSelect
            value={formValues.circuitName}
            options={circuitOptions}
            onChange={handleSelectChange('circuitName')}
            placeholder="Select a circuit"
          />
        </Box>
        <Box mt="18px">
          <Typography className="poppins-font-primary" ml="11px">
            Customer Name
          </Typography>
          <StyledSelect
            value={formValues.customerName}
            options={customerOptions}
            onChange={handleSelectChange('customerName')}
            placeholder="Select a customer"
          />
        </Box>

        <Box mt="18px">
          <Typography className="poppins-font-primary" ml="11px">
            Copies
          </Typography>
          <StyledSelect
            value={formValues.copies}
            options={copiesOptions}
            onChange={handleSelectChange('copies')}
            placeholder="Select copies"
          />
        </Box>
        <Box mt="18px">
          <Typography className="poppins-font-primary" ml="11px">
            Class of Service
          </Typography>
          <StyledSelect
            value={formValues.classOfService}
            options={classOfServiceOptions}
            onChange={handleSelectChange('classOfService')}
            placeholder="Select class of service"
          />
        </Box>
        <Box mt="18px">
          <Button
            className="poppins-font-secondary"
            endIcon={<img src={filter} alt="filter" />}
            sx={{
              backgroundColor: '#EEEEEE',
              color: '#06152B',
              textTransform: 'none',
              borderRadius: '5px',
              width: '237px',
              maxHeight: '40px',
              marginBottom: '20px',
              border: '1px solid #D9E1E7',
            }}
            variant="outlined"
          >
            Advanced Config
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default DwdmCircuitType;
