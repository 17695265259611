import React from "react";
import { Box, Grid } from "@mui/material";
import ReadonlyTextField from "../CustomTextField/ReadonlyTextField";

interface CustomerData {
  circuitName: string;
  customerName: string;
  capacity: string;
  copies: string;
  classOfService: string;
}

interface DwdmBasicCircuitReadonlyProps {
  data: CustomerData;
}

const DwdmBasicCircuitReadonly: React.FC<DwdmBasicCircuitReadonlyProps> = ({
  data,
}) => {
  return (
    <Box
      sx={{
        paddingLeft: "3rem",
      }}
    >
      <Grid container spacing={4}>
        {/* Row 1: Circuit Name and Customer Name */}
        <Grid item xs={12} sm={6}>
          <ReadonlyTextField
            width="100%"
            label="Circuit Name"
            value={data.circuitName}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ReadonlyTextField
            width="100%"
            label="Customer Name"
            value={data.customerName}
          />
        </Grid>

        {/* Row 2: Capacity and Copies */}
        <Grid item xs={12} sm={6}>
          <ReadonlyTextField
            width="100%"
            label="Capacity"
            value={data.capacity}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ReadonlyTextField width="100%" label="Copies" value={data.copies} />
        </Grid>

        {/* Row 3: Class of Service */}
        <Grid item xs={12} sm={6}>
          <ReadonlyTextField
            width="100%"
            label="Class of Service"
            value={data.classOfService}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default DwdmBasicCircuitReadonly;
