import React from "react";
import { Button, ButtonProps, styled } from "@mui/material";
import GridViewIcon from "@mui/icons-material/GridView";

// Define custom props extending ButtonProps from MUI
interface CustomButtonProps extends ButtonProps {
  variantType?: "primary" | "secondary" | "default" | "disabled";
  padding?: string;
  bg?: string;
  width?: string;
}

const StyledButton = styled(Button)<{ padding?: string; bg?: string; width?: string; }>(
  ({ padding, bg, width, theme }) => ({
    borderRadius: '10px',
    textTransform: "none",
    minWidth: "50px",
    fontFamily: "Poppins, sans-serif",
    fontStyle: "normal",
    fontWeight: 500,
    padding: padding ? padding : "10px 24px", // Use padding prop if provided, otherwise default
    ...(width && {
      [theme.breakpoints.up('lg')]: {
        width: width,
      },
      [theme.breakpoints.down('md')]: {
        width: "auto",
      },
    }),
    "&.MuiButton-containedPrimary": {
      backgroundColor: "#0000FF",
      color: "#ffffff",
      border: "2px solid #00F",
      "&:hover": {
        backgroundColor: "#0000BA",
      },
    },
    "&.MuiButton-containedSecondary": {
      backgroundColor: "#D9E1E7",
      color: "#141414",
      boxShadow: "none",
      "&:hover": {
        backgroundColor: "#D9E1E7",
      },
    },
    "&.MuiButton-outlined": {
      border: bg ? "2px solid #0000FF" : "2px solid #D9E1E7",
      color: bg ? "#0000FF" : "#06152B",
      backgroundColor: bg ? "#F5FBFF" : "none",
      "&:hover": {
        backgroundColor: "rgba(147, 198, 233, 0.1)",
      },
    },
    "&.Mui-disabled": {
      backgroundColor: "#9BABFF",
      color: "#ffffff",
    },
  })
);

const CustomButton: React.FC<CustomButtonProps> = ({
  children,
  variantType = "default",
  ...props
}) => {
  let variant: "contained" | "outlined" | "text" = "contained";
  let color:
    | "primary"
    | "secondary"
    | "inherit"
    | "success"
    | "error"
    | "info"
    | "warning" = "inherit";

  if (variantType === "primary") {
    color = "primary";
  } else if (variantType === "secondary") {
    color = "secondary";
  } else if (variantType === "disabled") {
    props.disabled = true;
  } else {
    variant = "outlined";
    // Choose an appropriate color for default variant
    color = "inherit"; // or 'default', 'info', etc.
  }

  return (
    <StyledButton variant={variant} color={color} {...props}>
      {props.startIcon && <GridViewIcon sx={{ marginRight: ".4rem" }} />}{" "}
      {children}
    </StyledButton>
  );
};

export default CustomButton;