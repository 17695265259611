import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import NmsCircuitForm from "../Readonly/NmsCircuitForm";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

interface NmsCircuitModallProps {
  modalHeading: string;
  open: boolean;
  handleClose: () => void;
  handleChangeForCustomer?: any;
  customerData?: any;
}

const NmsCircuitModal: React.FC<NmsCircuitModallProps> = ({
  open,
  handleClose,
  customerData,
  handleChangeForCustomer,
  modalHeading,
}) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            className="poppins-font-heading"
            sx={{
              color: "#464A53",
            }}
          >
            {modalHeading}
          </Typography>
          <CloseIcon onClick={handleClose} sx={{ cursor: "pointer" }} />
        </Box>

        <Box
          sx={{
            paddingTop: "10px",
            height: "500px",
            overflowY: "scroll",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <NmsCircuitForm closeModal={handleClose} />
        </Box>
      </Box>
    </Modal>
  );
};

export default NmsCircuitModal;
