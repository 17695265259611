import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import customTheme from "../../themes/customTheme";
import { ThemeProvider } from "@mui/material";
import React from "react";
import { poppinsFormText } from "../../themes/summaryStyle";

interface CustomTextFieldProps {
  label: string;
  value: string;
  width?: { md: string; sm: string; xs: string } | string;
}

const ReadonlyTextField = React.forwardRef(
  (props: CustomTextFieldProps, ref: any) => {
    const { label, value, width } = props;

    return (
      <ThemeProvider theme={customTheme}>
        <Box>
          <TextField
            label={label}
            variant="outlined"
            value={value}
            ref={ref}
            InputProps={{
              readOnly: true,
            }}
            sx={{
              width: width || { md: "496px", sm: "200px", xs: "150px" },
              height: "49px",
              "& label.Mui-focused": {
                ...poppinsFormText,
              },
              "& .MuiInputLabel-root": {
                ...poppinsFormText,
              },
              "& .MuiOutlinedInput-root": {
                height: "49px",
                "& fieldset": {
                  borderColor: "#D9E1E7", // Default border color
                },
                "&:hover fieldset": {
                  borderColor: "#00F", // Border color on hover
                },
                "&:hover": {
                  backgroundColor: "rgba(220, 226, 255, 0.5)", // Semi-transparent background
                },
                "& input": {
                  color: "#000", // Ensure font color is black
                },
              },
            }}
            InputLabelProps={{ shrink: true }}
          />
        </Box>
      </ThemeProvider>
    );
  }
);

export default ReadonlyTextField;
