import React, { useState } from "react";

import {
  Box,
  Button,
  FormGroup,
} from "@mui/material";

import AccordionWithFeatures from "../accordion/AccordionWithFeatures";
import DwdmBasicCircuitReadonly from "./DwdmBasicCircuitReadonly";
import DwdmSourceInfoReadonly from "./DwdmSouceInfoReadonly";
import DwdmDestinationInfoReadonly from "./DwdmDestinationInfoReadonly";

const dummyData: any[] = [
  {
    type: "circuit",
    circuitName: "Circuit-1",
    customerName: "John Doe",
    capacity: "10Gbps",
    copies: "2",
    classOfService: "Gold",
  },
  {
    type: "source",
    trafficType: "Ethernet",
    ipAddress: "192.168.1.1",
    port: "8080",
    slot: "1",
  },
  {
    type: "destination",
    destinationTrafficType: "Optical",
    ipAddress: "10.0.0.1",
    port: "9090",
    timeSlot: "2",
  },
];

interface NmsCircuitFormProps {
  closeModal: () => void;
}

const NmsCircuitForm: React.FC<NmsCircuitFormProps> = ({ closeModal }) => {
  const [checkedItems, setCheckedItems] = useState<boolean[]>(
    Array(dummyData.length).fill(false)
  );

  // Handle receiving checked items
  function handleReceiveCheckedItems(updatedCheckedItems: boolean[]) {
    setCheckedItems(updatedCheckedItems);
  }

  // Function to log the checked items from dummyData to console
  function showCheckItems() {
    const filteredData = dummyData.filter((_, index) => checkedItems[index]);

    // Log the filtered data to the console
    // console.log("Checked items:", filteredData);
  }

  // Handle "Select All" checkbox change
  // const handleSelectAllChange = (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   const isChecked = event.target.checked;
  //   setSelectAll(isChecked);
  //   // Update all checkbox states inside the accordions
  //   setCheckedItems(checkedItems.map(() => isChecked));
  // };

  // // Handle individual accordion checkbox change
  // const handleAccordionCheckboxChange = (index: number) => {
  //   const updatedCheckedItems = [...checkedItems];
  //   updatedCheckedItems[index] = !updatedCheckedItems[index];
  //   setCheckedItems(updatedCheckedItems);

  //   // If all are selected after this change, set the 'Select All' checkbox to checked
  //   if (updatedCheckedItems.every((item) => item)) {
  //     setSelectAll(true);
  //   } else {
  //     setSelectAll(false);
  //   }
  // };

  const accordions = [
    {
      title: "Circuit Basic Details",
      description: "",
      content: (
        <DwdmBasicCircuitReadonly
          data={{
            circuitName: dummyData[0].circuitName,
            customerName: dummyData[0].customerName,
            capacity: dummyData[0].capacity,
            copies: dummyData[0].copies,
            classOfService: dummyData[0].classOfService,
          }}
        />
      ),
    },
    {
      title: "Source Information",
      description: "",
      content: (
        <DwdmSourceInfoReadonly
          data={{
            trafficType: dummyData[1].trafficType,
            ipAddress: dummyData[1].ipAddress,
            port: dummyData[1].port,
            slot: dummyData[1].slot,
          }}
        />
      ),
    },
    {
      title: "Destination Information",
      description: "",
      content: (
        <DwdmDestinationInfoReadonly
          data={{
            destinationTrafficType: dummyData[2].destinationTrafficType,
            ipAddress: dummyData[2].ipAddress,
            port: dummyData[2].port,
            timeSlot: dummyData[2].timeSlot,
          }}
        />
      ),
    },
    {
      title: "Constraints & Route Creations",
      description: "",
      content: <></>,
    },
  ];
  return (
    <Box>
      <FormGroup>
        <AccordionWithFeatures
          accordions={accordions}
          checkbox
          sendCheckedItems={handleReceiveCheckedItems}
        />
      </FormGroup>
      <Box sx={{ display: "flex", columnGap: 2, mt: 3 }}>
        <Button
          className="poppins-font"
          onClick={closeModal}
          sx={{
            backgroundColor: "#D9E1E7",
            "&:hover": {
              backgroundColor: "#D9E1E7", // Same background color on hover
            },
            color: "black",
            minWidth: 180,
            minHeight: 60,
            borderRadius: 2,
            textTransform: "none", // To prevent text from being converted to uppercase
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={showCheckItems}
          className="poppins-font"
          sx={{
            backgroundColor: "#00F",
            "&:hover": {
              backgroundColor: "#0000BA",
              borderColor: "#0000BA",
            },
            color: "white",
            minWidth: 180,
            minHeight: 60,
            borderRadius: 2,
            textTransform: "none", // To prevent text from being converted to uppercase
          }}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default NmsCircuitForm;
