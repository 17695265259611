import React from "react";
import { Box, Grid } from "@mui/material";
import ReadonlyTextField from "../CustomTextField/ReadonlyTextField";

interface Data {
  trafficType: string;
  ipAddress: string;
  port: string;
  slot: string;
}

interface DwdmSourceInfoReadonlyProps {
  data: Data;
}

const DwdmSourceInfoReadonly: React.FC<DwdmSourceInfoReadonlyProps> = ({
  data,
}) => {
  return (
    <Box
      sx={{
        paddingLeft: "3rem",
      }}
    >
      <Grid container spacing={4}>
        {/* Row 1: Circuit Name and Customer Name */}
        <Grid item xs={12} sm={6}>
          <ReadonlyTextField
            width="100%"
            label="Circuit Name"
            value={data.trafficType}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ReadonlyTextField
            width="100%"
            label="Customer Name"
            value={data.ipAddress}
          />
        </Grid>

        {/* Row 2: Capacity and Copies */}
        <Grid item xs={12} sm={6}>
          <ReadonlyTextField width="100%" label="Capacity" value={data.port} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ReadonlyTextField width="100%" label="Copies" value={data.slot} />
        </Grid>

        {/* Row 3: Class of Service */}
      </Grid>
    </Box>
  );
};

export default DwdmSourceInfoReadonly;
