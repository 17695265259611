import React, { useState } from 'react';

import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  OutlinedInput,
  RadioGroup,
  Select,
  Typography,
} from '@mui/material';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Radio from '@mui/material/Radio';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import CustomButton from '../../../components/button/CustomButton';
import {
  checkboxStyle,
  OutlinedInputStyle,
  poppinsFormText,
  radioStyle,
  selectFormControlStyle,
} from '../../../themes/summaryStyle';
import {
  checkboxOptions,
  inputFields,
  selectOptions,
} from '../../../constants/AdvSettingData';

interface OtnGmplsAdvSettingProps {
  handleClose: () => void;
  setSelectOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface FormValues {
  autoPlanned: boolean;
  reRouteMax: number;
  reTryInterval: number;
  reTryMax: number;
  workReRouteMax: number;
  protectReRouteMax: number;
  priority: number;
  disjointness: string;
  disjointnessDegree: string;
  timeSlotSelection: string;
  direction: string;
  flexiSupport: boolean;
  spectrumSlots: number;
  reRoute: boolean;
  autoPowerControl: boolean;
  serviceParing: boolean;
  frqSelection: string;
}

const WsonAdvSettings: React.FC<OtnGmplsAdvSettingProps> = ({
  handleClose,
  setSelectOpen,
}) => {
  const [formValues, setFormValues] = useState<FormValues>({
    reRouteMax: 0,
    autoPlanned: false,
    flexiSupport: false,
    reTryInterval: 0,
    reTryMax: 0,
    workReRouteMax: 0,
    protectReRouteMax: 0,
    priority: 0,
    disjointness: '',
    disjointnessDegree: '',
    timeSlotSelection: 'Manual',
    direction: 'Uni-Direction',
    spectrumSlots: 0,
    reRoute: false,
    autoPowerControl: false,
    serviceParing: false,
    frqSelection: 'Manual',
  });

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name!]: value,
    });
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setFormValues({
      ...formValues,
      [name]: checked,
    });
  };

  const handleNumberChange = (name: keyof FormValues, increment: boolean) => {
    setFormValues((prev) => {
      const currentValue = prev[name];
      // Ensure the current value is a number before performing arithmetic
      if (typeof currentValue === 'number') {
        return {
          ...prev,
          [name]: increment ? currentValue + 1 : currentValue - 1,
        };
      }
      return prev;
    });
  };

  const handleInputChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value, type } = event.target;
    const checked = (event.target as HTMLInputElement).checked; // Cast to HTMLInputElement

    setFormValues({
      ...formValues,
      [name]: type === 'checkbox' ? checked : value,
    });
  };
  return (
    <Box
      sx={{
        padding: '1rem 2rem 3rem 3rem',
      }}
    >
      <Grid container display='flex' alignItems='center' spacing={1.5}>
        <Grid item xs={6} md={4}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  name='flexiSupport'
                  checked={formValues.flexiSupport}
                  onChange={handleCheckboxChange}
                  sx={checkboxStyle}
                />
              }
              label='Flexi Grid Support'
              sx={{
                '& .MuiFormControlLabel-label': {
                  ...poppinsFormText,
                },
              }}
            />
          </FormGroup>
        </Grid>

        <Grid item xs={6} md={8} display='flex' alignItems='center' gap='1rem'>
          <Typography sx={{ ...poppinsFormText }}>
            Spectrum Slots(n*6.25Ghz)
          </Typography>
          <FormControl>
            <OutlinedInput
              name='spectrumSlots'
              value={formValues.spectrumSlots}
              onChange={handleChange}
              sx={OutlinedInputStyle}
              type='number'
              endAdornment={
                <Box sx={{ position: 'relative', paddingRight: '40%' }}>
                  <KeyboardArrowUpIcon
                    sx={{
                      height: '20px',
                      position: 'absolute',
                      color: '#707070',
                      right: 0,
                      bottom: '-3px',
                      cursor: 'pointer',
                    }}
                    onClick={() => handleNumberChange('spectrumSlots', true)}
                  />
                  <KeyboardArrowDownIcon
                    sx={{
                      height: '20px',
                      position: 'absolute',
                      color: '#707070',
                      right: 0,
                      top: '-4px',
                      cursor: 'pointer',
                    }}
                    onClick={() => handleNumberChange('spectrumSlots', false)}
                  />
                </Box>
              }
            />
          </FormControl>
        </Grid>

        <Grid item xs={6} md={4}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  name='reRoute'
                  checked={formValues.reRoute}
                  onChange={handleCheckboxChange}
                  sx={checkboxStyle}
                />
              }
              label='Re-Route Option'
              sx={{
                '& .MuiFormControlLabel-label': {
                  ...poppinsFormText,
                },
              }}
            />
          </FormGroup>
        </Grid>

        <Grid item xs={6} md={8} display='flex' alignItems='center' gap='1rem'>
          <Typography sx={poppinsFormText}>Re-route Max</Typography>
          <FormControl>
            <OutlinedInput
              name='reRouteMax'
              value={formValues.reRouteMax}
              onChange={handleChange}
              sx={OutlinedInputStyle}
              type='number'
              endAdornment={
                <Box sx={{ position: 'relative', paddingRight: '40%' }}>
                  <KeyboardArrowUpIcon
                    sx={{
                      height: '20px',
                      position: 'absolute',
                      color: '#707070',
                      right: 0,
                      bottom: '-3px',
                      cursor: 'pointer',
                    }}
                    onClick={() => handleNumberChange('reRouteMax', true)}
                  />
                  <KeyboardArrowDownIcon
                    sx={{
                      height: '20px',
                      position: 'absolute',
                      color: '#707070',
                      right: 0,
                      top: '-4px',
                      cursor: 'pointer',
                    }}
                    onClick={() => handleNumberChange('reRouteMax', false)}
                  />
                </Box>
              }
            />
          </FormControl>
        </Grid>

        {inputFields.map(({ label, name }) => (
          <React.Fragment key={name}>
            <Grid item xs={5} md={3.5}>
              <Typography sx={poppinsFormText}>{label}</Typography>
            </Grid>
            <Grid item xs={7} md={8.5}>
              <FormControl>
                <OutlinedInput
                  name={name}
                  onChange={handleChange}
                  sx={OutlinedInputStyle}
                  type='number'
                  endAdornment={
                    <Box sx={{ position: 'relative', paddingRight: '40%' }}>
                      <KeyboardArrowUpIcon
                        sx={{
                          height: '20px',
                          position: 'absolute',
                          color: '#707070',
                          right: 0,
                          bottom: '-3px',
                          cursor: 'pointer',
                        }}
                      />
                      <KeyboardArrowDownIcon
                        sx={{
                          height: '20px',
                          position: 'absolute',
                          color: '#707070',
                          right: 0,
                          top: '-4px',
                          cursor: 'pointer',
                        }}
                      />
                    </Box>
                  }
                />
              </FormControl>
            </Grid>
          </React.Fragment>
        ))}

        <Grid item xs={12}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  name='autoPowerControl'
                  checked={formValues.autoPlanned}
                  onChange={handleCheckboxChange}
                  sx={checkboxStyle}
                />
              }
              label='Automatic Power control'
              sx={{
                '& .MuiFormControlLabel-label': {
                  ...poppinsFormText,
                  fontWeight: 500,
                },
              }}
            />
          </FormGroup>
        </Grid>

        {selectOptions.map(({ label, name, options }) => (
          <React.Fragment key={name}>
            <Grid item xs={5} md={4}>
              <Typography sx={poppinsFormText}>{label}</Typography>
            </Grid>
            <Grid
              item
              display='flex'
              alignItems='center'
              justifyContent='start'
              gap='.8rem'
              xs={7}
              md={8}
            >
              <FormControl sx={selectFormControlStyle}>
                <Select
                  labelId={`${name}-select-label`}
                  id={`${name}-select`}
                  name={name}
                  onChange={handleChange}
                  IconComponent={KeyboardArrowDownIcon}
                  inputProps={{ 'aria-label': 'Without label' }}
                  sx={{
                    width: '100%',
                    maxWidth: '104px',
                    height: '36px',
                    '& .MuiSelect-select': {
                      color: 'inherit',
                    },
                  }}
                  onOpen={() => setSelectOpen(true)}
                  MenuProps={{
                    disableScrollLock: true,
                    TransitionProps: {
                      onExited: () => setSelectOpen(false),
                    },
                  }}
                >
                  {options.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </React.Fragment>
        ))}
        <Grid item xs={12}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  name='serviceParing'
                  checked={formValues.serviceParing}
                  onChange={handleCheckboxChange}
                  sx={checkboxStyle}
                />
              }
              label='Service Pairing'
              sx={{
                '& .MuiFormControlLabel-label': {
                  ...poppinsFormText,
                  fontWeight: 500,
                },
              }}
            />
          </FormGroup>
        </Grid>

        <Grid item xs={5} md={4}>
          <Typography sx={poppinsFormText}>Disjointness Degree</Typography>
        </Grid>
        <Grid
          item
          display='flex'
          alignItems='center'
          justifyContent='start'
          gap='.8rem'
          xs={7}
          md={8}
        >
          <FormControl sx={selectFormControlStyle}>
            <Select
              labelId='disjointness-degree-select-label'
              id='disjointness-degree-select'
              name='disjointnessDegree'
              value={formValues.disjointnessDegree}
              onChange={handleChange}
              IconComponent={KeyboardArrowDownIcon}
              inputProps={{ 'aria-label': 'Without label' }}
              sx={{
                width: '100%',
                maxWidth: '104px',
                height: '36px',
                '& .MuiSelect-select': {
                  color: 'inherit',
                },
              }}
              onOpen={() => setSelectOpen(true)}
              MenuProps={{
                disableScrollLock: true,
                TransitionProps: {
                  onExited: () => setSelectOpen(false),
                },
              }}
            >
              <MenuItem value='Full'>Full</MenuItem>
              <MenuItem value='Partial'>Partial</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={5} md={4} mt={'17px'}>
          <Typography sx={{ ...poppinsFormText, fontWeight: 600 }}>
            Frequency Selection:
          </Typography>
        </Grid>
        <Grid item xs={7} md={8} mt={'17px'}>
          <FormControl>
            <RadioGroup
              aria-labelledby='time-slot-selection-group'
              name='frqSelection'
              value={formValues.frqSelection}
              onChange={handleInputChange}
              row
              sx={{ gap: { xs: 4, md: 6 } }}
            >
              <FormControlLabel
                value='Manual'
                control={<Radio sx={radioStyle} />}
                label='Manual'
              />
              <FormControlLabel
                value='Auto'
                control={<Radio sx={radioStyle} />}
                label='Auto'
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item xs={5} md={4}>
          <Typography sx={{ ...poppinsFormText, fontWeight: 600 }}>
            Provisioning Selection
          </Typography>
        </Grid>
        <Grid item xs={7} md={8}>
          <FormControl>
            <RadioGroup
              aria-labelledby='provisioning-selection-group'
              name='provisioningSelection'
              onChange={handleInputChange}
              row
              sx={{ gap: { xs: 1, md: 3 } }}
            >
              <FormControlLabel
                value='Frequency'
                control={<Radio sx={radioStyle} />}
                label='Frequency'
              />
              <FormControlLabel
                value='Port'
                control={<Radio sx={radioStyle} />}
                label='Port'
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item xs={5} md={4}>
          <Typography sx={{ ...poppinsFormText, fontWeight: 600 }}>
            Frequency Type{' '}
          </Typography>
        </Grid>
        <Grid item xs={7} md={8}>
          <FormControl>
            <RadioGroup
              aria-labelledby='frequency-type-group'
              name='frequencyType'
              onChange={handleInputChange}
              row
              sx={{ gap: { md: 10.3 } }}
            >
              <FormControlLabel
                value='All'
                control={<Radio sx={radioStyle} />}
                label='All'
              />
              <FormControlLabel
                value='Even'
                control={<Radio sx={radioStyle} />}
                label='Even'
              />
              <FormControlLabel
                value='Odd'
                control={<Radio sx={radioStyle} />}
                label='Odd'
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item xs={5} md={4}>
          <Typography sx={{ ...poppinsFormText, fontWeight: 600 }}>
            Direction:
          </Typography>
        </Grid>
        <Grid item xs={7} md={8}>
          <FormControl>
            <RadioGroup
              aria-labelledby='direction'
              name='direction'
              onChange={handleInputChange}
              row
              sx={{ gap: { xs: 6, md: 8 } }}
            >
              <FormControlLabel
                value='Uni-Direction'
                control={<Radio sx={radioStyle} />}
                label='Uni-Direction'
              />
              <FormControlLabel
                value='Bi-Direction'
                control={<Radio sx={radioStyle} />}
                label='Bi-Direction'
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  name='autoPlanned'
                  checked={formValues.autoPlanned}
                  onChange={handleCheckboxChange}
                  sx={checkboxStyle}
                />
              }
              label='Automatic Power control'
              sx={{
                '& .MuiFormControlLabel-label': {
                  ...poppinsFormText,
                },
              }}
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12} md={12} display='flex' alignItems='start' gap='25px'>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  name='osnrMonitoring'
                  onChange={handleCheckboxChange}
                  sx={checkboxStyle}
                />
              }
              label='OSNR Monitoring'
              sx={{
                '& .MuiFormControlLabel-label': {
                  poppinsFormText,
                  fontWeight: 800,
                },
              }}
            />
          </FormGroup>
          <Box
            sx={{
              borderRadius: '10px',
              border: '1px solid #E0E0E0',
              background: '#FDFDFD',
              boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
              maxWidth: '414px',
              padding: '20px',
            }}
          >
            <Typography sx={{ ...poppinsFormText, fontWeight: 600 }}>
              Recurring
            </Typography>
            <Box>
              <FormGroup
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  columnGap: '34px',
                }}
              >
                {checkboxOptions.map(({ name, label }) => (
                  <FormControlLabel
                    key={name}
                    control={
                      <Checkbox
                        name={name}
                        onChange={handleCheckboxChange}
                        sx={checkboxStyle}
                      />
                    }
                    label={label}
                    sx={{
                      '& .MuiFormControlLabel-label': poppinsFormText,
                      fontWeight: 500,
                    }}
                  />
                ))}
              </FormGroup>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} display='flex' gap='1rem' mt={2}>
          <CustomButton
            variantType='primary'
            size='small'
            padding='1rem 2.5rem'
            width='179px'
            onClick={handleClose}
          >
            Submit
          </CustomButton>
          <CustomButton
            variantType='secondary'
            size='small'
            padding='1rem 2.5rem'
            width='179px'
            onClick={handleClose}
          >
            Cancel
          </CustomButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default WsonAdvSettings;
