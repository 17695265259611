import React, { useState } from 'react';

import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Radio from '@mui/material/Radio';

import CustomButton from '../../../components/button/CustomButton';
import { checkboxStyle, radioStyle, selectFormControlStyle } from '../../../themes/summaryStyle';

interface SdhAdvSettingProps {
  handleClose: () => void;
  setSelectOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface FormValues {
  serviceStatus: string;
  higherOrder: boolean;
  timeSlotSelection: string;
  direction: string;
}

const SdhAdvSettings: React.FC<SdhAdvSettingProps> = ({
  handleClose,
  setSelectOpen,
}) => {
  const poppinsFormText = {
    color: '#06152b',
    fontFamily: 'Poppins, sans-serif',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
  };

  const [formValues, setFormValues] = useState<FormValues>({
    serviceStatus: 'Service_IN',
    higherOrder: false,
    timeSlotSelection: 'Manual',
    direction: 'Uni-Direction',
  });

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setFormValues({
      ...formValues,
      [name]: checked,
    });
  };

  return (
    <Box sx={{ padding: '1rem 2rem 1rem 3rem' }}>
      <Grid container display='flex' alignItems='center' spacing={3}>
        <Grid item xs={4}>
          <Typography sx={poppinsFormText}>Service Status</Typography>
        </Grid>
        <Grid item xs={8} display='flex' alignItems='center' gap='1rem'>
          <FormControl
            sx={selectFormControlStyle}
          >
            <Select
              labelId='serviceStatus-select-label'
              id='serviceStatus-select'
              name='serviceStatus'
              value={formValues.serviceStatus}
              onChange={handleSelectChange}
              IconComponent={KeyboardArrowDownIcon}
              inputProps={{ 'aria-label': 'Without label' }}
              sx={{
                width: '100%',
                maxWidth: '127px',
                height: '36px',
                '& .MuiSelect-select': {
                  color: formValues.serviceStatus ? '#707070' : 'inherit',
                },
              }}
              onOpen={() => setSelectOpen(true)}
              MenuProps={{
                disableScrollLock: true,
                TransitionProps: {
                  onExited: () => setSelectOpen(false),
                },
              }}
            >
              <MenuItem value='Service_IN'>Service IN</MenuItem>
              <MenuItem value='Service_OUT'>Service OUT</MenuItem>
              <MenuItem value='NEVER'>NEVER</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6} md={12}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  name='higherOrder'
                  checked={formValues.higherOrder}
                  onChange={handleCheckboxChange}
                  sx={checkboxStyle}
                />
              }
              label='Higher Order'
              sx={{ '& .MuiFormControlLabel-label': poppinsFormText }}
            />
          </FormGroup>
        </Grid>

        <Grid item xs={4}>
          <Typography sx={{ ...poppinsFormText, fontWeight: 600 }}>
            Time slot selection
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <FormControl>
            <RadioGroup
              aria-labelledby='time-slot-selection-group'
              name='timeSlotSelection'
              value={formValues.timeSlotSelection}
              onChange={handleInputChange}
              row
              sx={{ gap: 6 }}
            >
              <FormControlLabel
                value='Manual'
                control={
                  <Radio
                    sx={radioStyle}
                  />
                }
                label='Manual'
              />
              <FormControlLabel
                value='Auto'
                control={
                  <Radio
                    sx={radioStyle}
                  />
                }
                label='Auto'
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item xs={4}>
          <Typography sx={{ ...poppinsFormText, fontWeight: 600 }}>
            Direction
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <FormControl>
            <RadioGroup
              aria-labelledby='direction-group'
              name='direction'
              value={formValues.direction}
              onChange={handleInputChange}
              row
              sx={{ gap: 1 }}
            >
              <FormControlLabel
                value='Uni-Direction'
                control={
                  <Radio
                    sx={{
                      color: 'lightgray',
                      '&.Mui-checked': {
                        color: 'white',
                      },
                      '&.Mui-checked .MuiSvgIcon-root': {
                        backgroundColor: 'blue',
                        borderRadius: '50%',
                      },
                      '& .MuiSvgIcon-root': {
                        fontSize: 28,
                      },
                    }}
                  />
                }
                label='Uni-Direction'
              />
              <FormControlLabel
                value='Bi-Direction'
                control={
                  <Radio
                    sx={{
                      color: 'lightgray',
                      '&.Mui-checked': {
                        color: 'white',
                      },
                      '&.Mui-checked .MuiSvgIcon-root': {
                        backgroundColor: 'blue',
                        borderRadius: '50%',
                      },
                      '& .MuiSvgIcon-root': {
                        fontSize: 28,
                      },
                    }}
                  />
                }
                label='Bi-Direction'
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12} display='flex' gap='1rem'>
          <CustomButton
            variantType='primary'
            size='small'
            padding='1rem 2.5rem'
            width='179px'
            onClick={handleClose}
          >
            Submit
          </CustomButton>
          <CustomButton
            variantType='secondary'
            size='small'
            padding='1rem 2.5rem'
            width='179px'
            onClick={handleClose}
          >
            Cancel
          </CustomButton>
        </Grid>
      </Grid>
    </Box>
  );
};



export default SdhAdvSettings