import { styled } from '@mui/material/styles';

const Heading = styled('div')({
  color: '#000',
  fontFamily: 'Poppins',
  fontSize: '22px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',
});

const Title = styled('div')({
  color: '#06152B',
  fontFamily: 'Poppins, sans-serif',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
});

const Text = styled('div')({
  color: '#141414',
  fontFamily: 'Poppins, sans-serif',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 500,
});

const poppinsFormText = {
  color: '#06152B',
  fontFamily: 'Poppins, sans-serif',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
};

const textStyle = {
  color: '#06152B',
  fontFamily: 'Poppins, sans-serif',
  fontSize: '12px',
  fontWeight: 400,
  textTransform: 'none',
};

const customPadding = {
  padding: '.7rem .5rem .7rem 1rem',
};

const circuitHeading = {
  color: '#000',
  fontFamily: 'Poppins, sans-serif',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',
};

const circuitSubtext = {
  maxWidth: '400px',
  width: '100%',
  color: '#707070',
  fontSize: '14px',
  fontFamily: 'Poppins, sans-serif',
  fontWeight: 400,
  lineHeight: 'normal',
};

const btnStyle = {
  color: '#000',
  WebkitTextStrokeWidth: 0.1,
  WebkitTextStrokeColor: '#000',
  fontFamily: 'Poppins',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  textTransform: 'none',
};

const checkboxStyle = {
  color: '#dde5ea',
  '&.Mui-checked': {
    color: '#2F4CDD',
    borderColor: '#2F4CDD',
  },
  '& .MuiSvgIcon-root': {
    borderRadius: '3px',
  },
  '&.MuiCheckbox-root': {
    borderColor: '#dde5ea',
  },
};

const tableHeading = {
  fontFamily: 'Poppins, sans-serif',
  fontWeight: 500,
  color: '#141414',
};

const tableCell = {
  fontFamily: 'Poppins, sans-serif',
  fontWeight: 400,
};

const submitBtn = {
  backgroundColor: '#00F',
  '&:hover': {
    backgroundColor: '#0000BA',
    borderColor: '#0000BA',
  },
  color: 'white',
  minWidth: 316,
  minHeight: 59,
  borderRadius: '10px',
  textTransform: 'none',
  '&.Mui-disabled': {
    backgroundColor: '#9BABFF',
    color: 'white',
    border: '2px solid #D9E1E7',
  },
};

const radioStyle = {
  color: 'lightgray',
  '&.Mui-checked': {
    color: 'white',
  },
  '&.Mui-checked .MuiSvgIcon-root': {
    backgroundColor: 'blue',
    borderRadius: '50%',
  },
  '& .MuiSvgIcon-root': {
    fontSize: 28,
  },
};

const selectFormControlStyle = {
  width: '100%',
  maxWidth: '127px',
  height: '36px',
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: '#0000FF',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#00F',
      backgroundColor: 'rgba(220, 226, 255, 0.5)',
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: '2px solid #D9E1E7',
  },
};

const OutlinedInputStyle = {
  width: '100%',
  maxWidth: '75px',
  padding: '2px',
  lineHeight: '0',
  height: '36px',
  color: '#707070',
  border: '1px solid #809FB8',
  '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button':
    {
      WebkitAppearance: 'none',
      margin: 0,
    },
  '& input[type="number"]': {
    MozAppearance: 'textfield',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#0000FF', // Blue color when focused
  },
  '&:hover fieldset': {
    border: '2px solid #0000FF !important',
  },
  '&:hover': {
    backgroundColor: 'rgba(220, 226, 255, 0.5)', // Semi-transparent background
  },
  '& input': {
    color: '#000', // Ensure font color is black
  },
};

const CircuitButtonStyle = {
  background: '#FFF',
  padding: '.4rem 1rem',
  borderRadius: '5px',
  border: '2px solid #D0D5DE',
  textTransform: 'none',
  '&:active': {
    border: '2px solid #00F',
    backgroundColor: '#ECF3F9',
  },
  '&:hover': {
    border: '2px solid #00F',
    backgroundColor: '#ECF3F9',
  },
};

const commonBtn = {
  backgroundColor: '#F5FBFF',
  color: '#06152B',
  textTransform: 'none',
  borderRadius: '10px',
  border: '1px solid #D9E1E7',
  fontSize: '12px !important', // Use !important to enforce font size
  minHeight: '49px',
};

export {
  Heading,
  Title,
  Text,
  poppinsFormText,
  textStyle,
  customPadding,
  circuitHeading,
  circuitSubtext,
  btnStyle,
  checkboxStyle,
  tableHeading,
  tableCell,
  submitBtn,
  radioStyle,
  selectFormControlStyle,
  OutlinedInputStyle,
  CircuitButtonStyle,
  commonBtn
};
