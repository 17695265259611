// MainLayout.tsx
import React, { useState } from 'react';

import { Box } from '@mui/material';

import LeftSideBar from '../../components/LeftSideBar/LeftSideBar';
import CircuitSidebar from '../../components/sidebar/CircuitSidebar';
import CircuitHeader from '../../components/Navbar/CircuitHeader';
import TableLayout from '../../forms/p2p/Otn/TableLayout';

interface MainLayoutProps {
  anchorEl: HTMLButtonElement | null;
  open: boolean;
  content: string;
  handleClick: (event: any, content: string) => void;
  renderContent: () => React.ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = ({
  anchorEl,
  open,
  content,
  handleClick,
  renderContent,
}) => {
  const [showTable, setShowTable] = useState<boolean>(false);
  const setTableVisibility = (isVisible: boolean) => {
    setShowTable(isVisible);
  };

  return (
    <>
      <CircuitHeader />
      <Box sx={{ margin: 'auto', maxWidth: '1600px', position: 'relative' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            height: '100vh',
          }}
        >
          <Box
            sx={{
              position: 'fixed', // Keep LeftSideBar fixed
            }}
          >
            <LeftSideBar
              anchorEl={anchorEl}
              open={open}
              content={content}
              handleClick={handleClick}
              renderContent={renderContent}
            />
          </Box>
          {/* Will uncomment later when required */}
          <Box sx={{ marginTop: '70px' }} flexGrow={1}>
            {showTable && (
              <TableLayout setTableVisibility={setTableVisibility} />
            )}
          </Box>
          <CircuitSidebar
            setShowTable={setShowTable}
            setTableVisibility={setTableVisibility}
          />
        </Box>
      </Box>
    </>
  );
};

export default MainLayout;
