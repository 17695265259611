import { useState } from 'react';

import { Typography } from '@mui/material';

import SdhCircuitType from '../../forms/p2p/Sdh/SdhCircuitType';
import SdhSourceForm from '../../forms/p2p/Sdh/SdhSourceForm';
import SdhDestinationForm from '../../forms/p2p/Sdh/SdhDestinationForm';
import SdhConstraintForm from '../../forms/p2p/Sdh/SdhConstraintsForm';

import MainLayout from './ MainLayout';

const SdhCircuitCreation = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState<string>('');

  const handleClick = (event: any, content: string) => {
    setAnchorEl(event.currentTarget);
    setContent(content);
    setOpen(true);
  };

  const renderContent = () => {
    switch (content) {
      case 'circuit-type':
        return <SdhCircuitType setOpen={setOpen} />;
      case 'source-info':
        return <SdhSourceForm setOpen={setOpen} />;
      case 'node-info':
        return <SdhDestinationForm setOpen={setOpen} />;
      case 'route-info':
        return <SdhConstraintForm setOpen={setOpen} />;
      default:
        return <Typography sx={{ p: 2 }}>Default Content</Typography>;
    }
  };

  return (
    <MainLayout
      anchorEl={anchorEl}
      open={open}
      content={content}
      handleClick={handleClick}
      renderContent={renderContent}
    />
  );
};

export default SdhCircuitCreation;
