import React from 'react';

import { Box, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';

import circuitIcon from '../../../assets/svg/circuit-icon.svg';
import infoIcon from '../../../assets/svg/info-icon-blue.svg';

interface OtnGmplsSourceFormProps {
  setOpen: (open: boolean) => void;
}

const OtnGmplsConstraintForm: React.FC<OtnGmplsSourceFormProps> = ({
  setOpen,
}) => {
  return (
    <Box
      sx={{
        width: '268px',
        height: '305px',
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ borderBottom: '1px solid #D9E1E7', paddingBlock: '13px' }}
      >
        <Box display="flex" alignItems="center" sx={{ ml: '-1px' }}>
          <IconButton aria-label="circuit info">
            <img src={circuitIcon} alt="circuit icon" />
          </IconButton>
          <Typography className="poppins-error-font">Constraints</Typography>
        </Box>
        <Box sx={{ mr: '7px' }}>
          <IconButton
            size="small"
            aria-label="close"
            onClick={() => setOpen(false)}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
      <Box sx={{ paddingInline: '14px 17px' }}>
        <Box mt="18px" ml="12px" display="flex" alignItems="center">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked
                  sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: '15px',
                      borderRadius: '3px',
                    },
                    '&.Mui-checked': {
                      color: 'blue',
                      height: '14px',
                      width: '14px',
                      marginRight: '10px',
                    },
                  }}
                />
              }
              label="Add Include/Exclude constraints"
              sx={{
                '& .MuiFormControlLabel-label': {
                  fontSize: '10px',
                  maxWidth: '165px',
                  fontFamily: 'Poppins',
                },
              }}
            />
          </FormGroup>
          <Box ml="-15px">
            <IconButton size="small" aria-label="close">
              <img src={infoIcon} alt="info icon" />
            </IconButton>
          </Box>
        </Box>
        <Box mt="18px">
          <FormControl>
            <Typography className="poppins-font-primary">Work Path</Typography>
            <RadioGroup
              defaultValue="Include Nodes /Links"
              name="radio-buttons-group"
            >
              <FormControlLabel
                value="Include Nodes /Links"
                control={
                  <Radio
                    sx={{
                      '& .MuiSvgIcon-root': { fontSize: '19px' },
                      '&.Mui-checked': {
                        color: 'blue',
                      },
                    }}
                  />
                }
                sx={{
                  '& .MuiSvgIcon-root': { fontSize: '19px' },
                  '& .MuiFormControlLabel-label': {
                    fontSize: '10px',
                    fontFamily: 'Poppins',
                  },
                }}
                label="Include Nodes /Links"
              />
              <FormControlLabel
                value="Exclude Nodes /Links"
                control={
                  <Radio
                    sx={{
                      '& .MuiSvgIcon-root': { fontSize: '19px' },
                      '&.Mui-checked': {
                        color: 'blue',
                      },
                      mt: '-10px',
                    }}
                  />
                }
                sx={{
                  '& .MuiFormControlLabel-label': {
                    fontSize: '10px',
                    fontFamily: 'Poppins',
                    height: '15px',
                    mt: '-10px',
                  },
                }}
                label="Exclude Nodes /Links"
              />
            </RadioGroup>
          </FormControl>
        </Box>
        <Box mt={2}>
          <FormControl>
            <Typography className="poppins-font-primary">
              Protect Path
            </Typography>
            <RadioGroup
              defaultValue="Include Nodes /Links"
              name="radio-buttons-group"
            >
              <FormControlLabel
                value="Include Nodes /Links"
                control={
                  <Radio
                    sx={{
                      '& .MuiSvgIcon-root': { fontSize: '19px' },
                      '&.Mui-checked': {
                        color: 'blue',
                      },
                    }}
                  />
                }
                sx={{
                  '& .MuiFormControlLabel-label': {
                    fontSize: '10px',
                    fontFamily: 'Poppins',
                  },
                }}
                label="Include Nodes /Links"
              />
              <FormControlLabel
                value="Exclude Nodes /Links"
                control={
                  <Radio
                    sx={{
                      '& .MuiSvgIcon-root': { fontSize: '19px' },
                      '&.Mui-checked': {
                        color: 'blue',
                      },
                      mt: '-10px',
                    }}
                  />
                }
                sx={{
                  '& .MuiFormControlLabel-label': {
                    fontSize: '10px',
                    fontFamily: 'Poppins',
                    height: '15px',
                    mt: '-10px',
                  },
                }}
                label="Exclude Nodes /Links"
              />
            </RadioGroup>
          </FormControl>
        </Box>
      </Box>
    </Box>
  );
};

export default OtnGmplsConstraintForm;
