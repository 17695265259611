import React, { useState } from 'react';

import CustomTable from '../../../components/customTable/CustomTable';
import {
  initialRows,
  tableHeaders,
  RowData,
  dynamicData,
} from '../../../constants/tableData';

// Define the props for TableLayout
interface TableLayoutProps {
  setTableVisibility: (isVisible: boolean) => void;
}

const TableLayout: React.FC<TableLayoutProps> = ({ setTableVisibility }) => {
  const [rows, setRows] = useState<RowData[]>(initialRows);

  const handleCheckboxChange = (index: number) => {
    const updatedRows = rows.map((row, i) =>
      i === index ? { ...row, ho: !row.ho } : row
    );
    setRows(updatedRows);
  };

  const handleSrcClick = (srcME: string) => {
    // console.log(`Source ME clicked: ${srcME}`);
  };

  const handleDestinationClick = (destinationME: string) => {
    // console.log(`Destination ME clicked: ${destinationME}`);
  };

  return (
    <CustomTable
      dynamicData={dynamicData}
      rows={rows}
      tableHeaders={tableHeaders}
      handleCheckboxChange={handleCheckboxChange}
      handleSrcClick={handleSrcClick}
      handleDestinationClick={handleDestinationClick}
      setTableVisibility={setTableVisibility}
    />
  );
};

export default TableLayout;
