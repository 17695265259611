import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Tabs,
  Tab,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";

// Define the props type
interface CustomizedDialogsProps {
  open: boolean;
  handleClose: () => void;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: "1rem",
  },
  "& .MuiDialog-container": {
    backgroundColor: "rgba(0, 0, 0, 0.30)",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
    paddingBottom: theme.spacing(3), // Add bottom padding here
  },
  "& .MuiDialog-paper": {
    borderRadius: "15px",
    minWidth: "auto",
    [theme.breakpoints.up('md')]: {
      minWidth: "927px",
    },
    minHeight: "670px",
  },
}));

export default function CustomizedDialogs({
  open,
  handleClose,
}: CustomizedDialogsProps) {
  const [tabIndex, setTabIndex] = React.useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const tableHeaders = [
    "Node ID",
    "Link Source",
    "Link Destination",
    "Path",
    "Order",
  ];

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogTitle
        sx={{
          fontSize: "18px",
          color: "#000",
          fontFamily: "Poppins, sans-serif",
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "normal",
          paddingTop: 2,
          paddingBottom: 1,
        }}
        id="customized-dialog-title"
      >
        Inclusion Exclusion Constraints
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: "#222222",
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Box>
          <Tabs value={tabIndex} onChange={handleTabChange} sx={{marginBottom:"1rem"}}>
          <Tab
              label="Inclusion"
              sx={{
                color: tabIndex === 0 ? "#00F" : "#06152B",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: tabIndex === 0 ? 600 : 400,
                lineHeight: "normal",
                textTransform: "none"
              }}
            />
            <Tab
              label="Exclusion"
              sx={{
                color: tabIndex === 1 ? "#00F" : "#06152B",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: tabIndex === 1 ? 600 : 400,
                lineHeight: "normal",
                textTransform: "none"
              }}
            />
          </Tabs>
          {tabIndex === 0 && (
            <Table sx={{ minWidth: 700}}>
              <TableHead sx={{ backgroundColor: "#F5F5F5" }}>
                <TableRow>
                  {tableHeaders.map((header) => (
                    <TableCell
                      key={header}
                      sx={{ minWidth: "100px", border: "none", textTransform: "none",
                      }}
                    >
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {/* Insert Inclusion Table Data Here */}
                <TableRow>
                  <TableCell>1</TableCell>
                  <TableCell>Source A</TableCell>
                  <TableCell>Destination A</TableCell>
                  <TableCell>Path A</TableCell>
                  <TableCell>1</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          )}
          {tabIndex === 1 && (
            <Table sx={{ minWidth: 700}}>
              <TableHead sx={{ backgroundColor: "#F5F5F5" }}>
                <TableRow>
                  {tableHeaders.map((header) => (
                    <TableCell
                      key={header}
                      sx={{ minWidth: "100px", border: "none" }}
                    >
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {/* Insert Exclusion Table Data Here */}
                <TableRow>
                  <TableCell>2</TableCell>
                  <TableCell>Source B</TableCell>
                  <TableCell>Destination B</TableCell>
                  <TableCell>Path B</TableCell>
                  <TableCell>2</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Box pr={5} display="flex" gap="26px">
          <Button
            onClick={handleClose}
            sx={{
              backgroundColor: "#D9E1E7",
              "&:hover": {
                backgroundColor: "#D9E1E7", // Same background color on hover
              },
              color: "#141414",
              fontFamily: "Poppins, sans-serif",

              minWidth: 179,
              minHeight: 60,
              borderRadius: "10px",
              textTransform: "none",
              fontWeight: 500, // To prevent text from being converted to uppercase
            }}          >
            Close
          </Button>
          <Button
            onClick={handleClose}
            sx={{
              backgroundColor: "blue",
              "&:hover": {
                backgroundColor: "blue", // Same background color on hover
              },
              color: "white",
              fontFamily: "Poppins, sans-serif",

              minWidth: 179,
              minHeight: 60,
              borderRadius: "10px",
              textTransform: "none",
              fontWeight: 500, // To prevent text from being converted to uppercase
            }}
          >
            Remove
          </Button>
        </Box>
      </DialogActions>
    </BootstrapDialog>
  );
}
