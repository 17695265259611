import React, { FC, ReactNode } from 'react';
import { ToastContainer, toast, ToastOptions } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import InfoIcon from '@mui/icons-material/Info';
import ErrorIcon from '@mui/icons-material/Error';

// Define the props for the CustomCloseButton
interface CustomCloseButtonProps {
  closeToast?: () => void;
}

// Custom close button component
const CustomCloseButton: FC<CustomCloseButtonProps> = ({ closeToast }) => (
  <button
    onClick={closeToast}
    style={{
      backgroundColor: 'transparent',
      border: 'none',
      cursor: 'pointer',
      fontSize: '16px',
    }}
  >
    ✖
  </button>
);

// Toast configuration function
const getToastConfig = (
  type: 'success' | 'error' | 'info' | 'warning'
): ToastOptions => ({
  position: 'top-center',
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  closeButton: <CustomCloseButton />,
  icon:
    type === 'error' ? (
      <ErrorIcon style={{ color: '#f44336' }} />
    ) : type === 'info' ? (
      <InfoIcon style={{ color: '#2196f3' }} />
    ) : type === 'warning' ? (
      <WarningAmberIcon style={{ color: '#ff9800' }} />
    ) : (
      false
    ),
  style: {
    backgroundColor:
      type === 'success'
        ? '#d4edda'
        : type === 'error'
        ? '#f8d7da'
        : type === 'warning'
        ? '#fff3cd'
        : type === 'info'
        ? '#B2D8F6'
        : '#e2e3e5',
    color:
      type === 'success'
        ? '#155724'
        : type === 'error'
        ? '#721c24'
        : type === 'warning'
        ? '#856404'
        : type === 'info'
        ? '#0066FF'
        : '#383d41',
    fontSize: '16px',
    padding: '10px 15px',
    borderRadius: '15px',
  },
});

// Toast component to trigger notifications
const Toast = {
  success: (message: string) =>
    toast.success(message, getToastConfig('success')),
  error: (message: string) => toast.error(message, getToastConfig('error')),
  info: (message: string) => toast.info(message, getToastConfig('info')),
  warning: (message: string) => toast.warn(message, getToastConfig('warning')),
};

// Define the props for the ToastProvider
interface ToastProviderProps {
  children: ReactNode;
}

// ToastProvider component to render ToastContainer
const ToastProvider: FC<ToastProviderProps> = ({ children }) => (
  <>
    {children}
    <ToastContainer />
  </>
);

export { ToastProvider, Toast };
