import React from 'react';
import { useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, Controller } from 'react-hook-form';

import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CachedIcon from '@mui/icons-material/Cached';

import CustomizedDialogs from '../../../components/modal/ConstraintsModal';
import CustomMultiSelect from '../../../components/CustomSelect/CustomMultiSelect';

import info from '../../../assets/svg/information-button.svg';
import {
  checkboxStyle,
  poppinsFormText,
  selectFormControlStyle,
  submitBtn,
} from '../../../themes/summaryStyle';

import {
  validationWsonConstrainInfoSchema,
  wsonConstrainInfoSchema,
} from '../../../schema/schema';
import { useStepContext } from '../../../contexts/StepProviderContext';
import SearchAutoComplete from '../../../components/SearchAutoComplete/SearchAutoComplete';

interface OtnWsonConstraintsProps {
  onNext: () => void;
}

const typographyStyle = {
  color: '#06152B',
  fontFamily: 'Poppins',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',
};

const WsonConstraints: React.FC<OtnWsonConstraintsProps> = ({ onNext }) => {
  const [open, setOpen] = React.useState(false);
  const { formNumber, setFormNumber } = useStepContext();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    control,
  } = useForm<validationWsonConstrainInfoSchema>({
    resolver: zodResolver(wsonConstrainInfoSchema),
    defaultValues: {
      ExcludeConstSelect: 'Nodes', // Set default value here
      includeConstSelect: 'Nodes', // Set default value here
      selectPort: [],
      includeSelectPort: [],
    },
  });

  const ipAddresses = [
    '192.168.0.1',
    '10.0.0.1',
    '172.16.0.1',
    '192.168.1.1',
    '203.0.113.1',
    '8.8.8.8',
    '192.168.100.1',
    '192.0.2.1',
    '198.51.100.1',
    '255.255.255.255',
  ];

  const excludeConstraints = watch('excludeConstraints', false); // Watch the checkbox state
  const includeConstraint = watch('includeConstraints', false);
  const selectIncludeSelectPort = watch('includeSelectPort', []);
  const selectPortOptions = watch('selectPort', []);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleClearAll = (field: keyof validationWsonConstrainInfoSchema) => {
    setValue(field, []);
  };

  const onSubmit = (data: validationWsonConstrainInfoSchema) => {
    setFormNumber(4);
    navigate('/p2p/wson/summary');
  };

  return (
    <Box mt={2}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} alignItems='center'>
          <Grid item md={4} lg={2.5}>
            <Typography sx={poppinsFormText}>Service Pair Tunnel</Typography>
          </Grid>
          <Grid item md={8} lg={9.5}>
            <Box display='flex' alignItems='center' gap='6px'>
              <Box>
                <FormControl
                  sx={{
                    ...selectFormControlStyle,
                    maxWidth: '183px',
                  }}
                >
                  <Select
                    IconComponent={() => (
                      <KeyboardArrowDownIcon sx={{ color: '#809FB8' }} />
                    )}
                    inputProps={{ 'aria-label': 'Without label' }}
                    sx={{
                      ...poppinsFormText,
                      width: '183px',
                      height: '36px',
                      color: '#707070',
                      fontSize: '16px',
                      lineHeight: 'normal',
                      '& .MuiSelect-select': {
                        display: 'flex',
                        alignItems: 'center',
                      },
                      '& .MuiSvgIcon-root': {
                        color: '#707070',
                      },
                    }}
                    {...register('servicePairTunnel')}
                    defaultValue=''
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                  >
                    <MenuItem value={'refreshToLoad'}>Refresh to Load</MenuItem>
                    <MenuItem value={'Option1'}>Option1</MenuItem>
                    <MenuItem value={'Option2'}>Option2</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box>
                <IconButton
                  aria-label='refresh'
                  sx={{
                    backgroundColor: '#EBEBEB',
                    height: '36px',
                    width: '38px',
                    border: '1px solid #809FB8',
                    borderRadius: '7px',
                  }}
                >
                  <CachedIcon sx={{ color: 'black' }} />
                </IconButton>
              </Box>
            </Box>
            {errors.servicePairTunnel && (
              <Typography
                className='poppins-error-font'
                mt='4px'
                sx={{ color: '#F90000' }}
              >
                {errors.servicePairTunnel.message}
              </Typography>
            )}
          </Grid>

          <Grid item md={4} lg={2.5}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    {...register('excludeConstraints')}
                    sx={checkboxStyle}
                  />
                }
                label='Exclude Constraints'
              />
              {errors.excludeConstraints && (
                <Typography
                  ml={2}
                  sx={{ color: '#F90000' }}
                  className='poppins-error-font'
                >
                  {' '}
                  {errors.excludeConstraints.message}
                </Typography>
              )}
            </FormGroup>
          </Grid>
          <Grid
            item
            display='flex'
            alignItems='center'
            gap='6px'
            md={8}
            lg={9.5}
          >
            <Box>
              <FormControl
                sx={{
                  height: '36px',
                  maxWidth: '183px',
                  width: '100%',
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                      borderColor: '#0000FF',
                      borderWidth: '2px',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: '2px solid #D9E1E7',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#00F', // Change border to red on hover
                      backgroundColor: 'rgba(220, 226, 255, 0.5)', // Semi-transparent background
                    },
                  },
                }}
              >
                <Select
                  IconComponent={() => (
                    <KeyboardArrowDownIcon sx={{ color: '#809FB8' }} />
                  )}
                  inputProps={{ 'aria-label': 'Without label' }}
                  sx={{
                    width: '183px',
                    height: '36px',
                    color: '#707070',
                    fontFamily: 'Poppins',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                  }}
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  {...register('ExcludeConstSelect')}
                  defaultValue='Nodes'
                >
                  <MenuItem value={'Nodes'}>Nodes</MenuItem>
                  <MenuItem value={'Links'}>Links</MenuItem>
                  <MenuItem value={'SRLG'}>SRLG</MenuItem>
                </Select>
              </FormControl>
              {excludeConstraints && errors.ExcludeConstSelect && (
                <Typography
                  className='poppins-error-font'
                  sx={{ color: '#F90000' }}
                >
                  {errors.ExcludeConstSelect.message}
                </Typography>
              )}
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Controller
              name='nodeIpAddress'
              control={control}
              render={({ field }) => (
                <SearchAutoComplete
                  ipAddresses={ipAddresses}
                  value={field.value?.toString() || ''}
                  onChange={(event: any, value: any) => {
                    field.onChange(value);
                  }}
                  label={
                    <>
                      Node/Ip Address<span style={{ color: 'red' }}>*</span>
                    </>
                  }
                />
              )}
            />
            {errors.nodeIpAddress && (
              <Typography
                mt='4px'
                ml={2}
                sx={{ color: '#F90000' }}
                className='poppins-error-font'
              >
                {errors.nodeIpAddress.message}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} display='flex' alignItems='start' gap='6px'>
            <Box>
              <CustomMultiSelect
                label='Select Options'
                options={[
                  { value: 'common-1-3-1', label: 'Common-1-3-1' },
                  { value: 'common-1-3-2', label: 'Common-1-3-2' },
                  { value: 'common-1-3-3', label: 'Common-1-3-3' },
                  { value: 'common-1-3-4', label: 'Common-1-3-4' },
                  { value: 'common-1-3-5', label: 'Common-1-3-5' },
                ]}
                value={selectPortOptions}
                onChange={(e: any) =>
                  setValue('selectPort', e.target.value as string[])
                }
                name='selectPort'
                borderRadius='7px 0px 0px 7px'
              />
              {selectPortOptions.length > 0 && (
                <Box
                  display='flex'
                  flexDirection='column' // Ensure items are stacked vertically
                  alignItems='start'
                  justifyContent='space-between'
                  sx={{
                    width: {
                      md: '490px',
                      sm: '200px',
                      xs: '150px',
                    },
                    position: 'relative',
                    border: '1px solid #E4E4E4',
                    background: '#F4F4F4',
                    padding: '8px', // Add some padding to the box
                  }}
                >
                  {selectPortOptions.map((item, index) => (
                    <Box
                      display='flex'
                      justifyContent='space-between'
                      alignItems='center'
                      key={index}
                      sx={{ width: '100%', marginBottom: '8px' }} // Ensure spacing between items
                    >
                      <Typography sx={{ typographyStyle }}>{item}</Typography>
                    </Box>
                  ))}
                  {selectPortOptions.length > 0 && (
                    <Typography
                      sx={{
                        color: '#06152B',
                        position: 'absolute',
                        top: '8px',
                        right: '8px',
                        cursor: 'pointer',
                        fontStyle: 'italic',
                      }}
                      onClick={() => handleClearAll('selectPort')}
                    >
                      Clear All
                    </Typography>
                  )}
                </Box>
              )}

              {errors.selectPort && (
                <Typography
                  mt='4px'
                  ml={2}
                  sx={{ color: '#F90000' }}
                  className='poppins-error-font'
                >
                  {errors.selectPort.message}
                </Typography>
              )}
            </Box>

            <Box>
              <IconButton
                aria-label='add'
                sx={{
                  backgroundColor: '#EBEBEB',
                  height: '49px',
                  width: '55px',
                  border: '1px solid #D9E1E7',
                  borderRadius: '5px',
                }}
              >
                <AddIcon sx={{ color: 'black' }} />
              </IconButton>
            </Box>
          </Grid>

          <Grid item md={4} lg={2.5}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    {...register('includeConstraints')}
                    sx={checkboxStyle}
                  />
                }
                label='Include Constraints'
              />
              {errors.includeConstraints && (
                <Typography
                  mt='4px'
                  ml={2}
                  sx={{ color: '#F90000' }}
                  className='poppins-error-font'
                >
                  {errors.includeConstraints.message}
                </Typography>
              )}
            </FormGroup>
          </Grid>

          <Grid
            item
            display='flex'
            alignItems='center'
            gap='6px'
            md={8}
            lg={9.5}
          >
            <Box>
              <FormControl
                sx={{
                  height: '36px',
                  maxWidth: '183px',
                  width: '100%',
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                      borderColor: '#0000FF',
                      borderWidth: '2px',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: '2px solid #D9E1E7',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#00F', // Change border to red on hover
                      backgroundColor: 'rgba(220, 226, 255, 0.5)', // Semi-transparent background
                    },
                  },
                }}
              >
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  {...register('includeConstSelect')}
                  IconComponent={() => (
                    <KeyboardArrowDownIcon sx={{ color: '#809FB8' }} />
                  )}
                  inputProps={{ 'aria-label': 'Without label' }}
                  sx={{
                    width: '210px',
                    height: '36px',
                    color: '#707070',
                    fontFamily: 'Poppins',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                  }}
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  defaultValue='Nodes'
                >
                  <MenuItem value={'Nodes'}>Nodes</MenuItem>
                  <MenuItem value={'Links'}>Links</MenuItem>
                  <MenuItem value={'SRLG'}>SRLG</MenuItem>
                </Select>
              </FormControl>
              {includeConstraint && errors.includeConstSelect && (
                <Typography
                  className='poppins-error-font'
                  mt='4px'
                  sx={{ color: '#F90000' }}
                >
                  {errors.includeConstSelect.message}
                </Typography>
              )}
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Controller
              name='includeNodeIpAddress'
              control={control}
              render={({ field }) => (
                <SearchAutoComplete
                  ipAddresses={ipAddresses}
                  value={field.value?.toString() || ''}
                  onChange={(event: any, value: any) => {
                    field.onChange(value);
                  }}
                  label={
                    <>
                      Node/Ip Address<span style={{ color: 'red' }}>*</span>
                    </>
                  }
                />
              )}
            />
            {errors.includeNodeIpAddress && (
              <Typography
                mt='4px'
                ml={2}
                sx={{ color: '#F90000' }}
                className='poppins-error-font'
              >
                {errors.includeNodeIpAddress.message}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} display='flex' alignItems='start' gap='6px'>
            <Box>
              <CustomMultiSelect
                label='Select Port'
                options={[
                  { value: 'common-1-3-1', label: 'Common-1-3-1' },
                  { value: 'common-1-3-2', label: 'Common-1-3-2' },
                  { value: 'common-1-3-3', label: 'Common-1-3-3' },
                  { value: 'common-1-3-4', label: 'Common-1-3-4' },
                  { value: 'common-1-3-5', label: 'Common-1-3-5' },
                ]}
                value={selectIncludeSelectPort}
                onChange={(e: any) =>
                  setValue('includeSelectPort', e.target.value as string[])
                }
                name='includeSelectPort'
                borderRadius='7px 0px 0px 7px'
              />
              {selectIncludeSelectPort.length > 0 && (
                <Box
                  display='flex'
                  flexDirection='column' // Ensure items are stacked vertically
                  alignItems='start'
                  justifyContent='space-between'
                  sx={{
                    width: {
                      md: '490px',
                      sm: '200px',
                      xs: '150px',
                    },
                    position: 'relative',
                    border: '1px solid #E4E4E4',
                    background: '#F4F4F4',
                    padding: '8px', // Add some padding to the box
                  }}
                >
                  {selectIncludeSelectPort.map((item, index) => (
                    <Box
                      display='flex'
                      justifyContent='space-between'
                      alignItems='center'
                      key={index}
                      sx={{ width: '100%', marginBottom: '8px' }} // Ensure spacing between items
                    >
                      <Typography sx={{ typographyStyle }}>{item}</Typography>
                    </Box>
                  ))}
                  {selectIncludeSelectPort.length > 0 && (
                    <Typography
                      sx={{
                        color: '#06152B',
                        position: 'absolute',
                        top: '8px',
                        right: '8px',
                        cursor: 'pointer',
                        fontStyle: 'italic',
                      }}
                      onClick={() => handleClearAll('includeSelectPort')}
                    >
                      Clear All
                    </Typography>
                  )}
                </Box>
              )}

              {errors.includeSelectPort && (
                <Typography
                  mt='4px'
                  ml={2}
                  sx={{ color: '#F90000' }}
                  className='poppins-error-font'
                >
                  {errors.includeSelectPort.message}
                </Typography>
              )}
            </Box>
            <Box>
              <IconButton
                aria-label='add'
                sx={{
                  backgroundColor: '#EBEBEB',
                  height: '49px',
                  width: '55px',
                  border: '1px solid #D9E1E7',
                  borderRadius: '5px',
                }}
              >
                <AddIcon sx={{ color: 'black' }} />
              </IconButton>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Button
              sx={{
                borderRadius: '5px',
                border: '1px solid #809FB8',
                backgroundColor: '#EBEBEB',
                color: '#00F',
                fontFamily: 'Poppins, sans-serif',
                fontSize: '14px',
                fontStyle: 'italic',
                fontWeight: 400,
                lineHeight: 'normal',
                padding: '.8rem 3rem',
              }}
              onClick={handleClickOpen}
            >
              Show GMPLS Constraints
            </Button>
            <CustomizedDialogs open={open} handleClose={handleClose} />
          </Grid>
          <Grid item xs={12} mt={3}>
            <Typography
              sx={{
                color: '#000',
                fontFamily: 'Poppins',
                fontSize: '18px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: 'normal',
              }}
            >
              Constraints{' '}
            </Typography>
            <Typography
              sx={{
                maxWidth: '400px',
                width: '100%',
                color: '#707070',
                fontSize: '14px',
                fontFamily: 'Poppins, sans-serif',
                fontWeight: '400',
              }}
            >
              Please enter a few details below Listed Mandatory Fields, Need
              More Please filter From advance{' '}
            </Typography>
          </Grid>

          <Grid item md={12}>
            <Box display='flex' alignItems='center'>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      {...register('addIncludeExcludeConstraints')}
                      sx={checkboxStyle}
                    />
                  }
                  label='Add Include/Exclude constraints'
                />
              </FormGroup>
              <img src={info} alt='info' />
            </Box>
            {errors.addIncludeExcludeConstraints && (
              <Typography
                mt='4px'
                ml={2}
                sx={{ color: '#F90000' }}
                className='poppins-error-font'
              >
                {errors.addIncludeExcludeConstraints.message}
              </Typography>
            )}
          </Grid>

          <Grid item xs={12}>
            <Typography
              sx={{
                color: '#000',
                fontSize: '16px',
                fontFamily: 'Poppins, sans-serif',
                fontWeight: 600,
              }}
              mb={1}
            >
              Work path
            </Typography>
            <FormControl>
              <RadioGroup
                aria-labelledby='demo-controlled-radio-buttons-group'
                name='workPath'
                defaultValue=''
                row
                sx={{ display: 'flex', gap: { md: '4rem' } }}
              >
                <FormControlLabel
                  value='Include Nodes /Links'
                  control={
                    <Radio
                      sx={{
                        color: 'lightgray',
                        '&.Mui-checked': {
                          color: 'white',
                        },
                        '&.Mui-checked .MuiSvgIcon-root': {
                          backgroundColor: 'blue',
                          borderRadius: '50%',
                        },
                        '& .MuiSvgIcon-root': {
                          fontSize: 20,
                        },
                      }}
                    />
                  }
                  label='Include Nodes /Links'
                  {...register('workPath')}
                />
                <FormControlLabel
                  value='Exclude Nodes /Links'
                  control={
                    <Radio
                      sx={{
                        color: 'lightgray',
                        '&.Mui-checked': {
                          color: 'white',
                        },
                        '&.Mui-checked .MuiSvgIcon-root': {
                          backgroundColor: 'blue',
                          borderRadius: '50%',
                        },
                        '& .MuiSvgIcon-root': {
                          fontSize: 20,
                        },
                      }}
                    />
                  }
                  label='Exclude Nodes /Links'
                  {...register('workPath')}
                />
              </RadioGroup>
              {errors.workPath && (
                <Typography
                  mt='4px'
                  ml={2}
                  sx={{ color: '#F90000' }}
                  className='poppins-error-font'
                >
                  {errors.workPath.message}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} mt={2}>
            <Typography
              sx={{
                color: '#000',
                fontSize: '16px',
                fontFamily: 'Poppins, sans-serif',
                fontWeight: 600,
              }}
              mb={1}
            >
              Protect path{' '}
            </Typography>
            <FormControl>
              <RadioGroup
                aria-labelledby='demo-controlled-radio-buttons-group'
                name='protectPath'
                defaultValue=''
                row
                sx={{ display: 'flex', gap: { md: '4rem' } }}
              >
                <FormControlLabel
                  value='Include Nodes /Links'
                  control={
                    <Radio
                      sx={{
                        color: 'lightgray',
                        '&.Mui-checked': {
                          color: 'white',
                        },
                        '&.Mui-checked .MuiSvgIcon-root': {
                          backgroundColor: 'blue',
                          borderRadius: '50%',
                        },
                        '& .MuiSvgIcon-root': {
                          fontSize: 20,
                        },
                      }}
                    />
                  }
                  label='Include Nodes /Links'
                  {...register('protectPath')}
                />
                <FormControlLabel
                  value='Exclude Nodes /Links'
                  control={
                    <Radio
                      sx={{
                        color: 'lightgray',
                        '&.Mui-checked': {
                          color: 'white',
                        },
                        '&.Mui-checked .MuiSvgIcon-root': {
                          backgroundColor: 'blue',
                          borderRadius: '50%',
                        },
                        '& .MuiSvgIcon-root': {
                          fontSize: 20,
                        },
                      }}
                    />
                  }
                  label='Exclude Nodes /Links'
                  {...register('protectPath')}
                />
              </RadioGroup>
            </FormControl>
            {errors.protectPath && (
              <Typography
                mt='4px'
                ml={2}
                sx={{ color: '#F90000' }}
                className='poppins-error-font'
              >
                {errors.protectPath.message}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox {...register('srlgDisjoint')} sx={checkboxStyle} />
                }
                label='SRLG Disjoint'
              />
            </FormGroup>
            {errors.srlgDisjoint && (
              <Typography
                mt='4px'
                ml={2}
                sx={{ color: '#F90000' }}
                className='poppins-error-font'
              >
                {errors.srlgDisjoint.message}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <Button
              className='poppins-font'
              sx={submitBtn}
              type='submit'
              disabled={formNumber < 3}
            >
              Proceed
            </Button>
          </Grid>
        </Grid>{' '}
      </form>
    </Box>
  );
};

export default WsonConstraints;
