export const inputFields = [
    { label: 'Re-Try Interval', name: 'reTryInterval' },
    { label: 'Re-Try Max', name: 'reTryMax' },
    { label: 'Work Re-route max', name: 'workReRouteMax' },
    { label: 'Protect Re-route max', name: 'protectReRouteMax' },
  ];
  
export const checkboxOptions = [
    { name: 'recurringHourly', label: 'Hourly' },
    { name: 'recurringDaily', label: 'Daily' },
    { name: 'recurringWeekly', label: 'Weekly' },
    { name: 'recurringMonthly', label: 'Monthly' },
  ];

  
  export const selectOptions = [
    {
      label: 'Route Selection Metric',
      name: 'routeSelectionMetric',
      options: [
        'Available Bandwidth',
        'Number of Hops',
        'User Defined',
        'Latency',
      ],
    },
    {
      label: 'Priority',
      name: 'priority',
      options: ['01', '02', '03'],
    },
    {
      label: 'Disjointness',
      name: 'disjointness',
      options: ['Link', 'Node', 'SRLC'],
    },
  ];
