import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { Box, Button, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import {
  dwdmDestinationInfoSchema,
  validationDwdmDestinationInfoSchemaType,
} from '../../../schema/schema';

import CustomSelect from '../../../components/CustomSelect/CustomSelect';
import { commonBtn, submitBtn } from '../../../themes/summaryStyle';
import { useStepContext } from '../../../contexts/StepProviderContext';
import SearchAutoComplete from '../../../components/SearchAutoComplete/SearchAutoComplete';

interface OtnDwdmDestinationInfoProps {
  onNext: () => void;
}

const WsonDestinationInfo: React.FC<OtnDwdmDestinationInfoProps> = ({
  onNext,
}) => {
  const { formNumber, setFormNumber } = useStepContext();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<validationDwdmDestinationInfoSchemaType>({
    resolver: zodResolver(dwdmDestinationInfoSchema),
  });

  const ipAddresses = [
    '192.168.0.1',
    '10.0.0.1',
    '172.16.0.1',
    '192.168.1.1',
    '203.0.113.1',
    '8.8.8.8',
    '192.168.100.1',
    '192.0.2.1',
    '198.51.100.1',
    '255.255.255.255',
  ];
  const onSubmit = (data: validationDwdmDestinationInfoSchemaType) => {
    setFormNumber(3);
    onNext();
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box mt={2}>
        <Box mt={3}>
          <Controller
            name='ipAddress'
            control={control}
            render={({ field }) => (
              <SearchAutoComplete
                ipAddresses={ipAddresses}
                value={field.value?.toString() || ''}
                onChange={(event: any, value: any) => {
                  field.onChange(value);
                }}
                label={
                  <>
                    Node/Ip Address<span style={{ color: 'red' }}>*</span>
                  </>
                }
              />
            )}
          />
          {errors.ipAddress && (
            <Typography
              mt="4px"
              ml={2}
              sx={{ color: "#F90000" }}
              className="poppins-error-font"
            >
              {errors.ipAddress.message}
            </Typography>
          )}
        </Box>
        <Box mt={3}>
          <Box>
            <CustomSelect
              label={
                <>
                  Select Port<span style={{ color: "red" }}>*</span>
                </>
              }
              options={[
                { value: "option1", label: "Option 1" },
                { value: "option2", label: "Option 2" },
              ]}
              {...register("selectPort")}
            />
            {errors.selectPort && (
              <Typography
                mt="4px"
                ml={2}
                sx={{ color: "#F90000" }}
                className="poppins-error-font"
              >
                {errors.selectPort.message}
              </Typography>
            )}
          </Box>
        </Box>
        <Box mt={3} sx={{ display: "flex", gap: 2, alignItems: "center" }}>
          <Box>
            <CustomSelect
              label={
                <>
                  Frequency <span style={{ color: "red" }}>*</span>
                </>
              }
              options={[
                { value: "option1", label: "Option 1" },
                { value: "option2", label: "Option 2" },
              ]}
              {...register("frequency")}
            />
            {errors.frequency && (
              <Typography
                mt="4px"
                ml={2}
                sx={{ color: "#F90000" }}
                className="poppins-error-font"
              >
                {errors.frequency.message}
              </Typography>
            )}
          </Box>
        </Box>
        <Box my={4.5} sx={{ display: "flex", gap: 3, alignItems: "center" }}>
          <Typography
            className="poppins-font"
            sx={{
              fontSize: "14px",
              fontFamily: "Poppins, sans-serif",
              fontWeight: 500,
            }}
          >
            Add Source client port protection{" "}
          </Typography>
          <Button
            variant="outlined"
            className="poppins-font"
            sx={{
              ...commonBtn,
              minWidth: 131,
              minHeight: 55,
            }}
            startIcon={<AddIcon />}
          >
            Add
          </Button>
        </Box>
        <Box mt={2}>
          <Button
            className='poppins-font'
            sx={
              submitBtn
              
              
            }
            type='submit'
            disabled={formNumber < 2}
          >
            Proceed
          </Button>
        </Box>
      </Box>
    </form>
  );
};




export default WsonDestinationInfo