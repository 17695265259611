import { useState } from 'react';

import { Typography } from '@mui/material';

import OtnGmplsCircuitType from '../../forms/p2p/otnGmpls/OtnGmplsCircuitType';
import OtnGmplsSourceForn from '../../forms/p2p/otnGmpls/OtnGmplsSourceForm';
import OtnGmplsDestinationForm from '../../forms/p2p/otnGmpls/OtnGmplsDestinationForm';
import OtnGmplsConstraintForm from '../../forms/p2p/otnGmpls/OtnGmplsConstraintForm';

import MainLayout from './ MainLayout';

const OtnGmplsCircuitCreation = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState<string>('');

  const handleClick = (event: any, content: string) => {
    setAnchorEl(event.currentTarget);
    setContent(content);
    setOpen(true);
  };

  const renderContent = () => {
    switch (content) {
      case 'circuit-type':
        return <OtnGmplsCircuitType setOpen={setOpen} />;
      case 'source-info':
        return <OtnGmplsSourceForn setOpen={setOpen} />;
      case 'node-info':
        return <OtnGmplsDestinationForm setOpen={setOpen} />;
      case 'route-info':
        return <OtnGmplsConstraintForm setOpen={setOpen} />;
      default:
        return <Typography sx={{ p: 2 }}>Default Content</Typography>;
    }
  };

  return (
    <MainLayout
      anchorEl={anchorEl}
      open={open}
      content={content}
      handleClick={handleClick}
      renderContent={renderContent}
    />
  );
};

export default OtnGmplsCircuitCreation;
