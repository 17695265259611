import React from "react";
import { Box, Grid } from "@mui/material";
import ReadonlyTextField from "../CustomTextField/ReadonlyTextField";

interface DestinationData {
  destinationTrafficType: string;
  ipAddress: string;
  port: string;
  timeSlot: string;
}

interface DwdmDestinationInfoReadonlyProps {
  data: DestinationData;
}

const DwdmDestinationInfoReadonly: React.FC<
  DwdmDestinationInfoReadonlyProps
> = ({ data }) => {
  return (
    <Box
      sx={{
        paddingLeft: "3rem",
      }}
    >
      <Grid container spacing={4}>
        {/* Row 1: Destination Traffic Type and IP Address */}
        <Grid item xs={12} sm={6}>
          <ReadonlyTextField
            width="100%"
            label="Destination Traffic Type"
            value={data.destinationTrafficType}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ReadonlyTextField
            width="100%"
            label="IP Address"
            value={data.ipAddress}
          />
        </Grid>

        {/* Row 2: Port and Time Slot */}
        <Grid item xs={12} sm={6}>
          <ReadonlyTextField width="100%" label="Port" value={data.port} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ReadonlyTextField
            width="100%"
            label="Time Slot"
            value={data.timeSlot}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default DwdmDestinationInfoReadonly;
