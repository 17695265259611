import React from 'react';

import CommonAccordion from '../../components/accordion/CommonAccordion';

import SdhBasicCircuit from '../../forms/p2p/Sdh/SdhBasicCircuit';
import SdhSourceInfo from '../../forms/p2p/Sdh/SdhSourceInfo';
import SdhDestinationInfo from '../../forms/p2p/Sdh/SdhDestinationInfo';
import SdhConstraints from '../../forms/p2p/Sdh/SdhConstraints';

const SDH: React.FC = () => {
  const accordions = [
    {
      title: 'Circuit Primitive Information',
      description:
        'Please enter a few details below Listed Mandatory Fields, Need More Please filter From advance',
      content: <SdhBasicCircuit onNext={() => {}} />, // Pass a dummy onNext function for now
    },
    {
      title: 'Source Information',
      description:
        'Please enter a few details below Listed Mandatory Fields.',
      content: <SdhSourceInfo onNext={() => {}} />, // Pass a dummy onNext function for now
    },
    {
      title: 'Destination Information',
      description:
        'Please enter a few details below Listed Mandatory Fields.',
      content: <SdhDestinationInfo onNext={() => {}} />, // Pass a dummy onNext function for now
    },
    {
      title: 'Constraints & Route Creations',
      description:
        'Please enter a few details below Listed Mandatory Fields.',
      content: <SdhConstraints onNext={() => {}} />, // Pass a dummy onNext function for now
    },
  ];
  return (
    <div>
      <CommonAccordion accordions={accordions} />
    </div>
  );
};

export default SDH;
