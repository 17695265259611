import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';

import info from '../../../assets/svg/information-button.svg';
import {
  SdhConstrainInfoSchema,
  validationSdhConstrainInfoSchema,
} from '../../../schema/schema';
import { checkboxStyle, submitBtn } from '../../../themes/summaryStyle';
import { useStepContext } from '../../../contexts/StepProviderContext';

interface OtnGmplsConstraintsProps {
  onNext: () => void;
}

const SdhConstraints: React.FC<OtnGmplsConstraintsProps> = ({ onNext }) => {
  const navigate = useNavigate();
  const { formNumber, setFormNumber } = useStepContext();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<validationSdhConstrainInfoSchema>({
    resolver: zodResolver(SdhConstrainInfoSchema),
  });

  const onSubmit = (data: validationSdhConstrainInfoSchema) => {
    setFormNumber(4);
    navigate('/p2p/sdh/summary');
  };

  return (
    <Box mt={2}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} alignItems='center'>
          <Grid item md={12}>
            <Box display='flex' alignItems='center'>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      {...register('addIncludeExcludeConstraints')}
                      sx={checkboxStyle}
                    />
                  }
                  label='Add Include/Exclude constraints'
                />
              </FormGroup>
              <img src={info} alt='info' />
            </Box>
            {errors.addIncludeExcludeConstraints && (
              <Typography
                mt='4px'
                ml={2}
                sx={{ color: '#F90000' }}
                className='poppins-error-font'
              >
                {errors.addIncludeExcludeConstraints.message}
              </Typography>
            )}
          </Grid>

          <Grid item xs={12}>
            <Typography
              sx={{
                color: '#000',
                fontSize: '16px',
                fontFamily: 'Poppins, sans-serif',
                fontWeight: 600,
              }}
              mb={1}
            >
              Work path
            </Typography>
            <FormControl>
              <RadioGroup
                name='workPath'
                defaultValue=''
                row
                sx={{ display: 'flex', gap: '4rem' }}
              >
                <FormControlLabel
                  value='Include Nodes /Links'
                  control={
                    <Radio
                      sx={{
                        color: 'lightgray',
                        '&.Mui-checked': {
                          color: 'white',
                        },
                        '&.Mui-checked .MuiSvgIcon-root': {
                          backgroundColor: 'blue',
                          borderRadius: '50%',
                        },
                        '& .MuiSvgIcon-root': {
                          fontSize: 20,
                        },
                      }}
                    />
                  }
                  label='Include Nodes /Links'
                  {...register('workPath')}
                />
                <FormControlLabel
                  value='Exclude Nodes /Links'
                  control={
                    <Radio
                      sx={{
                        color: 'lightgray',
                        '&.Mui-checked': {
                          color: 'white',
                        },
                        '&.Mui-checked .MuiSvgIcon-root': {
                          backgroundColor: 'blue',
                          borderRadius: '50%',
                        },
                        '& .MuiSvgIcon-root': {
                          fontSize: 20,
                        },
                      }}
                    />
                  }
                  label='Exclude Nodes /Links'
                  {...register('workPath')}
                />
              </RadioGroup>
              {errors.workPath && (
                <Typography
                  mt='4px'
                  ml={2}
                  sx={{ color: '#F90000' }}
                  className='poppins-error-font'
                >
                  {errors.workPath.message}
                </Typography>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12} mt={2}>
            <Typography
              sx={{
                color: '#000',
                fontSize: '16px',
                fontFamily: 'Poppins, sans-serif',
                fontWeight: 600,
              }}
              mb={1}
            >
              Protect path
            </Typography>
            <FormControl>
              <RadioGroup
                name='protectPath'
                defaultValue=''
                row
                sx={{ display: 'flex', gap: '4rem' }}
              >
                <FormControlLabel
                  value='Include Nodes /Links'
                  control={
                    <Radio
                      sx={{
                        color: 'lightgray',
                        '&.Mui-checked': {
                          color: 'white',
                        },
                        '&.Mui-checked .MuiSvgIcon-root': {
                          backgroundColor: 'blue',
                          borderRadius: '50%',
                        },
                        '& .MuiSvgIcon-root': {
                          fontSize: 20,
                        },
                      }}
                    />
                  }
                  label='Include Nodes /Links'
                  {...register('protectPath')}
                />
                <FormControlLabel
                  value='Exclude Nodes /Links'
                  control={
                    <Radio
                      sx={{
                        color: 'lightgray',
                        '&.Mui-checked': {
                          color: 'white',
                        },
                        '&.Mui-checked .MuiSvgIcon-root': {
                          backgroundColor: 'blue',
                          borderRadius: '50%',
                        },
                        '& .MuiSvgIcon-root': {
                          fontSize: 20,
                        },
                      }}
                    />
                  }
                  label='Exclude Nodes /Links'
                  {...register('protectPath')}
                />
              </RadioGroup>
            </FormControl>
            {errors.protectPath && (
              <Typography
                mt='4px'
                ml={2}
                sx={{ color: '#F90000' }}
                className='poppins-error-font'
              >
                {errors.protectPath.message}
              </Typography>
            )}
          </Grid>

          <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox {...register('srlgDisjoint')} sx={checkboxStyle} />
                }
                label='SRLG Disjoint'
              />
            </FormGroup>
            {errors.srlgDisjoint && (
              <Typography
                mt='4px'
                ml={2}
                sx={{ color: '#F90000' }}
                className='poppins-error-font'
              >
                {errors.srlgDisjoint.message}
              </Typography>
            )}
          </Grid>

          <Grid item xs={12}>
            <Button sx={submitBtn} type='submit'  disabled={formNumber < 3}>
              Proceed
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default SdhConstraints;
